/**************************************************
    GRID & LAYOUT SETTINGS
    **********************************************/
/**************************************************
    COLORS
    **********************************************/
/**************************************************
    BORDER RADIUS DEFINITIONS
    **********************************************/
/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
.container {
  padding-bottom: 20px;
  text-align: center;
}

.instrumentTrendExplainer {
  text-align: center;
  margin: 0 1rem;
}

.title {
  margin-bottom: 1rem;
}

.icon {
  width: 64px;
  height: 64px;
}

.subtitle {
  margin: 0.5rem 0;
}