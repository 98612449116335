@media (min-width: 1121px) {
  .registerButton {
    background-color: #18191D;
    border-color: rgba(255, 255, 255, 0.25);
  }
  .registerButton .btnContent {
    background-image: url(../../images/brands/wintrado/logo.svg);
  }
}

.login__logo {
  background-image: url(../../images/brands/wintrado/logo-mobile.png);
  width: 150px;
  z-index: 20;
}

.sidemenu h1 {
  background-image: url(../../images/brands/wintrado/logo.svg);
}