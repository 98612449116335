.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.holder {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  min-width: 120px;
}

.videoholder {
  position: relative;
  margin-bottom: 20px;
}
.videoholder video {
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.25);
  max-width: 100%;
  border-radius: 5px;
  overflow: hidden;
}