html,
body {
  font-size: 16px;
}

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
/**************************************************
    GRID & LAYOUT SETTINGS
    **********************************************/
/**************************************************
    COLORS
    **********************************************/
/**************************************************
    BORDER RADIUS DEFINITIONS
    **********************************************/
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.holder {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  min-width: 120px;
}

.illustration {
  width: auto;
  margin: auto;
}
@media screen and (min-width: 340px) {
  .illustration {
    width: 210px;
  }
}

.small-labels {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  margin-top: -1.2em;
  font-weight: 700;
  color: white;
  font-size: 80%;
}
.small-labels span {
  width: 31.25%;
}
.small-labels span.hidden {
  visibility: hidden;
}

.big-label {
  display: block;
  text-align: center;
  font-weight: 700;
  color: white;
  margin-top: -0.3em;
}