/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
/**************************************************
    GRID & LAYOUT SETTINGS
    **********************************************/
/**************************************************
    COLORS
    **********************************************/
/**************************************************
    BORDER RADIUS DEFINITIONS
    **********************************************/
.dropdown {
  margin-top: 5px;
  position: absolute;
  top: 100%;
  z-index: 1050;
  display: flex;
  background: linear-gradient(134.22deg, #565a5c -63.52%, #2a2e31 74.66%);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.35);
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
  padding: 15px 20px 20px;
  gap: 1rem;
  width: 300px;
}
@media screen and (max-width: 1119px) {
  .dropdown {
    position: fixed;
    top: unset;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    border-radius: 20px 20px 0 0;
    border: none;
  }
}

.title {
  display: none;
  margin: 0.5rem 0;
}
@media screen and (max-width: 1119px) {
  .title {
    display: block;
  }
}

.buttons-container {
  margin-top: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: stretch;
  align-items: center;
}