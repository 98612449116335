html,
body {
  font-size: 16px;
}

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
/**************************************************
    GRID & LAYOUT SETTINGS
    **********************************************/
/**************************************************
    COLORS
    **********************************************/
/**************************************************
    BORDER RADIUS DEFINITIONS
    **********************************************/
.trigger {
  position: relative;
  font-size: inherit;
  background: #1a1b1f;
  border-radius: 8px;
  border: 1px solid transparent;
  font-size: 16px;
  line-height: 24px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  transition: border-color 200ms ease-out;
}
.trigger:hover {
  border: 1px solid #44bbfe;
}

.content {
  max-height: 300px;
  height: min-content;
  overflow-y: auto !important;
  border-radius: 10px;
  background: linear-gradient(134.22deg, #565a5c -63.52%, #2a2e31 74.66%) !important;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 1rem;
  list-style: none;
  min-width: 10rem;
  text-align: left;
  width: 100%;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  position: relative;
  z-index: 10000000;
}
@media screen and (max-width: 540px) {
  .content {
    width: calc(100vw - 20px);
  }
}

.arrow {
  fill: #2a2e31;
}

.selectIcon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}

.value {
  font-weight: 200;
  font-size: 16px;
  bottom: 4px;
  left: 8px;
}

.label {
  top: 4px;
  left: 8px;
  color: #84899c;
  font-weight: 200;
  font-size: 12px;
}

.trigger {
  height: 56px;
  text-align: start;
  border-radius: 8px;
  background: #1a1b1f;
  display: flex;
  align-items: center;
  padding: 0 16px;
}

.dropdownIcon {
  padding-left: 8px;
  margin-left: auto;
}

.content[data-side=top] {
  animation-name: slideDownAndFade;
}

.content[data-side=right] {
  animation-name: slideLeftAndFade;
}

.content[data-side=bottom] {
  animation-name: slideUpAndFade;
}

.content[data-side=left] {
  animation-name: slideRightAndFade;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}