/**************************************************
    GRID & LAYOUT SETTINGS
    **********************************************/
/**************************************************
    COLORS
    **********************************************/
/**************************************************
    BORDER RADIUS DEFINITIONS
    **********************************************/
.container {
  border-radius: 20px;
  padding: 0.2rem 0.5rem;
  background-color: #646d7b;
}