/**************************************************
    GRID & LAYOUT SETTINGS
    **********************************************/
/**************************************************
    COLORS
    **********************************************/
/**************************************************
    BORDER RADIUS DEFINITIONS
    **********************************************/
/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(24, 25, 29, 0.7);
  z-index: 1040;
}
@media screen and (max-width: 1119px) {
  .backdrop--mobile-transparent {
    background-color: transparent;
  }
}
@media screen and (min-width: 1120px) {
  .backdrop--desktop-transparent {
    background-color: transparent;
  }
}