html,
body {
  font-size: 16px;
}

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
/**************************************************
    GRID & LAYOUT SETTINGS
    **********************************************/
/**************************************************
    COLORS
    **********************************************/
/**************************************************
    BORDER RADIUS DEFINITIONS
    **********************************************/
.layout-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 30px;
  max-height: 100%;
  overflow: auto;
}
@media screen and (max-width: 1119px) {
  .layout-container {
    margin-bottom: 0;
  }
}

.layout-wrapper {
  height: 100%;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
}
@media screen and (max-width: 1119px) {
  .container {
    flex-direction: column;
    margin: 0;
    padding-bottom: 30px;
  }
}

.hero {
  margin-right: 15px;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1d2024;
  padding: 15px;
  border-radius: 5px;
}
@media screen and (max-width: 1119px) {
  .hero {
    margin: 0;
    padding: 0;
    background-color: transparent;
  }
}
@media screen and (min-width: 1120px) {
  .hero {
    border: 1px solid #2a2e31;
  }
}
.hero .label {
  margin-bottom: 2rem;
}
@media screen and (min-width: 1120px) {
  .hero .label {
    font-weight: 700;
  }
}
.hero .balance-wrapper {
  border-radius: 12.5rem;
  border: 1px solid #ffda57;
  background-color: rgba(255, 218, 87, 0.06);
  width: 80%;
  max-width: 18rem;
  display: flex;
  justify-content: center;
  box-shadow: 0px 0px 50px #000000, inset 5px 5px 10px rgba(0, 0, 0, 0.5);
  margin-bottom: 2rem;
}
.hero .balance-wrapper span {
  font-size: 1.875rem;
  text-align: center;
  font-weight: 700;
}
@media screen and (min-width: 1120px) {
  .hero .balance-wrapper span {
    font-size: 2.625rem;
  }
}

.exchange-container {
  background-color: #1d2024;
  padding: 15px;
  margin-left: 15px;
  display: flex;
  flex: 2 0 auto;
  flex-direction: column;
  border-radius: 5px;
}
@media screen and (max-width: 1119px) {
  .exchange-container {
    margin: 0;
    padding: 0;
    background-color: transparent;
  }
}
@media screen and (min-width: 1120px) {
  .exchange-container {
    border: 1px solid #2a2e31;
  }
}
@media screen and (min-width: 600px) and (max-width: 1119px) {
  .exchange-container {
    min-width: 85%;
    margin: 0 auto;
  }
}

.notice-title, .notice-subtitle {
  text-align: left;
}
@media screen and (max-width: 1119px) {
  .notice-title, .notice-subtitle {
    text-align: center;
  }
}

.notice-subtitle {
  color: #A7A8A8;
}

.coins-icon {
  height: 5rem;
  margin: 2rem 0;
}

.exchange-table {
  border-collapse: separate;
  border-spacing: 0 0.2rem;
  width: 100%;
}
.exchange-table thead {
  color: rgba(255, 255, 255, 0.5);
  background-color: #2a2e31;
}
.exchange-table th {
  font-size: 0.75rem;
  font-weight: 500;
}
.exchange-table tbody {
  font-size: 0.875rem;
  font-weight: 300;
}
.exchange-table td, .exchange-table th {
  text-align: center;
  padding: 0.7rem 0;
}
.exchange-table td:first-child, .exchange-table th:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.exchange-table td:last-child, .exchange-table th:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.exchange-table tbody tr {
  margin-bottom: 0.2rem;
  border-radius: 5px;
}
.exchange-table tbody tr:nth-child(even) {
  background: #2c3036;
}
.exchange-table tbody tr:nth-child(odd) {
  background: #24262A;
}