html,
body {
  font-size: 16px;
}

/**************************************************
    GRID & LAYOUT SETTINGS
    **********************************************/
/**************************************************
    COLORS
    **********************************************/
/**************************************************
    BORDER RADIUS DEFINITIONS
    **********************************************/
.radixTooltipContent {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  position: relative;
  z-index: 1070;
  text-align: center;
  background-color: #000;
  border-radius: 0.5rem;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
}

.paragraph {
  margin: 0;
}

.radixTooltipContent[data-state=delayed-open][data-side=top] {
  animation-name: slideDownAndFade;
}

.radixTooltipContent[data-state=delayed-open][data-side=right] {
  animation-name: slideLeftAndFade;
}

.radixTooltipContent[data-state=delayed-open][data-side=bottom] {
  animation-name: slideUpAndFade;
}

.radixTooltipContent[data-state=delayed-open][data-side=left] {
  animation-name: slideRightAndFade;
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}