@charset "UTF-8";
:root {
  color-scheme: dark;
}

/**************************************************
    MAIN STYLES
    **********************************************/
/**************************************************
    GRID & LAYOUT SETTINGS
    **********************************************/
/**************************************************
    COLORS
    **********************************************/
/**************************************************
    BORDER RADIUS DEFINITIONS
    **********************************************/
/**************************************************
    LIBS & INCLUDES (CORE)
    **********************************************/
/**************************************************
    MODULES
    **********************************************/
/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
html,
body {
  font-size: 16px;
}

html,
body {
  font-size: 16px;
}

@font-face {
  font-family: "Equip";
  src: url("../fonts/Equip-Black.eot");
  src: url("../fonts/Equip-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/Equip-Black.woff2") format("woff2"), url("../fonts/Equip-Black.woff") format("woff"), url("../fonts/Equip-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Equip";
  src: url("../fonts/Equip-Thin.eot");
  src: url("../fonts/Equip-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/Equip-Thin.woff2") format("woff2"), url("../fonts/Equip-Thin.woff") format("woff"), url("../fonts/Equip-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Equip";
  src: url("../fonts/Equip-ThinItalic.eot");
  src: url("../fonts/Equip-ThinItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Equip-ThinItalic.woff2") format("woff2"), url("../fonts/Equip-ThinItalic.woff") format("woff"), url("../fonts/Equip-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Equip";
  src: url("../fonts/Equip-Italic.eot");
  src: url("../fonts/Equip-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/Equip-Italic.woff2") format("woff2"), url("../fonts/Equip-Italic.woff") format("woff"), url("../fonts/Equip-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Equip";
  src: url("../fonts/Equip-MediumItalic.eot");
  src: url("../fonts/Equip-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Equip-MediumItalic.woff2") format("woff2"), url("../fonts/Equip-MediumItalic.woff") format("woff"), url("../fonts/Equip-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Equip";
  src: url("../fonts/Equip-Bold.eot");
  src: url("../fonts/Equip-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Equip-Bold.woff2") format("woff2"), url("../fonts/Equip-Bold.woff") format("woff"), url("../fonts/Equip-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Equip";
  src: url("../fonts/Equip-ExtraLight.eot");
  src: url("../fonts/Equip-ExtraLight.eot?#iefix") format("embedded-opentype"), url("../fonts/Equip-ExtraLight.woff2") format("woff2"), url("../fonts/Equip-ExtraLight.woff") format("woff"), url("../fonts/Equip-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Equip";
  src: url("../fonts/Equip-Light.eot");
  src: url("../fonts/Equip-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Equip-Light.woff2") format("woff2"), url("../fonts/Equip-Light.woff") format("woff"), url("../fonts/Equip-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Equip";
  src: url("../fonts/Equip-Medium.eot");
  src: url("../fonts/Equip-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Equip-Medium.woff2") format("woff2"), url("../fonts/Equip-Medium.woff") format("woff"), url("../fonts/Equip-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Equip-ExtraBoIdItalic";
  src: url("../fonts/Equip-ExtraBoIdItalic.eot");
  src: url("../fonts/Equip-ExtraBoIdItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Equip-ExtraBoIdItalic.woff2") format("woff2"), url("../fonts/Equip-ExtraBoIdItalic.woff") format("woff"), url("../fonts/Equip-ExtraBoIdItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "Equip";
  src: url("../fonts/Equip-BlackItalic.eot");
  src: url("../fonts/Equip-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Equip-BlackItalic.woff2") format("woff2"), url("../fonts/Equip-BlackItalic.woff") format("woff"), url("../fonts/Equip-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Equip-BoIdItalic";
  src: url("../fonts/Equip-BoIdItalic.eot");
  src: url("../fonts/Equip-BoIdItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Equip-BoIdItalic.woff2") format("woff2"), url("../fonts/Equip-BoIdItalic.woff") format("woff"), url("../fonts/Equip-BoIdItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Equip";
  src: url("../fonts/Equip-LightItalic.eot");
  src: url("../fonts/Equip-LightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Equip-LightItalic.woff2") format("woff2"), url("../fonts/Equip-LightItalic.woff") format("woff"), url("../fonts/Equip-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Equip";
  src: url("../fonts/Equip-ExtraLightItalic.eot");
  src: url("../fonts/Equip-ExtraLightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Equip-ExtraLightItalic.woff2") format("woff2"), url("../fonts/Equip-ExtraLightItalic.woff") format("woff"), url("../fonts/Equip-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Equip";
  src: url("../fonts/Equip-Regular.eot");
  src: url("../fonts/Equip-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Equip-Regular.woff2") format("woff2"), url("../fonts/Equip-Regular.woff") format("woff"), url("../fonts/Equip-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
body {
  font-family: "Equip", sans-serif;
}

p {
  font-size: 0.875rem;
}

h1 {
  font-size: 2.625rem;
  font-weight: 700;
}

h2 {
  font-size: 1.875rem;
  font-weight: 700;
}

h3 {
  font-size: 1.875rem;
  font-weight: 700;
}

h4 {
  font-size: 1.5rem;
  font-weight: 700;
}

h5 {
  font-size: 1.25rem;
  font-weight: 700;
}

/* Only Chrome */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(93, 94, 97, 0.5);
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 20px;
}

/* IE */
html {
  scrollbar-face-color: rgba(93, 94, 97, 0.5);
  scrollbar-shadow-color: transparent;
  scrollbar-highlight-color: transparent;
}

/* FF */
html {
  scrollbar-color: rgba(93, 94, 97, 0.5) transparent;
}

fieldset {
  margin: 0;
  padding: 0;
}

input {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

input[type=text], input[type=email], input[type=search],
input[type=password], textarea {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
  outline: none !important;
}

.form {
  position: relative;
}
.form__fieldset {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 0.5rem 0.5rem;
  padding: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
}
.form__fieldset:focus-within::after {
  background: #70c157;
}
.form__fieldset::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #383d44;
  height: 2px;
}
.form__fieldset--condensed {
  margin: 0;
}
.form__fieldset label.error {
  position: relative;
  left: 20px;
  margin: 0;
  color: #f60057;
  font-size: 0.75rem;
  text-transform: uppercase;
}
.form__input-box {
  position: relative;
}
.form__input-suffix-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 15px;
  height: auto;
}
.form__label {
  position: relative;
  display: block;
  margin-top: 0.5rem;
  padding: 0 20px;
  font-size: 0.75rem;
  text-align: center;
}
.form__label--radio, .form__label--checkbox {
  display: inline;
  margin: 0 0 0 0.5rem;
  padding: 0;
  text-transform: none;
}
.form__label--radio a, .form__label--checkbox a {
  color: #12aaeb;
  border-bottom: 1px solid transparent !important;
}
.form__label--radio a:hover, .form__label--checkbox a:hover {
  border-color: #12aaeb;
}
.form__label--radio i, .form__label--checkbox i {
  float: left;
  position: relative;
  top: 2px;
}
.form__label--radio span, .form__label--checkbox span {
  display: block;
  margin-left: 25px;
}
.form__textfield, .form__fieldset input:not([type=checkbox]):not([type=radio]) {
  margin: 0;
  padding: 0 20px;
  width: 100%;
  border: none;
  color: #FFFFFF;
  font-size: 1rem;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
  background: transparent;
  position: relative;
  background: #18191d;
  border-radius: 5px;
  height: 50px;
}
.form__textfield--small, .form__fieldset--small input:not([type=checkbox]):not([type=radio]) {
  height: 30px;
  font-size: 0.75rem;
  padding: 0 10px;
}
.form__textfield--text-center, .form__fieldset--text-center input:not([type=checkbox]):not([type=radio]) {
  text-align: center;
}
.form__textfield--with-suffix-icon, .form__fieldset--with-suffix-icon input:not([type=checkbox]):not([type=radio]) {
  padding-right: 35px !important;
}
.form__textfield ::-webkit-input-placeholder, .form__fieldset input:not([type=checkbox]):not([type=radio]) ::-webkit-input-placeholder {
  color: #8B959E;
  font-size: 1.125rem;
  line-height: 18px;
  line-height: 1.8rem;
  font-weight: 400;
}
.form__textfield :-moz-placeholder, .form__fieldset input:not([type=checkbox]):not([type=radio]) :-moz-placeholder {
  color: #8B959E;
  font-size: 1.125rem;
  line-height: 18px;
  line-height: 1.8rem;
  font-weight: 400;
}
.form__textfield ::-moz-placeholder, .form__fieldset input:not([type=checkbox]):not([type=radio]) ::-moz-placeholder {
  color: #8B959E;
  font-size: 1.125rem;
  line-height: 18px;
  line-height: 1.8rem;
  font-weight: 400;
}
.form__textfield :-ms-input-placeholder, .form__fieldset input:not([type=checkbox]):not([type=radio]) :-ms-input-placeholder {
  color: #8B959E;
  font-size: 1.125rem;
  line-height: 18px;
  line-height: 1.8rem;
  font-weight: 400;
}
.form__textfield.error, .form__fieldset input.error:not([type=checkbox]):not([type=radio]) {
  border-color: #f60057;
  color: #f60057;
}
.form__textfield:-webkit-autofill, .form__fieldset input:-webkit-autofill:not([type=checkbox]):not([type=radio]), .form__textfield:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #18191d inset;
  -webkit-text-fill-color: #FFFFFF;
}
.form__select {
  margin: 0;
  padding: 0 10px;
  width: 100%;
  height: 50px;
  border: 1px solid #383d44;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #717171;
  font-size: 1rem;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.form__select:focus {
  border-color: #70c157;
}
.form__select.error {
  border-color: #f60057;
}
.form__textarea, .form__fieldset textarea {
  width: 100%;
  border: none;
  border-bottom: 2px solid #383d44;
  background-color: transparent;
  font-size: 0.875rem;
  color: #FFFFFF;
  resize: vertical;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.form__textarea:focus, .form__fieldset textarea:focus {
  border-color: #70c157;
}
.form__textarea ::-webkit-input-placeholder, .form__fieldset textarea ::-webkit-input-placeholder {
  color: #8B959E;
  font-size: 1.125rem;
  line-height: 22px;
  line-height: 2.2rem;
}
.form__textarea :-moz-placeholder, .form__fieldset textarea :-moz-placeholder {
  color: #8B959E;
  font-size: 1.125rem;
  line-height: 22px;
  line-height: 2.2rem;
}
.form__textarea ::-moz-placeholder, .form__fieldset textarea ::-moz-placeholder {
  color: #8B959E;
  font-size: 1.125rem;
  line-height: 22px;
  line-height: 2.2rem;
}
.form__textarea :-ms-input-placeholder, .form__fieldset textarea :-ms-input-placeholder {
  color: #8B959E;
  font-size: 1.125rem;
  line-height: 22px;
  line-height: 2.2rem;
}
.form__textarea.error, .form__fieldset textarea.error {
  border-color: #f60057;
}

#regContainer {
  height: 100%;
}
#regContainer > div {
  height: 100%;
}

#regContent {
  padding-top: 10vh;
}

#regAdditional {
  background-color: red;
}

.wt-form input.form-control[type=text] {
  background: none;
  border: 0;
  padding: 0;
  color: #ffffff;
  caret-color: #12aaeb;
}
.wt-form input.form-control[type=text]:focus {
  outline: none;
  box-shadow: none;
}
.wt-form input.form-control[type=text]:-webkit-autofill, .wt-form input.form-control[type=text]:-webkit-autofill:hover, .wt-form input.form-control[type=text]:-webkit-autofill:focus {
  background-color: transparent;
  -webkit-box-shadow: 0 0 0px 1000px #18191d inset;
  -webkit-text-fill-color: #ffffff;
  color: #ffffff;
}
.wt-form .form-group {
  padding-left: 60px;
  margin-right: 40px;
  border-bottom: 1px solid #2a2b31;
}
.wt-form .form-group label {
  margin-bottom: 0;
}
.wt-form .form-group:focus-within > label {
  color: #12aaeb;
}
.wt-form .form-group.name {
  background: url(~/src/icons/settings.svg) no-repeat 20px center;
}
.wt-form .form-actions {
  text-align: center;
}

span.error {
  color: #f60057;
}

.login {
  overflow-x: hidden;
}
.login__wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-align-content: center;
  -moz-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100%;
}
.login__form-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-flex-flow: column;
  -moz-flex-flow: column;
  -ms-flex-flow: column;
  flex-flow: column;
  width: 100%;
  height: 80vh;
  box-sizing: border-box;
}
@media screen and (min-width: 991px) {
  .login__form-content {
    width: 50%;
    height: 100%;
  }
}
@media screen and (max-width: 991px) {
  .login__form-content {
    height: auto;
  }
}
.login__buttons_separator_label {
  margin: 1rem;
}
.login__bg {
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index: 2;
}
@media screen and (max-width: 990px) {
  .login__bg {
    background-image: none !important;
    max-width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    height: min-content;
    margin-top: 40px;
  }
}
@media screen and (max-width: 340px) {
  .login__bg {
    margin-top: 20px;
  }
}
.login__bg .wrapper {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
@media screen and (min-width: 991px) {
  .login__bg .wrapper {
    height: 100%;
  }
}
.login__bg .wrapper .language {
  width: 100%;
}
@media screen and (max-width: 600px) {
  .login__bg .wrapper .language {
    display: none;
  }
}
.login__bg .wrapper .btnholder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
@media screen and (min-width: 767px) {
  .login__bg .wrapper .btnholder {
    width: 324px;
    margin-bottom: 70px;
    flex-direction: column;
  }
}
@media screen and (min-width: 767px) and (min-width: 767px) {
  .login__bg .wrapper .btnholder .button--demo {
    margin-bottom: 0.5rem;
  }
}
@media screen and (max-width: 766px) {
  .login__bg .wrapper .btnholder .open-account-button {
    width: 100%;
  }
  .login__bg .wrapper .btnholder .button:first-child {
    margin-right: 0.5rem;
  }
}
.login__form-holder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  width: 100%;
  height: min-content;
}
.login__logoholder {
  position: relative;
}
.login__logoholder:before {
  background-image: url(~/src/images/login/logo-decoration.png);
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
  width: 500px;
  height: 160px;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 70px;
  z-index: 1;
}
.login__inputs {
  width: 100%;
}
@media screen and (min-width: 767px) {
  .login__inputs {
    max-width: 335px;
  }
}
.login__logo {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  z-index: 3;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 50px;
}
.login__circle {
  width: 220px;
  height: 120px;
  position: relative;
  z-index: 1;
}
@media screen and (min-width: 1120px) {
  .login__circle {
    margin-bottom: 45px;
  }
}
.login__circle:before {
  width: 220px;
  height: 160px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  content: "";
  background: rgba(255, 255, 255, 0.35);
  filter: blur(80px);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
}
@media screen and (max-width: 766px) {
  .login__circle:before {
    height: 80px;
  }
}
@media screen and (max-width: 1120px) {
  .login__circle:after {
    content: "Wintrado";
    display: block;
    position: absolute;
    bottom: -3px;
    text-align: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.875rem;
    letter-spacing: 2px;
    display: none;
  }
}
.login__title h1 {
  font-size: 1.5rem;
  font-weight: 700;
  color: #ffffff;
  margin: 0 0 30px 0;
}
@media screen and (max-width: 360px) {
  .login__title {
    display: none;
  }
}
.login__info {
  text-align: center;
}
.login__info p {
  font-size: 0.875rem;
  line-height: 21px;
  color: #ffffff; /* The Fallback */
  color: rgba(255, 255, 255, 0.6);
  max-width: 256px;
  margin: 0 auto 25px auto;
}
@media screen and (max-width: 340px) {
  .login__info {
    display: none;
  }
}
.login__form-holder {
  text-align: center;
}
@media screen and (min-width: 767px) {
  .login__form {
    min-width: 324px;
  }
}
.login__submit {
  margin: 20px 0;
  width: 100%;
}
.login__remember {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
@media screen and (max-width: 1119px) {
  .login__remember {
    padding: 0 15px;
  }
}
@media screen and (max-width: 340px) {
  .login__remember {
    padding: 0 2px;
  }
}
.login__remember .info-remember {
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.6);
}
.login__links {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}
@media screen and (max-width: 1119px) {
  .login__links {
    padding: 0 15px;
  }
}
@media screen and (max-width: 340px) {
  .login__links {
    padding: 0 2px;
  }
}
.login__forgot, .login__help {
  font-size: 0.875rem;
  cursor: pointer;
  margin-top: 1rem;
}
@media screen and (max-width: 340px) {
  .login__forgot, .login__help {
    margin-top: 0.2rem;
  }
}
.login__forgot a, .login__help a {
  color: #ffffff;
}
.login__language-mobile {
  position: absolute;
  top: 0;
  right: 20px;
  z-index: 999;
}
@media screen and (min-width: 991px) {
  .login__language-mobile {
    display: none;
  }
}
@media screen and (max-height: 480px) {
  .login__language-mobile {
    top: -20px;
    right: 5px;
  }
}

.slide-enter {
  opacity: 0;
  transform: translateY(40px);
  will-change: transform;
}

.slide-enter-active, .slide-enter-done {
  opacity: 1;
  transform: translateY(0);
  transition: all 350ms ease-out;
}

.slide-exit {
  opacity: 1;
  transform: translateY(0);
  will-change: transform;
}

.slide-exit-active, .slide-exit-done {
  opacity: 0;
  transform: translateY(40px);
  transition: all 350ms ease-out;
}

.slide-right-enter {
  opacity: 0;
  transform: translateX(-40px);
  will-change: transform;
}

.slide-right-enter-active, .slide-right-enter-done {
  opacity: 1;
  transform: translateX(0);
  transition: all 350ms ease-out;
}

.slide-right-exit {
  opacity: 1;
  transform: translateX(0);
  will-change: transform;
}

.slide-right-exit-active, .slide-right-exit-done {
  opacity: 0;
  transition: all 350ms ease-out;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active, .fade-enter-done {
  opacity: 1;
  transition: opacity 550ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active, .fade-exit-done {
  opacity: 0;
  transition: opacity 550ms ease-in;
}

.balance-dropdown-enter {
  max-height: 0 !important;
  will-change: max-height;
  overflow: hidden;
}

.balance-dropdown-enter-active, .balance-dropdown-enter-done {
  max-height: 130px !important;
  transition: max-height 350ms ease;
}

.balance-dropdown-exit {
  max-height: 130px !important;
  will-change: max-height;
}

.balance-dropdown-exit-active, .balance-dropdown-exit-done {
  max-height: 0 !important;
  overflow: hidden;
  transition: max-height 350ms ease;
}

.balance-dropdown-exit-active {
  pointer-events: none;
}

.page-slide-enter {
  position: absolute;
  transform: translateX(100%);
  will-change: transform;
}

.page-slide-enter-active, .page-slide-enter-done {
  transform: translateX(0);
  transition: all 450ms ease-in-out;
}

.page-slide-exit {
  transform: translateX(0);
  will-change: transform;
}

.page-slide-exit-active, .page-slide-exit-done {
  transform: translateX(100%);
  transition: all 450ms ease-in-out;
}

.reverse-page-slide-enter {
  position: absolute;
  transform: translateX(-100%);
  will-change: transform;
}

.reverse-page-slide-enter-active, .reverse-page-slide-enter-done {
  transform: translateX(0);
  transition: all 450ms ease-in-out;
}

.reverse-page-slide-exit {
  transform: translateX(0);
  will-change: transform;
}

.reverse-page-slide-exit-active, .reverse-page-slide-exit-done {
  transform: translateX(-100%);
  transition: all 450ms ease-in-out;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dot-keyframes {
  0% {
    opacity: 0.4;
    transform: scale(1, 1);
  }
  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }
  100% {
    opacity: 0.4;
    transform: scale(1, 1);
  }
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: "Equip", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #FFF;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 800;
}

small {
  font-size: 0.8rem;
}

sub,
sup {
  position: relative;
  font-size: 0.75rem;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #12aaeb;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #1496cd;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1rem;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

p {
  font-size: 0.875rem;
}

h1 {
  font-size: 2.625rem;
  font-weight: 700;
}

h2 {
  font-size: 1.875rem;
  font-weight: 700;
}

h3 {
  font-size: 1.875rem;
  font-weight: 700;
}

h4 {
  font-size: 1.5rem;
  font-weight: 700;
}

h5 {
  font-size: 1.25rem;
  font-weight: 700;
}

/* Only Chrome */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(93, 94, 97, 0.5);
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 20px;
}

/* IE */
html {
  scrollbar-face-color: rgba(93, 94, 97, 0.5);
  scrollbar-shadow-color: transparent;
  scrollbar-highlight-color: transparent;
}

/* FF */
html {
  scrollbar-color: rgba(93, 94, 97, 0.5) transparent;
}

fieldset {
  margin: 0;
  padding: 0;
}

input {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

input[type=text], input[type=email], input[type=search],
input[type=password], textarea {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
  outline: none !important;
}

/* inspired by: https://css-tricks.com/responsive-data-tables/ */
.responsiveTable {
  width: 100%;
}

.responsiveTable td .tdBefore {
  display: none;
}

@media screen and (max-width: 1119px) {
  /*
    Force table elements to not behave like tables anymore
    Hide table headers (but not display: none;, for accessibility)
  */
  .responsiveTable table,
  .responsiveTable thead,
  .responsiveTable tbody,
  .responsiveTable th,
  .responsiveTable td,
  .responsiveTable tr {
    display: block;
  }
  .responsiveTable thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
    border-bottom: 2px solid #333;
  }
  .responsiveTable tbody tr {
    padding: 0.25em;
  }
  .responsiveTable td.pivoted {
    /* Behave like a "row" */
    border: none !important;
    position: relative;
    padding-left: calc(50% + 10px) !important;
    text-align: left !important;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    font-size: 0.875rem;
    font-weight: 300;
  }
  .responsiveTable td .tdBefore {
    /* Now like a table header */
    position: absolute;
    display: block;
    /* Top/left values mimic padding */
    left: 1rem;
    width: calc(50% - 20px);
    white-space: pre-wrap;
    overflow-wrap: break-word;
    text-align: left !important;
    font-size: 0.75rem;
    color: #A7A8A8;
  }
}
.errorMessage {
  padding: 0;
  position: absolute;
  left: 0;
  right: 0;
  border: 0;
  opacity: 0;
  z-index: 1070;
  text-align: center;
  max-width: 90%;
  margin: auto;
  pointer-events: none;
}
.errorMessage.enter {
  opacity: 0;
  -webkit-transform: translateY(-40px);
  -moz-transform: translateY(-40px);
  -o-transform: translateY(-40px);
  -ms-transform: translateY(-40px);
  transform: translateY(-40px);
}
.errorMessage.enter-active, .errorMessage.enter-done {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  transform: translateY(0);
  transition: all 350ms ease-out;
}
.errorMessage.exit {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.errorMessage.exit-active, .errorMessage.exit-done {
  opacity: 0;
  -webkit-transform: translateY(-40px);
  -moz-transform: translateY(-40px);
  -o-transform: translateY(-40px);
  -ms-transform: translateY(-40px);
  transform: translateY(-40px);
  transition: all 350ms ease-out;
}
.errorMessage .alert-warning {
  display: inline-block;
  padding: 0;
  background-color: #CD0000;
  border-radius: 0 0 12px 12px;
  border: none;
}
.errorMessage .alert-wrapper {
  padding: 5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: none;
  flex-wrap: nowrap;
}
.errorMessage.display {
  opacity: 1;
}
.errorMessage .row {
  padding: 5px;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: none;
  flex-wrap: nowrap;
}
.errorMessage .alert-content {
  position: relative;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media screen and (min-width: 767px) {
  .errorMessage .alert-content {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
}
.errorMessage .icon-container {
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  min-width: 60px;
}
@media screen and (min-width: 767px) {
  .errorMessage .icon-container {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
}
.errorMessage .icon-container .icon {
  fill: white;
  margin: 5px 0 0 5px;
  width: 52px;
  height: 52px;
}
.errorMessage h2 {
  color: white;
  font-size: 1.125rem;
  font-weight: 700;
}
.errorMessage span, .errorMessage i {
  color: white;
  font-weight: 500;
}
.errorMessage .progress {
  border-radius: 0;
  background: transparent;
}
.errorMessage .progress .progress-bar {
  height: 4px;
  line-height: 4px;
  vertical-align: bottom;
  background: linear-gradient(141deg, #f60057 0%, #f60057 51%, #f60057 75%);
}
@media screen and (max-width: 767px) {
  .errorMessage .alert {
    min-width: 140px;
  }
  .errorMessage .row {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.dropup {
  padding-top: 25px;
  display: block;
  text-align: center;
}
.dropup .dropdown-menu {
  margin-bottom: -1.675rem;
}
.dropup .dropdown-menu-center {
  left: 20%;
  background-color: transparent;
  width: 80%;
  border: none;
}
@media screen and (min-device-width: 768px) and (max-device-width: 960px) {
  .dropup .dropdown-menu-center {
    width: 60%;
  }
}
@media screen and (min-device-width: 960px) and (max-device-width: 1200px) {
  .dropup .dropdown-menu-center {
    width: 60%;
  }
}
.dropup .dropdown-menu-center a {
  color: #FFF;
  background-color: #12aaeb;
  padding: 37px;
  margin-top: 5px;
  border-radius: 10px;
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
}
.dropup .dropdown-menu-center a.disabled {
  pointer-events: none;
  cursor: default;
  background-color: #404040;
}

.dropup button.dropdown-toggle {
  width: 80%;
  background-color: #404040;
  height: 100px;
  border: none;
  color: #FFF;
  font-size: 1.5rem;
  position: relative;
}
.dropup button.dropdown-toggle:hover {
  background-color: #606060;
}
.dropup button.dropdown-toggle:focus {
  background-color: #606060;
  color: #FFF;
  border: 1px solid #FFF;
  box-shadow: none;
}
.dropup button.dropdown-toggle:after {
  display: none;
}
.dropup button.dropdown-toggle i {
  position: absolute;
  left: 6%;
  top: 40%;
  opacity: 0.7;
}
.dropup button.dropdown-toggle .duration-amount {
  font-weight: 100;
}

@media screen and (min-device-width: 1201px) and (max-device-width: 1500px) {
  .dropup .dropdown-toggle {
    height: 70px;
    width: 60%;
  }
}
@media screen and (min-device-width: 996px) and (max-device-width: 1200px) {
  .dropup button.dropdown-toggle {
    height: 70px;
    width: 65%;
    font-size: 1rem;
  }
  .dropup button.dropdown-toggle i {
    left: 2%;
  }
}
@media screen and (min-device-width: 760px) and (max-device-width: 990px) {
  .dropup button.dropdown-toggle {
    height: 70px;
    width: 60%;
    font-size: 1rem;
  }
  .dropup button.dropdown-toggle i {
    left: 2%;
  }
}
.duration-label {
  font-weight: 200;
}

.bubble-spinner {
  position: absolute;
  top: 40%;
  left: 40%;
}

.ball-clip-rotate div {
  width: 100px;
  height: 100px;
}

.card-block {
  padding: 0;
}

.odd {
  background-color: #3f3f3f;
  padding: 10px 0 0 0;
}

.even {
  padding: 10px 0 0 0;
}

.leaderboard.content-box {
  overflow: auto;
}
@media screen and (max-width: 1119px) {
  .leaderboard {
    width: unset;
  }
}
@media screen and (min-width: 1120px) {
  .leaderboard {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1120px) {
  .leaderboard__container {
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
}
.leaderboard__heading {
  display: flex;
  flex-direction: row;
  height: 60px;
  padding: 20px;
}
@media screen and (max-width: 1119px) {
  .leaderboard__heading {
    max-width: 100%;
    text-align: center;
  }
}
.leaderboard__heading h3 {
  flex: 1;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
}
@media screen and (max-width: 1119px) {
  .leaderboard__heading h3 {
    font-weight: 600;
  }
}
.leaderboard__holder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  align-items: stretch;
  height: 100%;
  margin: 0 20px;
  position: relative;
}
@media screen and (max-width: 1119px) {
  .leaderboard__holder {
    margin: 0;
  }
}
.leaderboard__holder span.subtitle {
  opacity: 0.6;
  display: block;
  text-align: center;
  margin: 0 0 2rem 0;
  font-size: 0.875rem;
}
.leaderboard__holder .cups-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  margin: 0 auto;
}
@media screen and (min-width: 1400px) {
  .leaderboard__holder .cups-container {
    margin: 20px auto;
  }
}
@media screen and (min-width: 1120px) {
  .leaderboard__holder .cups-container {
    flex: 1;
  }
}
@media screen and (max-width: 1119px) {
  .leaderboard__holder .cups-container {
    text-align: center;
    max-width: 320px;
  }
}
.leaderboard__holder .cups-container img {
  display: block;
  width: 100%;
  height: auto;
}
@media screen and (max-width: 1119px) {
  .leaderboard__holder .cups-container img {
    height: auto;
    max-width: 100%;
    width: 90px;
    margin: auto;
  }
}
.leaderboard__holder .cups-container p {
  text-align: center;
  font-size: 0.875rem;
  margin-top: 0.2rem;
}
@media screen and (max-width: 1119px) {
  .leaderboard__holder .cups-container p {
    font-weight: 500;
  }
}
.leaderboard__holder .cups-container .small-cup-container {
  -webkit-box-flex: 3;
  -webkit-flex: 3;
  -moz-box-flex: 3;
  -moz-flex: 3;
  -ms-flex: 3;
  flex: 3;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.leaderboard__holder .cups-container .small-cup-container p {
  font-size: 1.125rem;
  font-weight: 700;
  text-shadow: 2.73943px 4.56572px 4.56572px rgba(0, 0, 0, 0.25);
}
.leaderboard__holder .cups-container .small-cup-container--hidden {
  visibility: hidden;
}
.leaderboard__holder .cups-container .big-cup-container {
  -webkit-box-flex: 4;
  -webkit-flex: 4;
  -moz-box-flex: 4;
  -moz-flex: 4;
  -ms-flex: 4;
  flex: 4;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  margin: 0 0.5rem;
}
.leaderboard__holder .cups-container .big-cup-container p {
  font-size: 1.125rem;
  font-weight: bold;
  text-shadow: 2.73943px 4.56572px 4.56572px rgba(0, 0, 0, 0.25);
}
.leaderboard__holder .leaderboard-content {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.leaderboard__holder .leaderboard-content .header-wrapper {
  display: flex;
}
.leaderboard__holder .flipper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow: auto;
  position: relative;
  -webkit-overflow-scrolling: touch;
}
.leaderboard__holder .leaderboard-list {
  margin: 0 20px;
}
@media screen and (max-width: 1119px) {
  .leaderboard__holder .leaderboard-list {
    font-size: 0.75rem;
    margin: 0;
  }
}
.leaderboard__holder .leaderboard-list-row {
  margin: 0.2rem 0;
  padding: 0.5rem 1rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  background: rgba(42, 46, 49, 0.75);
}
.leaderboard__holder .leaderboard-list-row.top {
  background: linear-gradient(134.22deg, #565A5C -63.52%, #2a2e31 74.66%), #2a2e31;
}
.leaderboard__holder .leaderboard-list-row:not(.top):not(.current-user):nth-child(odd) {
  background: rgba(42, 46, 49, 0.5);
}
.leaderboard__holder .leaderboard-list-row span {
  display: block;
  font-weight: 400;
}
.leaderboard__holder .leaderboard-list-row span.black {
  color: black;
}
.leaderboard__holder .leaderboard-list-row .cup-wrapper, .leaderboard__holder .leaderboard-list-row span.rank {
  flex: 0.5;
  text-align: center;
  margin-left: -0.8rem;
  display: flex;
  justify-content: center;
}
.leaderboard__holder .leaderboard-list-row span.rank {
  font-weight: bold;
}
.leaderboard__holder .leaderboard-list-row span.rank.up {
  color: #55D636;
}
.leaderboard__holder .leaderboard-list-row span.rank.down {
  color: #CD0000;
}
.leaderboard__holder .leaderboard-list-row .rank-icon-wrapper {
  flex: 0.3;
}
.leaderboard__holder .leaderboard-list-row .rank-icon-wrapper img {
  width: 65%;
  max-width: 20px;
  margin-left: -33%;
}
.leaderboard__holder .leaderboard-list-row span.usernameText {
  flex: 3;
  opacity: 0.6;
}
.leaderboard__holder .leaderboard-list-row.top span.usernameText, .leaderboard__holder .leaderboard-list-row.current-user span.usernameText {
  opacity: 1;
}
.leaderboard__holder .leaderboard-list-row.current-user {
  border: 1px solid white;
  position: sticky;
  bottom: 0;
  background: #2a2e31;
}
.leaderboard__holder .leaderboard-list-row.current-user span.usernameText {
  font-weight: bold;
}
.leaderboard__holder .leaderboard-list-row span.scoreText {
  flex: 1;
  margin-right: 0.2rem;
  text-align: right;
  opacity: 0.6;
}
.leaderboard__holder .leaderboard-list-row.top span.scoreText, .leaderboard__holder .leaderboard-list-row.current-user span.scoreText {
  opacity: 1;
}
.leaderboard__holder .leaderboard-list-row.current-user span.scoreText {
  font-weight: bold;
}
.leaderboard__holder .leaderboard-list-row img {
  display: block;
  width: 1.3rem;
  height: auto;
}
@media screen and (max-width: 1119px) {
  .leaderboard__holder .leaderboard-list-row img {
    width: 1.2rem;
  }
}
.leaderboard__holder .nickname-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.leaderboard__holder .nickname-container .image-container {
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 1rem;
  height: 0;
  min-height: 75px;
}
.leaderboard__holder .nickname-container .image-container img {
  height: 100%;
}
.leaderboard__holder .nickname-container .nickname-input-container {
  margin: 0 20px;
}
.leaderboard__holder .nickname-container .nickname-input-container .input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.5rem;
  width: 100%;
}
.leaderboard__holder .nickname-container .nickname-input-container input#nickname {
  text-align: center;
  background: none;
  color: white;
  border-radius: 0;
  border: 0;
  border-bottom: 2px solid #383D44;
  font-size: 1.125rem;
}
.leaderboard__holder .nickname-container .nickname-input-container input#nickname::placeholder {
  color: rgba(255, 255, 255, 0.75);
  font-size: 1.125rem;
}
.leaderboard__holder .nickname-container .nickname-input-container button.save {
  margin-bottom: 1rem;
}
.leaderboard__holder .nickname-container .nickname-input-container a.terms {
  margin-bottom: 0;
}
.leaderboard__holder .leaderboard-message {
  height: calc(100% - 60px);
  margin: 0 20px;
}
.leaderboard__holder .leaderboard-message > span {
  display: block;
  width: 100%;
  text-align: center;
  font-weight: bold;
  padding: 2rem 1rem 0;
}
.leaderboard__holder .image-wrapper {
  margin: auto;
  width: 80%;
  max-width: 350px;
  flex: 1;
  min-height: 0;
}
@media screen and (min-width: 1120px) and (max-height: 899px) {
  .leaderboard__holder .image-wrapper {
    max-width: 200px;
  }
}
.leaderboard__holder .image-wrapper img.no-active-competitions {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.page-container.leaderboard .container {
  flex: 1;
  display: flex;
}

.chart {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
  padding: 10px 0;
}
@media screen and (max-width: 1119px) {
  .chart {
    height: 100%;
    padding: 0 0 10px 0;
  }
}
@media screen and (min-width: 1120px) {
  .chart {
    padding: 20px;
  }
}
.chart__holder {
  position: relative !important;
  flex: 1 1 0;
  min-height: 0;
}
@media screen and (min-width: 1120px) {
  .chart__holder--market-closed {
    filter: grayscale(0.7);
  }
}
.chart__holder div .controls {
  width: calc(100% - 20px);
  height: auto;
  position: absolute;
  top: 10px;
  left: 10px;
}
@media screen and (min-width: 1120px) {
  .chart__holder div .controls {
    top: -80px;
  }
}
.chart__meta {
  padding: 10px;
  display: flex;
  flex: 0 1 0;
  justify-content: space-between;
}
@media screen and (max-width: 1119px) {
  .chart__meta {
    align-items: center;
    padding: 0 0 10px;
  }
}
@media screen and (max-height: 640px) {
  .chart__meta {
    padding: 5px 0;
  }
}
@media screen and (min-width: 1120px) {
  .chart__meta {
    padding: 10px 0;
  }
}
.chart__meta .info-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}
@media screen and (max-width: 1119px) {
  .chart__meta .info-left {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 49%;
    -moz-box-flex: 0;
    -moz-flex: 0 1 49%;
    -ms-flex: 0 1 49%;
    flex: 0 1 49%;
  }
}
.chart__meta .info-left .chart-controls-desktop {
  margin: 0 15px 0 0;
}
@media screen and (min-width: 1400px) {
  .chart__meta .info-left .chart-controls-desktop {
    margin: 0 30px 0 0;
  }
}
.chart__meta .info-left .chart-controls-desktop .segmented-controls {
  position: relative;
}
.chart__meta .info-left .trend-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 48%;
  -moz-box-flex: 0;
  -moz-flex: 0 1 48%;
  -ms-flex: 0 1 48%;
  flex: 0 1 48%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  align-items: stretch;
  background: rgba(42, 46, 49, 0.5);
  border: 1.5px solid #2a2e31;
  box-sizing: border-box;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  min-height: 65px;
}
.chart__meta .info-left .trend-container .rise-fall-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.chart__meta .info-left .trade-instrument-info {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}
@media screen and (max-width: 1119px) {
  .chart__meta .info-left .trade-instrument-info {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 48%;
    -moz-box-flex: 0;
    -moz-flex: 0 1 48%;
    -ms-flex: 0 1 48%;
    flex: 0 1 48%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    background: rgba(42, 46, 49, 0.5);
    border: 1.5px solid #2a2e31;
    box-sizing: border-box;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    padding: 10px 0;
    min-height: 65px;
  }
}
@media screen and (min-width: 1120px) {
  .chart__meta .info-left .trade-instrument-info {
    max-width: 180px;
    max-height: 42px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
  }
}
.chart__meta .info-left .trade-instrument-info .icon-holder {
  position: relative;
  width: 42px;
  height: 42px;
  position: relative;
  margin-right: 15px;
}
@media screen and (max-width: 1119px) {
  .chart__meta .info-left .trade-instrument-info .icon-holder {
    margin-right: 0;
  }
}
.chart__meta .info-left .trade-instrument-info .icon-holder:before {
  content: url(~/src/icons/main/arrow-right.svg);
  width: 42px;
  height: 42px;
  position: relative;
  top: -10px;
  right: -33px;
  display: block;
}
.chart__meta .info-left .trade-instrument-info .icon-holder:after {
  content: url(~/src/icons/main/arrow-left.svg);
  width: 42px;
  height: 42px;
  position: absolute;
  bottom: -25px;
  left: -5px;
  display: block;
}
.chart__meta .info-left .trade-instrument-info .icon-holder .trade-instrument-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}
.chart__meta .info-left .trade-instrument-info .trade-instrument-name {
  font-size: 1.125rem;
  font-weight: 700;
  padding-top: 3px;
}
@media screen and (max-width: 1119px) {
  .chart__meta .info-left .trade-instrument-info .trade-instrument-name {
    display: none;
  }
}
@media screen and (max-width: 1119px) {
  .chart__meta .prices-mobile {
    background: linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 49%;
    -moz-box-flex: 0;
    -moz-flex: 0 1 49%;
    -ms-flex: 0 1 49%;
    flex: 0 1 49%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    min-height: 65px;
  }
}
.chart__meta .prices-mobile .buy-sell-prices {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end;
}
@media screen and (max-width: 1119px) {
  .chart__meta .prices-mobile .buy-sell-prices {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 2;
    -webkit-flex: 2;
    -moz-box-flex: 2;
    -moz-flex: 2;
    -ms-flex: 2;
    flex: 2;
  }
}
.chart__meta .prices-mobile .buy-sell-prices > span {
  margin: 0px;
  border: 2px #646d7b solid;
  border-radius: 20px;
  display: inline-block;
  padding: 2px 15px;
  text-align: center;
  font-size: 0.75rem;
  margin-bottom: 5px;
  white-space: nowrap;
  text-transform: uppercase;
}
@media screen and (max-width: 1119px) {
  .chart__meta .prices-mobile .buy-sell-prices > span {
    margin: 0;
    padding: 0 0 0 15px;
    text-align: left;
    border: none;
    padding: 0 15px;
    display: block;
    color: white !important;
  }
}
@media screen and (max-width: 1119px) {
  .chart__meta .prices-mobile .buy-sell-prices > span {
    font-size: 0.75rem;
    font-weight: 500;
  }
}
@media screen and (max-width: 340px) {
  .chart__meta .prices-mobile .buy-sell-prices > span {
    padding: 0 0 0 5px;
  }
}
@media screen and (max-width: 1119px) {
  .chart__meta .prices-mobile .buy-sell-prices > span .label-bid-container span {
    font-size: 1.125rem;
  }
}
@media screen and (max-width: 991px) {
  .chart__meta .prices-mobile .buy-sell-prices > span .label-bid-container span {
    font-size: 1rem;
  }
}
@media screen and (max-width: 600px) {
  .chart__meta .prices-mobile .buy-sell-prices > span .label-bid-container span {
    font-size: 0.75rem;
  }
}
.chart__meta .prices-mobile .buy-sell-prices .desktop {
  display: none;
}
@media screen and (min-width: 1120px) {
  .chart__meta .prices-mobile .buy-sell-prices .desktop {
    display: block;
  }
}
@media screen and (min-width: 1120px) {
  .chart__meta .prices-mobile .buy-sell-prices .trade-instrument-name {
    display: none;
  }
}
@media screen and (max-width: 1119px) {
  .chart__meta .prices-mobile .buy-sell-prices .trade-instrument-name {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
  }
}
@media screen and (max-width: 1119px) {
  .chart__meta .prices-mobile .buy-sell-prices .instrument-quote-container {
    padding: 0 15px;
    margin: 0;
  }
}
@media screen and (max-width: 340px) {
  .chart__meta .prices-mobile .buy-sell-prices .instrument-quote-container {
    padding: 0 0 0 5px;
  }
}
@media screen and (min-width: 1120px) {
  .chart__meta .prices-mobile .buy-sell-prices .instrument-quote-container {
    display: none;
  }
}
.chart__meta .prices-mobile .buy-sell-prices > span.buy:before, .chart__meta .prices-mobile .buy-sell-mobile > span.buy:before {
  width: 9px;
  height: 9px;
  position: static;
  top: 0px;
  margin-right: 2px;
  background: #4BB84E;
  display: inline-block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  content: "";
}
@media screen and (min-width: 1120px) {
  .chart__meta .prices-mobile .buy-sell-prices > span.buy:before, .chart__meta .prices-mobile .buy-sell-mobile > span.buy:before {
    content: none;
    width: 14px;
    height: 14px;
    position: relative;
    top: 1px;
    margin-right: 4px;
  }
}
.chart__meta .prices-mobile .buy-sell-prices > span.sell:before, .chart__meta .prices-mobile .buy-sell-mobile > span.sell:before {
  width: 9px;
  height: 9px;
  position: static;
  top: 0px;
  margin-right: 2px;
  background-color: #f5004d;
  display: inline-block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  content: "";
}
@media screen and (min-width: 1120px) {
  .chart__meta .prices-mobile .buy-sell-prices > span.sell:before, .chart__meta .prices-mobile .buy-sell-mobile > span.sell:before {
    content: none;
    width: 14px;
    height: 14px;
    position: relative;
    top: 1px;
    margin-right: 4px;
  }
}
.chart__meta .prices-mobile .buy-sell-mobile {
  font-size: 0.75rem;
}
@media screen and (max-width: 1119px) {
  .chart__meta .prices-mobile .buy-sell-mobile {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 2;
    -webkit-flex: 2;
    -moz-box-flex: 2;
    -moz-flex: 2;
    -ms-flex: 2;
    flex: 2;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
  }
}
@media screen and (min-width: 1120px) {
  .chart__meta .prices-mobile .buy-sell-mobile {
    display: none;
  }
}
@media screen and (max-width: 1119px) {
  .chart__meta .chart-range-selector li a, .chart__meta .chart-range-selector li.disabled a:hover {
    border: none;
    color: #ffffff;
  }
}
@media screen and (max-width: 991px) {
  .chart__meta .registerMain {
    overflow-y: auto;
  }
}
@media screen and (max-width: 991px) {
  .chart__meta .registerMain .form-add {
    width: 100% !important;
  }
}
@media screen and (max-width: 991px) {
  .chart__meta .registerMain .dropzone-title, .chart__meta .registerMain .dropzone-container .dropzone {
    width: 100%;
  }
}
.chart .bubble-spinner {
  position: absolute;
  left: calc(50% - 20px);
  top: 10px;
}
.chart .progress-container {
  position: relative;
  height: 25px;
}
.chart .progress-container .progress-bar {
  line-height: 25px;
  height: 25px;
  vertical-align: middle;
  background: linear-gradient(141deg, #12aaeb 0%, #12bfea 51%, #12cdea 75%);
  -webkit-transition: width 1s; /* Safari */
  transition: width 1s;
}
.chart .progress-container .progress-duration {
  position: absolute;
  top: 0px;
  width: 100%;
}

@keyframes pulsex {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.02);
  }
  20% {
    transform: scale(1.05);
  }
  20% {
    transform: scale(1.07);
  }
  40% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.12);
  }
  60% {
    transform: scale(1.1);
  }
  70% {
    transform: scale(1.07);
  }
  80% {
    transform: scale(1.05);
  }
  90% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    transform: scaleX(1) scaleY(1);
  }
  50% {
    transform: scaleX(1.12) scaleY(1.12);
  }
  100% {
    transform: scaleX(1) scaleY(1);
  }
}
@media (max-width: 1025px) {
  .instrument_chart {
    padding: 10px 15px 20px 10px;
  }
}
#chart_profitloss {
  position: absolute;
  left: calc(50% - 50px);
  top: 20px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  line-height: 100px;
  text-align: center;
  z-index: 2;
  font-size: 1.125rem;
  font-weight: 700;
}
#chart_profitloss.centered {
  top: calc(50% - 50px);
}
#chart_profitloss.new:after {
  height: calc(100px + 20px);
  width: calc(100px + 20px);
}
@media screen and (max-width: 1119px) {
  #chart_profitloss {
    height: 90px;
    width: 90px;
    line-height: 90px;
  }
  #chart_profitloss.new:after {
    height: calc(90px + 20px);
    width: calc(90px + 20px);
  }
}
@media screen and (max-width: 600px) {
  #chart_profitloss {
    height: 70px;
    width: 70px;
    line-height: 70px;
    font-weight: 500;
    font-size: 1rem;
    left: calc(50% - 30px);
  }
  #chart_profitloss.new:after {
    height: calc(70px + 20px);
    width: calc(70px + 20px);
  }
}
@media screen and (max-width: 540px) {
  #chart_profitloss {
    height: 60px;
    width: 60px;
    line-height: 60px;
    font-weight: 500;
    font-size: 0.75rem;
    left: calc(50% - 25px);
  }
  #chart_profitloss.new:after {
    height: calc(60px + 20px);
    width: calc(60px + 20px);
  }
}
#chart_profitloss:after {
  content: "";
  display: block;
  position: absolute;
  background: none;
  top: -3px;
  left: -3px;
  height: 106px;
  width: 106px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
@media screen and (max-width: 1119px) {
  #chart_profitloss:after {
    height: 96px;
    width: 96px;
  }
}
@media screen and (max-width: 600px) {
  #chart_profitloss:after {
    height: 76px;
    width: 76px;
  }
}
@media screen and (max-width: 540px) {
  #chart_profitloss:after {
    height: 66px;
    width: 66px;
  }
}
#chart_profitloss.lost {
  background: linear-gradient(103.09deg, #f60057 21.7%, #bb173e 59.43%);
  animation: pulse ease-in-out 1s;
  animation-iteration-count: infinite;
}
#chart_profitloss.lost:after {
  border: 1px solid #f70057;
}
#chart_profitloss.won {
  background: linear-gradient(103.09deg, #70c157 21.7%, #3e8c3b 59.43%);
  animation: pulse ease-in-out 1s;
  animation-iteration-count: infinite;
}
#chart_profitloss.won:after {
  border: 1px solid #55c058;
}
#chart_profitloss.position-closing {
  background-color: #3f3f3f;
  animation: none;
}
#chart_profitloss.position-closing:after {
  border: 1px solid #3f3f3f;
}
#chart_profitloss.new {
  background-color: #3f3f3f;
}
#chart_profitloss.new:after {
  content: "";
  position: absolute;
  top: -10px;
  bottom: 0;
  left: -10px;
  right: 0;
  border: 10px solid transparent;
  border-radius: 50%;
  border-top: 10px solid white;
  -webkit-animation: spin 1.3s linear infinite; /* Safari */
  animation: spin 1.3s linear infinite;
}

.bubble-burst-container {
  position: absolute;
  top: 100px;
  left: calc(50% - 40px);
  right: 0;
  bottom: -50%;
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: left bottom;
  pointer-events: none;
  z-index: 1;
}
@media screen and (max-width: 600px) {
  .bubble-burst-container {
    position: absolute;
    top: 80px;
    left: calc(50% - 30px);
    right: 0;
    bottom: -50%;
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: left bottom;
  }
}
@media screen and (max-width: 540px) {
  .bubble-burst-container {
    position: absolute;
    top: 70px;
    left: calc(50% - 25px);
    right: 0;
    bottom: -50%;
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: left bottom;
  }
}

Ž .install-button-wrapper {
  padding-top: 5px;
}
Ž .install-button-wrapper button {
  padding: 3px 45px;
  outline: none;
  border: none;
}

#iosInstallNotification {
  position: absolute;
  bottom: 50px;
  width: 100%;
  text-align: center;
  display: block;
  color: black;
  z-index: 99999;
}
#iosInstallNotification div.holder {
  display: inline-block;
  background-color: #fff;
  width: 90%;
  padding: 30px 20px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid transparent;
  position: relative;
  box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.5);
}
#iosInstallNotification div.holder:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -19px;
  width: 0;
  height: 0;
  border-top: solid 19px #fff;
  border-left: solid 19px transparent;
  border-right: solid 19px transparent;
}
#iosInstallNotification div.holder .title {
  color: #18191d;
  font-weight: 700;
  margin: 0 0 10px 0;
  font-size: 1.5rem;
  line-height: 24px;
}
#iosInstallNotification div.holder .description {
  max-width: 200px;
  color: rgba(24, 25, 29, 0.5);
  font-size: 0.875rem;
  line-height: 21px;
  margin: 0 auto;
}
#iosInstallNotification div.holder .description .tapicon {
  width: 24px;
  height: 24px;
  background: #F7F7F7;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #EEEEEE;
  position: relative;
  display: inline-block;
  margin: 5px 5px -8px 5px;
}
#iosInstallNotification div.holder .description .tapicon img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
#iosInstallNotification div.holder .description .homescreen strong {
  color: #18191d;
  font-weight: 700;
  word-break: break-word;
  display: block;
  margin-top: 7px;
}
#iosInstallNotification div.holder .close-popup {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
#iosInstallNotification div.holder .close-popup svg {
  width: 26px;
  height: 26px;
  margin-bottom: 5px;
}

#androidInstallNotification {
  position: absolute;
  top: 70px;
  bottom: 70px;
  left: 20px;
  right: 20px;
  text-align: center;
  display: block;
  color: black;
  z-index: 99999;
}
#androidInstallNotification > div {
  background-color: #fff;
  background-image: url(~/src/images/install-popup-background-bubbles.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  padding: 3rem 1rem;
  border-radius: 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#androidInstallNotification > div .cta-header {
  flex: 1;
  display: flex;
  flex-direction: column;
}
#androidInstallNotification > div h2 {
  margin: 0 15%;
}
#androidInstallNotification > div h2, #androidInstallNotification > div strong {
  color: #18191D;
}
#androidInstallNotification > div p {
  margin: 2rem 10% 0;
  color: rgba(24, 25, 29, 0.5);
}
#androidInstallNotification > div .image-wrapper {
  flex: 1;
  padding: 0 5%;
}
#androidInstallNotification > div .image-wrapper img {
  width: 100%;
  filter: drop-shadow(10px 10px 30px rgba(0, 0, 0, 0.15));
}
#androidInstallNotification > div .install-button-wrapper {
  flex: 1;
  display: flex;
  align-items: flex-end;
}
#androidInstallNotification .close-popup svg {
  width: 25px;
  height: 25px;
}
#androidInstallNotification strong {
  font-weight: 700;
}

.closed-position {
  margin: 0 0 10px 0;
  overflow: hidden;
  font-size: 0.75rem;
}
@media screen and (min-width: 600px) and (max-width: 1120px) {
  .closed-position {
    font-size: 0.875rem;
  }
}
.closed-position__main-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  padding: 10px 10px 5px;
  background: #2a2b31; /* The Fallback */
  background: rgba(42, 43, 49, 0.5);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
}
.closed-position__main-row .rise-fall-image-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-flex: 2;
  -webkit-flex: 2;
  -moz-box-flex: 2;
  -moz-flex: 2;
  -ms-flex: 2;
  flex: 2;
}
.closed-position__main-row .rise-fall-image-container .rise-fall-container {
  margin-left: 5px;
  margin-right: 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}
.closed-position__main-row .rise-fall-image-container img.instrument {
  width: 48px;
  height: 48px;
}
.closed-position__main-row .label-bid-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 3;
  -webkit-flex: 3;
  -moz-box-flex: 3;
  -moz-flex: 3;
  -ms-flex: 3;
  flex: 3;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
@media screen and (max-width: 360px) {
  .closed-position__main-row .label-bid-container {
    -webkit-box-flex: 2;
    -webkit-flex: 2;
    -moz-box-flex: 2;
    -moz-flex: 2;
    -ms-flex: 2;
    flex: 2;
  }
}
.closed-position__main-row .label-bid-container .currency {
  color: #ffffff;
  font-weight: 700;
  font-size: 0.75rem;
}
.closed-position__main-row .position-stats {
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 2;
  -webkit-flex: 2;
  -moz-box-flex: 2;
  -moz-flex: 2;
  -ms-flex: 2;
  flex: 2;
}
.closed-position__main-row .position-stats__item {
  max-width: unset;
}
.closed-position__second-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  margin-top: 2px;
  min-height: 24px;
}
.closed-position__second-row .close-time {
  background: #2a2b31; /* The Fallback */
  background: rgba(42, 43, 49, 0.5);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 33%;
  -ms-flex: 0 0 33%;
  flex: 0 0 33%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.closed-position__second-row .close-time span {
  font-size: 0.75rem;
  color: #8B959E;
  margin: 0 auto;
}
.closed-position__second-row .stop-reasons {
  background: linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 33%;
  -ms-flex: 0 0 33%;
  flex: 0 0 33%;
  margin: 0 2px;
  position: relative;
}
.closed-position__second-row .stop-reasons .wrap {
  margin: 0 auto;
  width: max-content;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.closed-position__second-row .stop-reasons .wrap img {
  margin-right: 5px;
}
.closed-position__second-row .stop-reasons .wrap span {
  color: #fff;
  font-size: 0.75rem;
}
.closed-position__second-row .profit-loss {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 33%;
  -ms-flex: 0 0 33%;
  flex: 0 0 33%;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.closed-position__second-row .profit-loss.positive {
  background: linear-gradient(103.09deg, #70c157 21.7%, #3e8c3b 59.43%);
}
.closed-position__second-row .profit-loss.negative {
  background: linear-gradient(103.09deg, #f60057 21.7%, #bb173e 59.43%);
}
.closed-position__second-row .profit-loss span {
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  margin: 0 auto;
}
.closed-position .pill {
  margin-top: 0.3rem;
}

.instrument-quote-indicator {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  -webkit-justify-content: space-evenly;
  -moz-justify-content: space-evenly;
  justify-content: space-evenly;
}
.instrument-quote-indicator svg {
  margin-right: 2px;
}
.instrument-quote-indicator span {
  font-size: 0.75rem;
  color: #FFF;
}

.progress-bar {
  height: 100%;
  width: 100%;
  position: relative;
  background-color: transparent;
}
.progress-bar__bar {
  height: 100%;
  vertical-align: middle;
  background: #0d354f;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  -webkit-transition: width 0.6s ease;
  -moz-transition: width 0.6s ease;
  -ms-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}
.progress-bar__value_wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.progress-bar__value {
  font-size: 0.6875rem;
  line-height: 100%;
}

#position-open-price {
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
#position-open-price span {
  font-size: 0.75rem;
  color: #FFF;
}
@media screen and (max-width: 600px) {
  #position-open-price span {
    font-size: 0.75rem;
  }
}
@media screen and (max-width: 1119px) {
  #position-open-price img {
    margin-right: 5px;
    width: 15px;
    height: 15px;
  }
}
@media screen and (max-width: 600px) {
  #position-open-price img {
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }
}

.position-stats {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}
.position-stats__item {
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 50%;
  -moz-box-flex: 0;
  -moz-flex: 0 1 50%;
  -ms-flex: 0 1 50%;
  flex: 0 1 50%;
  padding: 0 10px;
  max-width: 50%;
  margin: 0;
  font-size: 0.75rem;
}
@media screen and (max-width: 480px) {
  .position-stats__item {
    padding: 0 5px;
  }
}
.position-stats__item span {
  text-align: right;
  color: #ffffff;
}
.position-stats__item img {
  width: 0.8em;
  margin-right: 10px;
}
@media screen and (max-width: 480px) {
  .position-stats__item img {
    margin-right: 5px;
  }
}
.position-stats__item svg {
  width: 0.9em;
}
@media screen and (max-width: 539px) {
  .position-stats__item svg {
    width: 0.9em;
  }
}
@media screen and (max-width: 480px) {
  .position-stats__item.clock {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

#soundbank-loader {
  z-index: 99999999999;
  position: absolute;
  bottom: 0;
  right: 110px;
  left: 110px;
  height: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  align-items: stretch;
  background-color: #18191d;
}
@media screen and (max-width: 1119px) {
  #soundbank-loader {
    right: 0;
    left: 0;
  }
}
#soundbank-loader span {
  text-align: center;
  margin-bottom: 1rem;
}
#soundbank-loader .progressbar-container {
  border-radius: 12px;
  overflow: hidden;
}
@media screen and (max-width: 1119px) {
  #soundbank-loader .progressbar-container {
    margin: 0px 20px;
  }
}
#soundbank-loader .progressbar-container .progress-bar__value {
  font-size: 0.875rem;
}

.offline-notice {
  position: fixed;
  top: 0;
  left: 50px;
  right: 50px;
  z-index: 100;
  background-color: #303640;
  padding: 10px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  will-change: transform, opacity;
  opacity: 0;
  transform-origin: top;
  transition: all 0.3s ease-in-out;
  transform: scaleY(0);
}
.offline-notice.active {
  opacity: 1;
  transform: none;
}
.offline-notice span {
  display: block;
  width: 100%;
  color: white;
  text-align: center;
}

#live-account-only {
  height: 100%;
  margin: 0 20px;
}
#live-account-only .live-account-only-label {
  display: block;
  width: 100%;
  text-align: center;
  font-weight: 700;
  padding: 4rem 1rem 0;
}

#tutorial_modal .info-tutorial {
  padding: 1rem;
  text-align: center;
}
#tutorial_modal .info-tutorial p {
  font-size: 0.875rem;
}
@media screen and (min-width: 767px) {
  #tutorial_modal .info-tutorial p {
    font-size: 1.125rem;
  }
}
@media screen and (max-width: 767px) {
  #tutorial_modal .modal-dialog {
    max-width: 100%;
    margin: 0;
  }
}
@media screen and (max-width: 767px) {
  #tutorial_modal .modal-dialog .modal-content {
    height: 100%;
    padding: 0;
  }
}
@media screen and (max-height: 600px) {
  #tutorial_modal .modal-dialog .modal-content {
    overflow-y: auto;
    overflow-x: hidden;
  }
}
@media screen and (max-width: 767px) {
  #tutorial_modal .modal-dialog .modal-content .modal_btns .button {
    padding: 15px 25px;
    font-size: 0.75rem;
    background: transparent;
    border: 2px solid #ffffff;
    border-radius: 30px;
    width: 190px;
    max-width: 100%;
    font-weight: 600;
  }
}
@media screen and (max-width: 991px) {
  #tutorial_modal .modal-dialog .modal-content .modal_btns .button {
    font-size: 1rem;
  }
}
@media only screen and (min-width: 1121px) and (max-height: 660px) {
  #tutorial_modal .video-container {
    width: 100vh;
  }
}
@media only screen and (min-width: 1121px) and (max-height: 480px) {
  #tutorial_modal .video-container {
    width: 70vh;
  }
}

#popup-notifications {
  position: absolute;
  right: 0;
  z-index: 9999;
}
@media screen and (max-width: 600px) {
  #popup-notifications {
    left: 0;
  }
}

.popup-notification {
  margin: 0.5rem 1.5rem;
}
@media screen and (max-width: 600px) {
  .popup-notification {
    margin: 1rem;
  }
}
.popup-notification.enter {
  opacity: 0.01;
  transform: translate(-40px, 0);
}
.popup-notification.enter-active {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 350ms ease-out;
}
.popup-notification.exit {
  opacity: 1;
  transform: translate(0, 0);
}
.popup-notification.exit-active {
  opacity: 0.01;
  transform: translate(40px, 0);
  transition: all 350ms ease-out;
}
.popup-notification__container {
  background: #fff;
  color: #2a2e31;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}
@media screen and (min-width: 600px) {
  .popup-notification__container {
    min-width: 347px;
  }
}
.popup-notification__container .holder {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  background: #E4E6E9;
  max-width: 70px;
}
.popup-notification__container .holder .icon {
  width: 48px;
  height: 25px;
}
.popup-notification__container .content {
  -webkit-box-flex: 3;
  -webkit-flex: 3;
  -moz-box-flex: 3;
  -moz-flex: 3;
  -ms-flex: 3;
  flex: 3;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 20px;
}
.popup-notification__container .content .tip {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  -webkit-align-items: baseline;
  -moz-align-items: baseline;
  align-items: baseline;
}
.popup-notification__container .content .tip .label-container {
  padding-right: 10px;
}
.popup-notification__container .content .tip .label-container span {
  font-weight: 700;
  font-size: 0.875rem;
}
.popup-notification__container .content .tip .description {
  font-size: 0.875rem;
  line-height: 21px;
  max-width: 170px;
}
.popup-notification__container .content .buttons-container {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
@media screen and (min-width: 600px) {
  .popup-notification__container .content .buttons-container {
    flex-direction: row;
    justify-content: flex-end;
  }
}
.popup-notification__container .content .buttons-container .button {
  line-height: 18px;
  padding: 9px 20px;
  max-height: 38px;
}
@media screen and (max-width: 599px) {
  .popup-notification__container .content .buttons-container .button {
    width: 100%;
  }
}
.popup-notification__container .content .buttons-container .button:not(:last-child) {
  margin-right: 0.5rem;
}
@media screen and (max-width: 599px) {
  .popup-notification__container .content .buttons-container .button:first-child {
    margin-bottom: 10px;
  }
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fullscreen-iframe-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  background-color: #18191d;
}
.fullscreen-iframe-container iframe {
  width: 100%;
  height: 100%;
}
.fullscreen-iframe-container .close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: white;
  color: black;
  font-size: 1.5rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 99999999;
}

@media screen and (max-width: 1120px) {
  .popup-menu {
    width: 100%;
  }
}
.popup-menu a {
  display: inline-block;
  position: relative;
  color: #ffffff;
  font-weight: 200;
}
@media screen and (max-width: 1120px) {
  .popup-menu a {
    display: block;
    font-weight: 600;
  }
}
.popup-menu a:hover {
  color: white;
}
.popup-menu a span {
  position: relative;
  padding: 8px 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  background: linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: block;
  z-index: calc(1000 + 1);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
@media screen and (min-width: 1120px) {
  .popup-menu a span {
    min-width: 140px;
    width: max-content;
    padding: 0 5px;
    font-size: 0.875rem;
    height: 60px;
    display: inline-flex;
    justify-content: center;
    justify-items: center;
  }
}
@media screen and (min-width: 1400px) {
  .popup-menu a span {
    min-width: 140px;
    padding: 11px 5px;
    font-size: 1rem;
  }
}
@media screen and (max-width: 1120px) {
  .popup-menu a span {
    background-image: none;
    background-size: 0;
    padding: 6px;
    text-align: center;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    font-size: 1.125rem;
  }
}
@media screen and (max-width: 600px) {
  .popup-menu a span {
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 340px) {
  .popup-menu a span {
    padding: 2px 5px;
  }
}
.popup-menu a span p {
  margin-bottom: 0;
  font-size: 0.6875rem;
  color: #ffffff;
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
  -ms-opacity: 0.5;
  -o-opacity: 0.5;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
@media screen and (max-width: 1119px) {
  .popup-menu a span strong {
    display: block;
    color: #ffffff;
    font-weight: 600;
  }
}
.popup-menu a strong {
  font-weight: 700;
  font-size: 0.875rem;
}
@media screen and (min-width: 1120px) {
  .popup-menu a strong {
    font-size: 1.125rem;
  }
}
.popup-menu a > ul {
  z-index: 1000;
  background: linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.35);
  list-style-type: none;
  display: none;
  position: absolute;
  bottom: 25px;
  left: 0;
  width: 100%;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  -ms-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
  padding-bottom: 20px;
}
@media screen and (max-width: 1120px) {
  .popup-menu a > ul {
    bottom: 100%;
    padding: 0;
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    -ms-border-radius: 5px 5px 0 0;
    border-radius: 5px 5px 0 0;
    background: transparent;
    overflow: hidden;
    margin-bottom: 0;
  }
}
.popup-menu a > ul li {
  font-size: 1rem;
  text-align: center;
  width: 100%;
  padding: 10px;
  font-weight: 700;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  cursor: pointer;
}
@media screen and (max-width: 1120px) {
  .popup-menu a > ul li {
    font-weight: 400;
    color: white;
    font-size: 1rem;
    background: #141618;
    padding: 10px;
    margin-bottom: 4px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
  }
}
@media screen and (max-width: 600px) {
  .popup-menu a > ul li {
    font-size: 0.75rem;
  }
}
.popup-menu a > ul li img.mobile-only {
  display: none;
}
.popup-menu a > ul li img.mobile-only:first-child {
  margin-right: auto;
}
.popup-menu a > ul li img.mobile-only:last-child {
  margin-left: auto;
}
@media screen and (max-width: 1120px) {
  .popup-menu a > ul li img.mobile-only {
    display: block;
  }
}
@media screen and (max-width: 1120px) {
  .popup-menu a > ul li:not(.disabled):hover {
    background: #2a3033;
  }
}
@media screen and (min-width: 1120px) {
  .popup-menu a > ul li:not(.disabled):hover {
    background: rgba(0, 0, 0, 0.2);
  }
}
.popup-menu a > ul li.disabled {
  opacity: 0.4;
  cursor: default;
}
.popup-menu a:hover {
  text-decoration: none;
}
@media screen and (max-width: 1120px) {
  .popup-menu.period {
    width: 100%;
  }
}
@media screen and (max-width: 1120px) {
  .popup-menu.period a {
    display: block;
    font-weight: 600;
  }
}
.popup-menu.profitloss {
  padding-right: 5px;
}
@media screen and (max-width: 1120px) {
  .popup-menu.profitloss {
    padding-right: 0;
  }
}
.popup-menu.profitloss .coins-wrapper {
  display: inline-flex;
  margin-left: 0.5rem;
  font-size: 0.75rem;
}
.popup-menu.profitloss .coins-wrapper .coins-label {
  margin-left: 0.1rem;
}
.popup-menu.invest li img {
  width: 25px;
}
.popup-menu.invest li.disabled {
  opacity: 0.5;
}
@media screen and (max-width: 1120px) {
  .popup-menu ul.m-show {
    z-index: 1050;
  }
}
@media screen and (min-width: 1120px) {
  .popup-menu:hover ul {
    display: block !important;
  }
}

@media screen and (max-width: 1120px) {
  .popup-menu-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    z-index: 1040;
  }
}

#error-screen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  position: absolute;
  padding: 10px;
}
#error-screen h2 {
  margin-bottom: 1rem;
  color: white;
}
#error-screen p {
  margin-bottom: 0;
  text-align: center;
}
#error-screen button {
  margin-top: 1rem;
}
#error-screen .version-container {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
#error-screen .version-container span {
  display: block;
  text-align: right;
}

.debug-popup-overlay {
  position: absolute;
  background-color: #18191d;
  display: none;
}

.debug-popup {
  position: absolute;
  top: 5px;
  left: 85px;
  z-index: 9999999;
}
.debug-popup .button {
  display: flex;
  flex-direction: column;
  padding: 6px 12px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #2a2b31;
  border-radius: 6px;
}
.debug-popup .button span {
  margin-left: 6px;
}
.debug-popup .button svg {
  width: 22px;
  height: 22px;
  fill: #CD0000;
}

@media (max-width: 1120px) {
  .debug-popup-overlay {
    top: 0;
    left: 49px;
    right: 49px;
    height: 39px;
    display: block;
  }
  .debug-popup {
    top: 5px;
    left: 49px;
    right: 49px;
    height: 39px;
  }
  .debug-popup .button {
    margin: 0 auto;
    width: 120px;
    height: 39px;
    flex-direction: row;
  }
}
@keyframes flashing-warning {
  0%, 49% {
    border-color: #2a2e31;
  }
  50%, 100% {
    border-color: #f5004d;
  }
}
.stat__holder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}
.stat__holder a {
  text-decoration: none;
}
.stat__holder a:hover {
  text-decoration: none;
}
@media screen and (max-width: 1119px) {
  .stat__holder {
    margin-left: auto;
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
  }
}
@media screen and (min-width: 600px) and (max-width: 1119px) {
  .stat__holder {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
  }
}
@media screen and (max-width: 1119px) {
  .stat__holder a {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    -moz-box-flex: 1;
    -moz-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }
}
@media screen and (min-width: 600px) and (max-width: 1119px) {
  .stat__holder a {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
}
@media screen and (max-width: 599px) {
  .stat__holder a {
    flex: 0 1 50%;
  }
}
.stat__item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0.5rem 0.8rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  border: 1.5px solid #2a2e31;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  border-radius: 6px;
  background: #1d2024;
  height: 60px;
}
@media screen and (max-width: 1119px) {
  .stat__item {
    padding: 2px 5px;
    max-height: 49px;
    height: unset;
  }
}
@media screen and (max-width: 404px) {
  .stat__item {
    padding: 2px 7px;
  }
}
.stat__item--with-dropdown {
  position: relative;
}
.stat__item--in-dropdown {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
}
.stat__item--in-dropdown:not(:last-child) {
  border-radius: 0;
  border-bottom: none;
}
.stat__item--warning {
  animation: flashing-warning 1s infinite;
}
.stat__item--warning .stat__item--in-dropdown {
  animation: flashing-warning 1s infinite;
}
.stat__item.balance {
  min-width: 130px;
}
@media screen and (max-width: 1119px) {
  .stat__item.balance {
    min-width: min-content;
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    -moz-box-flex: 1;
    -moz-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }
}
@media screen and (min-width: 600px) and (max-width: 1119px) {
  .stat__item.balance {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
  }
}
@media screen and (max-width: 599px) {
  .stat__item.balance {
    flex: 0 1 50%;
  }
}
.stat__item.balance .prepend img {
  max-width: 12px;
}
.stat__item.stars {
  margin-right: 10px;
}
.stat__item.coins {
  margin-left: 10px;
}
.stat__item .prepend {
  min-width: 10px;
  margin-right: 0.8rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}
@media screen and (max-width: 1119px) {
  .stat__item .prepend {
    margin-right: 5px;
    min-width: 18px;
  }
}
@media screen and (max-width: 330px) {
  .stat__item .prepend {
    display: none;
  }
}
@media screen and (max-width: 404px) {
  .stat__item .prepend img {
    width: 20px;
  }
}
.stat__item .labelDataWrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}
.stat__item .labelDataWrapper .labelTinyAppendWrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end;
}
.stat__item .labelDataWrapper .dataWrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.stat__item span.label {
  font-size: 0.6875rem;
  color: #A6A6A8;
  text-align: start;
}
.stat__item span.data {
  font-size: 1rem;
  font-weight: 700;
  color: white;
  text-align: start;
}
.stat__item .append {
  margin-left: 0.8rem;
}
.stat__dropdown-list {
  position: absolute;
  top: 95%;
  left: -1.5px;
  right: -1.5px;
  z-index: 1000;
  overflow: hidden;
  max-height: 0;
}

.side-drawer {
  position: fixed;
  z-index: 999999;
  top: 0;
  right: 0;
  bottom: 0;
  width: 400px;
  background: linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
  background: -o-linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
  background: -ms-linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
  background: -moz-linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
  background: -webkit-linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
  -webkit-transition: transform 0.4s ease-out;
  -moz-transition: transform 0.4s ease-out;
  -ms-transition: transform 0.4s ease-out;
  -o-transition: transform 0.4s ease-out;
  transition: transform 0.4s ease-out;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -o-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  will-change: transform;
}
@media screen and (max-width: 1119px) {
  .side-drawer {
    width: 100vw;
  }
}
.side-drawer.show {
  transform: none;
  -webkit-box-shadow: -30px -30px 50px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: -30px -30px 50px rgba(0, 0, 0, 0.5);
  box-shadow: -30px -30px 50px rgba(0, 0, 0, 0.5);
}
.side-drawer .drawer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem 0.5rem 1.2rem 2rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.side-drawer .drawer-header .button {
  cursor: pointer;
}
.side-drawer .drawer-header span.username {
  font-weight: light;
  opacity: 0.5;
}
.side-drawer .drawer-header button.close-side-drawer {
  height: 42px;
  width: 42px;
  background: #2a2e31;
  padding: 0;
  color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
}
.side-drawer .drawer-header a.messages {
  height: 42px;
  width: 42px;
  background: #2a2e31;
  border: 1px solid rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.side-drawer .drawer-header .drawer-user {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 42px;
}
.side-drawer .drawer-header .drawer-user .holder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  align-items: stretch;
  margin: 0 0 -20px 0;
}
.side-drawer .drawer-header .drawer-user .holder .avatar-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: -13px;
  margin-right: 0.5rem;
  height: 42px;
  width: 42px;
}
.side-drawer .drawer-header .drawer-user .holder .info {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 3px;
}
.side-drawer .drawer-header .drawer-user .holder .info .user {
  font-size: 1rem;
  color: #ffffff;
  margin: 0;
  padding: 0;
}
.side-drawer .drawer-header .drawer-user .holder .info .wallet-label {
  font-size: 0.75rem;
  color: #A6A6A8;
  padding: 0;
  margin: 0;
}
.side-drawer .drawer-header .drawer-user .holder .info .wallet-label--accent {
  color: #ffb200;
}
.side-drawer .content {
  position: relative;
  flex: 1;
  overflow: hidden;
}
.side-drawer .main-content-wrapper {
  height: 100%;
  overflow: auto;
}
.side-drawer .main-content-wrapper .refer-container {
  width: 100%;
  padding: 1rem 2rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: none;
  flex-wrap: nowrap;
}
.side-drawer .main-content-wrapper .refer-container .content-wrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 0;
  -moz-box-flex: 1;
  -moz-flex: 1 0 0;
  -ms-flex: 1 0 0;
  flex: 1 0 0;
}
.side-drawer .main-content-wrapper .refer-container span.link {
  width: 100%;
  font-size: 0.75rem;
  word-break: break-all;
}
.side-drawer .main-content-wrapper .refer-container .buttons {
  margin-top: 0.5rem;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
}
.side-drawer .main-content-wrapper .refer-container .buttons button {
  max-width: 50%;
  flex: 1;
  border: 1px solid #5F6265;
  border-radius: 5px;
  background: none;
  color: white;
  padding: 0.5rem 0;
  font-weight: 700;
}
.side-drawer .main-content-wrapper .refer-container .buttons button:first-child {
  margin-right: 1rem;
}
.side-drawer .main-content-wrapper .refer-container .buttons button:hover {
  background-color: rgba(24, 25, 29, 0.5);
}
.side-drawer .main-content-wrapper .refer-container .buttons button.copied {
  color: #70c157;
  border-color: #70c157;
}
.side-drawer .main-content-wrapper .refer-container .buttons button img {
  opacity: 1;
  margin-right: 0.5rem;
  height: 15px;
}
.side-drawer .main-content-wrapper .button {
  width: 100%;
  padding: 1.2rem 2rem;
  color: white;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  text-decoration: none;
  border: none;
  background-color: transparent;
}
.side-drawer .main-content-wrapper .button:hover {
  background-color: rgba(24, 25, 29, 0.5);
}
.side-drawer .main-content-wrapper .button:hover img {
  opacity: 1;
}
.side-drawer .main-content-wrapper .button.logout img {
  opacity: 1;
}
.side-drawer .main-content-wrapper .title {
  font-weight: 700;
}
.side-drawer .main-content-wrapper img {
  opacity: 0.25;
  margin-right: 1.5rem;
}
.side-drawer .wallet-picker-container {
  height: 100%;
}
.side-drawer .footer {
  margin-top: auto;
  padding: 0.5rem 1rem;
}
.side-drawer--overlay {
  display: none;
}
.side-drawer--overlay.show {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
}

.instruments-drawer-overlay {
  display: none;
}
.instruments-drawer-overlay.show {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
}

.instruments-drawer {
  position: absolute;
  z-index: 999999;
  top: 0;
  left: 0;
  bottom: 0;
  width: 280px;
  background-color: #2a2e31;
  -webkit-transition: transform 0.4s ease-out;
  -moz-transition: transform 0.4s ease-out;
  -ms-transition: transform 0.4s ease-out;
  -o-transition: transform 0.4s ease-out;
  transition: transform 0.4s ease-out;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  will-change: transform;
}
.instruments-drawer.show {
  transform: none;
  -webkit-box-shadow: 30px -30px 50px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 30px -30px 50px rgba(0, 0, 0, 0.5);
  box-shadow: 30px -30px 50px rgba(0, 0, 0, 0.5);
}
.instruments-drawer button.close-drawer {
  opacity: 1;
  background: none;
  padding: 0;
  color: transparent;
  border: none;
  outline: none;
  margin-left: auto;
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 2;
  height: 42px;
  width: 42px;
}

@media (max-width: 280px) {
  .instruments-drawer {
    width: 100vw;
    transform: translateX(-100%);
  }
}
body.alert-shown {
  overflow: hidden;
}

#popup-alert-wrapper .popup-alert {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1070;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}
#popup-alert-wrapper .popup-alert .alert-wrapper {
  max-width: 600px;
}
#popup-alert-wrapper .popup-alert .button-wrapper {
  display: flex;
}
#popup-alert-wrapper .popup-alert .button-wrapper button:not(:first-child) {
  margin-left: 1rem;
}
#popup-alert-wrapper .popup-alert.enter {
  opacity: 0;
}
#popup-alert-wrapper .popup-alert.enter-active {
  opacity: 1;
  transition: opacity 600ms ease;
}
#popup-alert-wrapper .popup-alert.enter-done {
  opacity: 1;
}
#popup-alert-wrapper .popup-alert.enter-done .alert-wrapper {
  opacity: 1;
}
#popup-alert-wrapper .popup-alert.exit-active {
  opacity: 0;
  transition: opacity 600ms ease;
}
#popup-alert-wrapper .popup-alert.exit-active .alert-wrapper {
  opacity: 0;
}
#popup-alert-wrapper .popup-alert .alert-wrapper {
  opacity: 0;
  transition: opacity 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#popup-alert-wrapper .popup-alert button.close {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem;
  font-size: 2rem;
  background: transparent;
  border: none;
  outline: none;
}
#popup-alert-wrapper .popup-alert .icon-container.success, #popup-alert-wrapper .popup-alert .icon-container.error {
  border-radius: 100%;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5), 0px 0px 40px rgba(0, 0, 0, 0.5);
}
#popup-alert-wrapper .popup-alert .icon-container.success {
  background: linear-gradient(157.57deg, #70C157 8.66%, #55D636 91.51%), #55D636;
}
#popup-alert-wrapper .popup-alert .icon-container.error {
  background: linear-gradient(103.09deg, #CD0000 21.7%, #BB173E 59.43%);
}
#popup-alert-wrapper .popup-alert .title {
  font-size: 1.875rem;
  text-align: center;
  margin: 2rem 0;
}
#popup-alert-wrapper .popup-alert .subtitle {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.125rem;
}
#popup-alert-wrapper .popup-alert button {
  padding: 0.5rem 1.5rem;
  color: white;
  font-weight: 700;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  outline: none;
}
#popup-alert-wrapper .popup-alert button.success {
  background: linear-gradient(128.52deg, #70C157 21.7%, #55D636 59.43%);
}
#popup-alert-wrapper .popup-alert button.error {
  background: linear-gradient(103.09deg, #CD0000 21.7%, #BB173E 59.43%);
}

.page-container {
  background-color: #18191d;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* Switch button */
}
.page-container .title-bar {
  display: flex;
  justify-content: space-between;
}
.page-container .title-bar .back-button-wrapper {
  flex: 1;
}
.page-container .title-bar .title-subtitle-wrapper {
  flex: 1;
}
.page-container .title-bar .title-append-wrapper {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 1119px) {
  .page-container--with-mobile-action-item .content-wrapper {
    padding-bottom: 100px;
  }
}
.page-container--embedded-cashier > .container {
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.page-container > .container {
  padding-right: 30px;
  padding-left: 30px;
  max-width: 100%;
}
@media screen and (max-width: 1119px) {
  .page-container > .container {
    max-width: 100%;
    padding-right: 10px;
    padding-left: 10px;
  }
}
.page-container .mobile-action-item {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #18191d;
  padding: 20px 30px 30px;
}
.page-container .mobile-action-item:before {
  position: absolute;
  content: "";
  height: 50px;
  top: -50px;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(24, 25, 29, 0) 0%, #18191D 100%);
}
.page-container .content-wrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.page-container .loader-container {
  min-height: 400px;
}
.page-container .row.spacing {
  padding-top: 30px;
}
.page-container .vr {
  border-left: 1px solid #747474;
  width: 1px;
  margin: 0 auto;
  height: 100%;
}
.page-container .btn-default.btn-on.active {
  background-color: #12aaeb;
  color: #FFF;
}
.page-container .btn-default.btn-off.active {
  background-color: #f60057;
  color: #FFF;
}
.page-container .btn-off,
.page-container .btn-on {
  background-color: #707070;
  padding-top: 16px;
  padding-bottom: 16px;
}
.page-container .tabs-left a {
  color: #FFF;
  text-decoration: none;
  font-size: 1.125rem;
  font-weight: 200;
}
.page-container .tabs-left a i {
  float: right;
  margin-top: 7px;
}
.page-container .tabs-left a li {
  width: 100%;
  border-top: 1px solid #747474;
  opacity: 0.5;
  padding-bottom: 20px;
  display: inline-block;
}
.page-container .tabs-left a.active li {
  opacity: 1;
}
.page-container .tabs-left a.active li i {
  background-color: #FFF;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  color: #000;
}
.page-container .tab-content {
  font-size: 1.125rem;
  font-weight: 200;
}
.page-container .nav-tabs {
  border: none;
  display: block;
}
.page-container.trading-strategy .container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}
.page-container.trading-strategies .container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

@media screen and (max-width: 1119px) {
  .settings {
    background-color: #1c2022;
  }
}
.settings h2 {
  text-align: center;
}
.settings__holder {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
@media screen and (min-width: 1120px) {
  .settings__holder {
    min-height: 360px;
    max-width: 840px;
    margin: 0 auto;
  }
}
.settings__controls {
  width: 100%;
  background: #1d2024;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}
@media screen and (min-width: 1120px) {
  .settings__controls {
    padding: 40px;
    border: 1px solid #2a2e31;
  }
}
.settings__controls .holder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  margin: 0 0 10px 0;
}
@media screen and (min-width: 767px) {
  .settings__controls .holder {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    margin: 0 0 20px 0;
  }
}
@media screen and (max-width: 766px) {
  .settings__controls .holder {
    display: inline-block;
  }
}
.settings__controls .holder .options__box {
  flex: 1;
  background: rgba(63, 64, 70, 0.2);
  border: 1px solid #2a2e31;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 15px;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.settings__controls .holder .options__box__button {
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
@media screen and (min-width: 1119px) {
  .settings__controls .holder .options__box.width-md-30 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
    flex-shrink: 1;
  }
}
@media screen and (min-width: 1119px) {
  .settings__controls .holder .options__box.width-md-50 {
    flex: 0 0 50%;
    max-width: 50%;
    flex-shrink: 1;
  }
}
.settings__controls .holder .options__box .title {
  color: #ffffff;
  font-weight: 700;
  font-size: 0.875rem;
  margin-bottom: 15px;
}
.settings__controls .holder .options__box .split {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}
.settings__controls .holder .options__box .dropDown {
  position: relative;
}
.settings__controls .holder .options__box .dropDown .dropupContainer {
  width: 100%;
}
.settings__controls .holder .options__box .dropDown .dropupContainer .btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  color: #FFF;
  background-color: #404040;
  padding: 7px 20px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 700;
  background: linear-gradient(134.22deg, #565a5c -63.52%, #2a2e31 74.66%);
  border: 1px solid rgba(255, 255, 255, 0.1);
  min-height: 48px;
  max-height: 48px;
}
@media screen and (max-width: 1119px) {
  .settings__controls .holder .options__box .dropDown .dropupContainer .btn {
    width: 100%;
    background-color: #2d3135;
    text-align: left;
  }
}
@media screen and (min-width: 1120px) {
  .settings__controls .holder .options__box .dropDown .dropupContainer .btn {
    width: 100%;
  }
}
.settings__controls .holder .options__box .dropDown .dropupContainer .btn .arrow-down {
  display: none;
}
.settings__controls .holder .options__box .dropDown .dropupContainer .btn:focus {
  outline: 0;
  box-shadow: none;
}
.settings__controls .holder .options__box .dropDown .dropupContainer .btn:after {
  position: absolute;
  right: 20px;
}
.settings__controls .holder .options__box .dropDown .dropupContainer .dropdown-menu {
  max-height: 300px;
  height: min-content;
  overflow-y: scroll;
  background-color: #2a2e31;
  font-size: 1.125rem;
  width: 100%;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
.settings__controls .holder .options__box .dropDown .dropupContainer .dropdown-menu a.dropdown-item {
  color: #FFF;
  padding: 15px 0 15px 10px;
  font-size: 0.875rem;
  background: #2a2e31;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.settings__controls .holder .options__box .dropDown .dropupContainer .dropdown-menu a.dropdown-item img {
  margin-right: 20px;
}
.settings__controls .holder .options__box .dropDown .dropupContainer .dropdown-menu a.dropdown-item i {
  float: right;
  padding-right: 5px;
  font-size: 1.75rem;
  padding-top: 3px;
}
.settings__controls .holder .options__box .dropDown .dropupContainer .dropdown-menu a.dropdown-item:hover, .settings__controls .holder .options__box .dropDown .dropupContainer .dropdown-menu a.dropdown-item:focus {
  background-color: #1d2024;
}
.settings__controls .holder--fullwidth {
  background: rgba(63, 64, 70, 0.2);
  border: 1px solid #2a2e31;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
}
.settings__controls .holder--fullwidth .options__box {
  background: transparent;
  border: none;
}
.settings__user-profile {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background: rgba(63, 64, 70, 0.2);
  border: 1px solid #2a2e31;
  box-sizing: border-box;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  padding: 15px;
}
@media screen and (max-width: 1119px) {
  .settings__user-profile {
    margin: 0 0 20px 0;
  }
}
.settings__user-profile .title {
  color: #ffffff;
  font-weight: 700;
  font-size: 0.875rem;
  margin-bottom: 15px;
}
.settings .container {
  overflow: auto;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.settings .container .row.spacing {
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.settings .container .anchorDesc {
  font-size: 1.125rem;
}
.settings .container .version-container {
  margin: 1rem 0;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 4rem;
  -moz-box-flex: 1;
  -moz-flex: 1 0 4rem;
  -ms-flex: 1 0 4rem;
  flex: 1 0 4rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end;
}
.settings .container .version-container span {
  text-align: right;
  display: block;
  opacity: 0.6;
}
.settings .dropDown .btn {
  color: #FFF;
  background-color: #404040;
  padding: 20px 20px;
  border-radius: 5px;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 200;
}
@media screen and (max-width: 1119px) {
  .settings .dropDown .btn {
    width: 100%;
    background-color: #2d3135;
    padding: 20px 15px;
    font-size: 1rem;
    text-align: left;
  }
}
.settings .dropDown .dropdown-menu {
  max-height: 300px;
  height: min-content;
  overflow-y: scroll;
  background-color: #393939;
  font-size: 1.125rem;
  width: 100%;
}
@media screen and (max-width: 540px) {
  .settings .dropDown .dropdown-menu {
    width: calc(100vw - 20px);
  }
}
.settings .dropDown .dropdown-menu.soundstyle {
  height: 140px;
}
.settings .dropDown .dropdown-menu a.dropdown-item {
  color: #FFF;
  padding: 15px 0 15px 10px;
}
.settings .dropDown .dropdown-menu a.dropdown-item i {
  float: right;
  padding-right: 5px;
  font-size: 1.875rem;
  padding-top: 3px;
}
.settings .dropDown .dropdown-menu a.dropdown-item:hover, .settings .dropDown .dropdown-menu a.dropdown-item:focus {
  background-color: #1d2024;
}
.settings .dropupContainer button span {
  color: #ffffff;
}
@media screen and (max-width: 1120px) {
  .settings .dropupContainer button span {
    font-size: 1rem;
  }
}
@media screen and (max-width: 600px) {
  .settings .dropupContainer button span {
    font-size: 0.875rem;
  }
}
.settings .tutorial-button-holder {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
@media screen and (min-width: 1120px) {
  .settings .tutorial-button-holder {
    margin-top: 1rem;
    margin-bottom: 0;
  }
}
.settings .powered-by {
  margin-top: 1rem;
  text-align: center;
  font-size: 0.875rem;
}
@media screen and (max-width: 1119px) {
  .settings .powered-by {
    margin-top: 0;
  }
}
.settings .powered-by__label {
  color: rgba(255, 255, 255, 0.5);
}
.settings .powered-by__link {
  font-weight: bold;
  color: white;
  line-height: 1;
}
.settings .powered-by__link:hover {
  text-decoration: none;
  color: #12aaeb;
}

.page-container.messages .container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex: 1;
}
.page-container.messages .no-messages-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-container.messages .content-wrapper {
  min-height: 0;
}
.page-container.messages .messages-wrapper {
  width: 100%;
  overflow-y: auto;
  margin: 1rem auto 0;
  max-width: 840px;
}
@media screen and (max-width: 1119px) {
  .page-container.messages .messages-wrapper {
    flex-direction: column-reverse;
    margin: 1rem 0 0;
    max-width: 100%;
  }
}
.page-container.messages .infinite-scroll-component {
  overflow: hidden !important;
}

.page-container.message-inner .container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex: 1;
}
.page-container.message-inner .content-wrapper {
  min-height: 0;
  width: 100%;
  max-width: 840px;
  margin: 0 auto 150px;
}
@media screen and (max-width: 1119px) {
  .page-container.message-inner .content-wrapper {
    margin-bottom: 15px;
  }
}
.page-container.message-inner .message-wrapper {
  margin-top: 35px;
  background: #1d2024;
  border: 1px solid #2a2e31;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 1119px) {
  .page-container.message-inner .message-wrapper {
    margin: 0;
  }
}
.page-container.message-inner .message-wrapper__content {
  overflow-y: auto;
  flex: 1;
  padding: 50px;
}
@media screen and (max-width: 1119px) {
  .page-container.message-inner .message-wrapper__content {
    padding: 20px;
  }
}
.page-container.message-inner .message-wrapper__date {
  display: block;
  color: #646d7b;
  font-size: 0.75rem;
  margin-bottom: 1rem;
}
.page-container.message-inner .message-wrapper__title {
  font-weight: 700;
  font-size: 1rem;
  flex: 1;
  min-width: 0;
  margin-bottom: 1rem;
}
.page-container.message-inner .message-wrapper__message {
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: regular;
  color: #A6A6A8;
  white-space: pre-line;
}
.page-container.message-inner .message-wrapper__actions {
  background: linear-gradient(134.22deg, #2F3235 -63.52%, #1F2225 74.66%), #2a2e31;
  padding: 16px;
}
.page-container.message-inner .message-wrapper__actions .message-action {
  margin-right: 10px;
}
@media screen and (max-width: 1119px) {
  .page-container.message-inner .message-wrapper__actions {
    display: flex;
    justify-content: stretch;
    align-items: center;
  }
  .page-container.message-inner .message-wrapper__actions .message-action {
    display: block;
    text-align: center;
    flex: 1;
  }
  .page-container.message-inner .message-wrapper__actions .message-action.learn-more {
    flex: 2;
  }
  .page-container.message-inner .message-wrapper__actions .message-action .button {
    width: 100%;
  }
}
.page-container.message-inner--with-actions .message-wrapper__content {
  padding-bottom: 25px;
}

.page-container.withdraw .container, .page-container.deposit .container {
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.page-container.withdraw .container iframe, .page-container.deposit .container iframe {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.ReactModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ReactModal__Content {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  outline: none;
  padding: 50px 20px;
  max-width: 600px;
  width: calc(100% - 60px);
  border-radius: 20px;
  color: #ffffff;
}
.ReactModal__Content--light {
  color: #18191d;
}
@media screen and (max-width: 767px) {
  .ReactModal__Content {
    border-radius: 5px;
  }
}
.ReactModal__Content h2.title {
  line-height: 40px;
  text-align: center;
}
.ReactModal__Content p.description {
  opacity: 0.6;
  font-size: 1rem;
  line-height: 1.5;
}

.modal {
  text-align: center;
  padding: 0 !important;
  position: absolute;
}
.modal:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-right: -4px; /* Adjusts for spacing */
}
.modal .close {
  font-size: 3.5rem;
  font-weight: 100;
  color: #ffffff;
  -webkit-text-shadow: 0 1px 0 #ffffff;
  -moz-text-shadow: 0 1px 0 #ffffff;
  text-shadow: 0 1px 0 #ffffff;
  position: absolute;
  top: 5%;
  right: 5%;
  z-index: 2;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  -ms-transition: opacity 0.25s ease;
  -o-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}
@media screen and (min-width: 1120px) {
  .modal .close {
    top: 10%;
    right: 10%;
  }
}
.modal .close:hover {
  color: #ffffff;
}
.modal .modal-dialog {
  max-width: 800px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%) !important;
  -moz-transform: translate(-50%, -50%) !important;
  -o-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
}
@media screen and (max-width: 1120px) {
  .modal .modal-dialog {
    width: 100%;
  }
}
.modal .modal-dialog .modal-content {
  background-color: transparent;
  color: #FFF;
  padding: 25px;
  font-size: 1.5rem;
  font-weight: 200;
  text-align: center;
  border: 0;
}
@media screen and (max-width: 1119px) {
  .modal .modal-dialog .modal-content {
    text-align: center;
    font-size: 1.125rem;
  }
}
@media screen and (max-width: 540px) {
  .modal .modal-dialog .modal-content {
    font-size: 0.875rem;
  }
}
.modal .modal-dialog .modal-content .title {
  font-size: 1.875rem;
  color: #ffffff;
  max-width: 340px;
  line-height: 40px;
  font-weight: 700;
  text-align: center;
  margin: 60px auto 30px auto;
}
@media screen and (min-width: 1120px) {
  .modal .modal-dialog .modal-content .title {
    margin: 60px auto 30px auto;
  }
}
.modal .modal-dialog .modal-content .modal_btns {
  margin-top: 30px;
}
.modal .modal-dialog .modal-content .modal_btns .holder {
  margin: 0 auto;
  max-width: 306px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around;
}
@media screen and (min-width: 1120px) {
  .modal .modal-dialog .modal-content .modal_btns .holder {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
  }
}
.modal .modal-dialog .modal-content .modal_btns .holder .button {
  min-width: 120px;
}
@media screen and (min-width: 767px) {
  .modal .modal-dialog .modal-content .modal_btns .holder .button {
    min-width: 143px;
  }
}
.modal .modal-dialog .modal-content iframe {
  max-width: 100%;
}
.modal .modal-dialog .modal-content .video-container {
  max-width: 100%;
  width: 800px;
}
.modal .modal-dialog .modal-content .video-container video {
  max-width: 100vw;
}
.modal .modal-dialog .modal-content .video-placeholder {
  max-width: 100%;
  width: 800px;
  padding-top: 56.25%;
}
.modal--competition .modal-dialog {
  padding: 0;
  margin: 0;
}
@media screen and (min-width: 350px) {
  .modal--competition .modal-dialog {
    width: 350px;
  }
}
.modal--competition .modal-dialog .close {
  position: absolute;
  top: 20px;
  right: 20px;
  text-shadow: none;
  opacity: 1;
  color: #fff;
  font-size: 2.625rem;
  font-weight: 100;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
@media screen and (min-width: 1120px) {
  .modal--competition .modal-dialog .close {
    top: 5px;
    right: 10px;
  }
}
.modal--competition .modal-dialog .close:hover {
  cursor: pointer;
  font-weight: 200;
}
.modal--competition .modal-dialog .modal-content {
  color: #FFF;
  text-align: center;
  background: linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
  background: -o-linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
  background: -ms-linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
  background: -moz-linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
  background: -webkit-linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  padding: 50px 40px 40px 40px;
}
.modal--competition .modal-dialog .modal-content .title {
  font-size: 1.125rem;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
  margin: 0px auto 30px auto;
}
@media screen and (min-width: 340px) {
  .modal--competition .modal-dialog .modal-content .title {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 1120px) {
  .modal--competition .modal-dialog .modal-content .title {
    margin: 0px auto 30px auto;
  }
}
.modal--competition .modal-dialog .modal-content .illustration {
  width: auto;
  margin: auto;
}
@media screen and (min-width: 340px) {
  .modal--competition .modal-dialog .modal-content .illustration {
    width: 210px;
  }
}
.modal--competition .modal-dialog .modal-content .illustration .small-labels {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  margin-top: -1.2em;
  font-weight: 700;
  color: white;
  font-size: 80%;
}
.modal--competition .modal-dialog .modal-content .illustration .small-labels span {
  width: 31.25%;
}
.modal--competition .modal-dialog .modal-content .illustration .small-labels span.hidden {
  visibility: hidden;
}
.modal--competition .modal-dialog .modal-content .illustration span.big-label {
  display: block;
  text-align: center;
  font-weight: 700;
  color: white;
  margin-top: -0.3em;
}
.modal--competition .modal-dialog .modal-content .notice {
  max-width: 270px;
  margin: 20px auto 0 auto;
}
.modal--competition .modal-dialog .modal-content .notice p {
  color: #ffffff;
  opacity: 0.6;
  font-size: 0.875rem;
  line-height: 21px;
}
.modal--competition .modal-dialog .modal-content .modal_btns {
  margin-top: 10px;
}
@media screen and (min-width: 340px) {
  .modal--competition .modal-dialog .modal-content .modal_btns {
    margin-top: 30px;
  }
}
.modal--competition .modal-dialog .modal-content .modal_btns .holder {
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}
.modal--competition .modal-dialog .modal-content .modal_btns .holder .button {
  min-width: 120px;
}
@media screen and (min-width: 767px) {
  .modal--competition .modal-dialog .modal-content .modal_btns .holder .button {
    min-width: 134px;
  }
}
.modal--low_margin .modal-dialog, .modal--stop_out .modal-dialog {
  padding: 0;
  margin: 0;
}
@media screen and (min-width: 350px) {
  .modal--low_margin .modal-dialog, .modal--stop_out .modal-dialog {
    width: 350px;
  }
}
@media screen and (min-width: 991px) {
  .modal--low_margin .modal-dialog, .modal--stop_out .modal-dialog {
    width: 900px;
    height: 680px;
  }
}
.modal--low_margin .modal-dialog .close, .modal--stop_out .modal-dialog .close {
  position: absolute;
  top: 20px;
  right: 20px;
  text-shadow: none;
  opacity: 1;
  color: #18191d;
  font-size: 2.625rem;
  font-weight: 100;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
@media screen and (min-width: 1120px) {
  .modal--low_margin .modal-dialog .close, .modal--stop_out .modal-dialog .close {
    top: 5px;
    right: 10px;
  }
}
.modal--low_margin .modal-dialog .close:hover, .modal--stop_out .modal-dialog .close:hover {
  cursor: pointer;
  font-weight: 200;
}
.modal--low_margin .modal-dialog .modal-content, .modal--stop_out .modal-dialog .modal-content {
  color: #18191d;
  text-align: center;
  background: #fff;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  padding: 50px 40px 40px 40px;
}
.modal--low_margin .modal-dialog .modal-content .title, .modal--stop_out .modal-dialog .modal-content .title {
  font-size: 1.125rem;
  color: #18191d;
  font-weight: 700;
  line-height: 31px;
  text-align: center;
  margin: 0px auto;
  max-width: 400px;
}
@media screen and (min-width: 340px) {
  .modal--low_margin .modal-dialog .modal-content .title, .modal--stop_out .modal-dialog .modal-content .title {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 990px) {
  .modal--low_margin .modal-dialog .modal-content .title, .modal--stop_out .modal-dialog .modal-content .title {
    max-width: 180px;
  }
}
.modal--low_margin .modal-dialog .modal-content .notice, .modal--stop_out .modal-dialog .modal-content .notice {
  margin: 30px auto;
  max-width: 270px;
}
@media screen and (min-width: 991px) {
  .modal--low_margin .modal-dialog .modal-content .notice, .modal--stop_out .modal-dialog .modal-content .notice {
    max-width: 630px;
  }
}
.modal--low_margin .modal-dialog .modal-content .notice p, .modal--stop_out .modal-dialog .modal-content .notice p {
  color: #18191d;
  opacity: 0.5;
  font-size: 0.875rem;
  line-height: 21px;
  margin: 0;
}
@media screen and (min-width: 991px) {
  .modal--low_margin .modal-dialog .modal-content .notice p, .modal--stop_out .modal-dialog .modal-content .notice p {
    max-width: 630px;
    font-size: 1.125rem;
    line-height: 30px;
    opacity: 1;
  }
}
.modal--low_margin .modal-dialog .modal-content .videoholder, .modal--stop_out .modal-dialog .modal-content .videoholder {
  position: relative;
  margin-bottom: 20px;
}
.modal--low_margin .modal-dialog .modal-content .videoholder video, .modal--stop_out .modal-dialog .modal-content .videoholder video {
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.25);
  max-width: 100%;
  border-radius: 5px;
  overflow: hidden;
}
.modal--low_margin .modal-dialog .modal-content .modal_btns, .modal--stop_out .modal-dialog .modal-content .modal_btns {
  margin-top: 10px;
}
@media screen and (min-width: 340px) {
  .modal--low_margin .modal-dialog .modal-content .modal_btns, .modal--stop_out .modal-dialog .modal-content .modal_btns {
    margin-top: 30px;
  }
}
.modal--low_margin .modal-dialog .modal-content .modal_btns .holder, .modal--stop_out .modal-dialog .modal-content .modal_btns .holder {
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}
.modal--low_margin .modal-dialog .modal-content .modal_btns .holder .button, .modal--stop_out .modal-dialog .modal-content .modal_btns .holder .button {
  min-width: 120px;
}
@media screen and (min-width: 767px) {
  .modal--low_margin .modal-dialog .modal-content .modal_btns .holder .button, .modal--stop_out .modal-dialog .modal-content .modal_btns .holder .button {
    min-width: 134px;
  }
}

#refer_modal #referralURL {
  position: absolute;
  top: -99999px;
}

.loader-container {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999;
}
.loader-container .loader-background {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  opacity: 0.75;
}
.loader-container .loader-background--opaque {
  background-color: #18191d;
  opacity: 1;
}
.loader-container .loader-background--transparent {
  background-color: transparent;
  opacity: 1;
}
.loader-container .loader-background--semi-transparent {
  background-color: #18191d;
  opacity: 0.8;
}

.inline-loader {
  height: 86px;
  width: 100%;
  position: relative;
}

.lds-ring {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
html, html * {
  -ms-content-zooming: none;
  -ms-touch-action: pan-x pan-y;
  -webkit-content-zooming: none;
  content-zooming: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

body {
  color: #FFF;
  background: #18191d;
}
body .react-grid-item a:focus {
  outline: transparent;
}
body.ios {
  /* CSS specific to iOS devices */
}
@media screen and (max-width: 1119px) {
  body.ios {
    margin-top: env(safe-area-inset-top) !important;
    margin-top: constant(safe-area-inset-top);
  }
}
@media screen and (max-width: 1119px) {
  body.ios .language_switch {
    margin-top: env(safe-area-inset-top) !important;
    margin-top: constant(safe-area-inset-top);
  }
}
body.ios .login {
  padding-top: 100px;
}
body.android {
  /* CSS for android */
}
@media screen and (max-width: 1119px) {
  body.android {
    padding-top: env(safe-area-inset-top) !important;
    padding-top: constant(safe-area-inset-top);
  }
}
@media screen and (max-width: 1119px) {
  body.android .instruments-drawer, body.android .close-drawer, body.android .side-drawer {
    padding-top: env(safe-area-inset-top) !important;
    padding-top: constant(safe-area-inset-top);
  }
}
body.modal-open, body.ReactModal__Body--open {
  overflow: hidden;
}
body.modal-open #app #main #mainContainer, body.modal-open #app #main .sidemenu, body.ReactModal__Body--open #app #main #mainContainer, body.ReactModal__Body--open #app #main .sidemenu {
  filter: blur(10px);
}
body.modal-open #app #main .login, body.ReactModal__Body--open #app #main .login {
  filter: blur(10px);
}

@media screen and (max-width: 1119px) {
  html, body {
    overflow: hidden;
    position: relative;
  }
}

html, body, #app, #main {
  height: 100%;
  background-color: #18191d;
  width: 100%;
  max-width: 100%;
}

.container-fluid {
  height: 100%;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (max-width: 1119px) {
  .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }
}

ul {
  -webkit-padding-start: 0;
  -moz-padding-start: 0;
}

.link.link--unstyled {
  color: unset;
}
.link.link--unstyled:hover {
  color: unset;
  text-decoration: none;
}

button {
  border: 0;
  padding: 0;
  outline: none;
  background: transparent;
}
button:focus {
  outline: none;
}

#main {
  background-color: #18191d;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  color: #FFFFFF;
  font-weight: 200;
}
@media screen and (max-width: 600px) {
  #main #sidebarRight {
    z-index: 4 !important;
  }
}
@media screen and (max-width: 1119px) {
  #main #sidebarRight .extra {
    margin-top: 5px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;
    line-height: 0;
  }
}
@media screen and (max-width: 1119px) {
  #main #sidebarRight .extra .material-icons {
    background: #2a2e31;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    margin-left: 5px;
    padding: 7.5px;
  }
}
#main .row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
#main #mainContainer {
  padding: 0 30px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 1119px) {
  #main #mainContainer {
    padding: 10px;
    width: 100%;
    max-width: 100%;
  }
}
#main #mainContainer > .container-fluid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}
@media screen and (max-width: 1119px) {
  #main #mainContainer.leaderboard {
    height: 100%;
  }
}
@media screen and (max-height: 567px) {
  #main #mainContainer.trading-screen {
    overflow-y: auto;
    overflow-x: hidden;
  }
}
@media screen and (max-width: 1119px) {
  #main #mainContainer.trading-screen #mainContent {
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: visible;
  }
}
@media screen and (max-width: 1119px) {
  #main #mainContainer.trading-screen #mainContent .positions-list {
    position: relative;
    overflow: visible;
  }
}
@media screen and (max-width: 1119px) {
  #main #mainContainer.trading-screen #mainContent .positions-list .vertical-bar-blue {
    display: flex !important;
  }
}
#main #mainContainer #mainContent {
  display: flex;
  flex: 3;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  min-height: 0;
}
@media screen and (max-width: 1119px) {
  #main #mainContainer #mainContent {
    width: 100%;
    max-width: 100%;
    margin: auto;
    padding: 0;
    display: flex;
    overflow: visible;
  }
}
#main #mainContainer #mainContent .chart-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 0;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  width: 100%;
}
@media screen and (max-width: 1119px) {
  #main #mainContainer #mainContent .chart-wrapper {
    order: 2;
    flex: 1;
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
}
@media screen and (min-width: 600px) and (max-width: 1119px) {
  #main #mainContainer #mainContent .chart-wrapper {
    height: calc(100% - 148px);
  }
}
@media screen and (min-width: 1920px) {
  #main #mainContainer #mainContent .chart-wrapper {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 58.33333%;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
}
@media screen and (min-width: 1120px) {
  #main #mainContainer #mainContent .chart-wrapper {
    width: 50%;
  }
}
#main #mainContainer #mainContent .chart-wrapper .controls {
  position: absolute;
  top: 10px;
  left: 10px;
}
@media screen and (min-width: 1119px) {
  #main #mainContainer #mainContent .width-md-50 {
    flex: 0 0 50%;
    max-width: 50%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media screen and (min-width: 1120px) {
  #main #mainContainer #mainContent .width-lg-50 {
    flex: 0 0 50%;
    max-width: 50%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media screen and (min-width: 1120px) {
  #main #mainContainer #mainContent .width-lg-100 {
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media screen and (max-width: 1119px) {
  #main #mainContainer #mainContent .positions-container {
    max-width: 100% !important;
    flex: unset !important;
  }
}
@media screen and (max-width: 1119px) {
  #main #mainContainer #mainContent .positions-container > .row {
    margin-left: -10px;
  }
}
@media screen and (min-width: 1920px) {
  #main #mainContainer #mainContent .positions-container {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 41.66667%;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
}
#main #mainContainer #mainContent .positions-container > div {
  position: absolute;
  width: 100%;
}
@media screen and (max-width: 1119px) {
  #main #mainContainer #mainContent .positions-container > div {
    position: relative;
    width: auto;
  }
}

.trophy-animation {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  z-index: 9999999;
}

.market-closed-notice {
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}
@media screen and (max-width: 1119px) {
  .market-closed-notice {
    background: #1d2024; /* The Fallback */
    background: rgba(29, 32, 36, 0.7);
  }
}
.market-closed-notice__wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  width: 100%;
}
.market-closed-notice__image {
  z-index: 1;
  max-width: 100%;
  height: auto;
}
@media screen and (max-height: 800px) {
  .market-closed-notice__image {
    width: 50%;
    max-width: 280px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1119px) {
  .market-closed-notice__image {
    max-width: 20%;
  }
}
@media screen and (max-height: 800px) {
  .market-closed-notice__image {
    display: none;
  }
}
@media only screen and (max-width: 767px) and (orientation: landscape) {
  .market-closed-notice__image {
    max-width: 200px;
  }
}
.market-closed-notice__title {
  font-size: 1.5rem;
  color: #CD0000;
  text-transform: uppercase;
  font-weight: 700;
  margin: 1rem 0 0 0;
  z-index: 1;
}
@media screen and (min-width: 767px) {
  .market-closed-notice__title {
    font-size: 1.875rem;
  }
}
@media screen and (min-width: 1120px) {
  .market-closed-notice__title {
    font-size: 2.625rem;
  }
}
@media screen and (max-height: 640px) {
  .market-closed-notice__title {
    margin: 0;
  }
}
.market-closed-notice__subtitle {
  font-size: 0.75rem;
  text-transform: uppercase;
  margin-top: 10px;
  z-index: 1;
  color: #fff;
}
@media screen and (min-width: 767px) {
  .market-closed-notice__subtitle {
    font-size: 1.125rem;
  }
}
.market-closed-notice__info {
  font-size: 1rem;
  width: 60%;
  text-align: center;
  color: #646d7b;
}
.market-closed-notice__shadow {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}
.market-closed-notice__shadow img {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.no-instruments-found {
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}
@media screen and (max-width: 1119px) {
  .no-instruments-found {
    background: #1d2024; /* The Fallback */
    background: rgba(29, 32, 36, 0.7);
  }
}
.no-instruments-found__wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  width: 100%;
}
.no-instruments-found__image {
  z-index: 1;
  max-width: 100%;
  height: auto;
}
@media screen and (max-height: 800px) {
  .no-instruments-found__image {
    width: 50%;
    max-width: 280px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1119px) {
  .no-instruments-found__image {
    max-width: 20%;
  }
}
@media screen and (max-height: 800px) {
  .no-instruments-found__image {
    display: none;
  }
}
@media only screen and (max-width: 767px) and (orientation: landscape) {
  .no-instruments-found__image {
    max-width: 200px;
  }
}
.no-instruments-found__info {
  font-size: 1rem;
  width: 60%;
  text-align: center;
  color: #646d7b;
}
.no-instruments-found__white-title {
  font-size: 1.5rem;
  color: #ffffff;
}

.pill {
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  padding: 0.1rem 0.3rem;
  margin: 0 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pill.blue {
  background: linear-gradient(108.6deg, #3BA4E8 0%, #0A579E 62.59%);
}
.pill span {
  font-weight: 700;
  color: white;
}

.loading-dots {
  text-align: center;
  width: 100%;
}
.loading-dots--dot {
  animation: dot-keyframes 1.5s infinite ease-in-out;
  background-color: white;
  border-radius: 10px;
  display: inline-block;
  height: 8px;
  width: 8px;
  margin: 0 2px;
}
.loading-dots--dot:nth-child(2) {
  animation-delay: 0.5s;
}
.loading-dots--dot:nth-child(3) {
  animation-delay: 1s;
}

/* ==========================================================================
     HELPER CLASSES NOT DEPENDENT ON ANY SPECIFIC COMPONENT / MODULE
     ========================================================================== */
.content-box {
  background-color: transparent;
  height: 100%;
  width: 100%;
}
@media screen and (min-width: 1120px) {
  .content-box {
    background-color: #1d2024;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;
    border: 1.5px solid #2A2E31;
  }
}

.vert-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}

.centerContent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-flex-flow: column;
  -moz-flex-flow: column;
  -ms-flex-flow: column;
  flex-flow: column;
}

@supports (-webkit-appearance: none) {
  .os-android .vh-100 {
    min-height: calc(100vh - 56px) !important;
  }
}

.fullHeight {
  height: 100%;
}

.loginFormOpen {
  display: none;
}
@media screen and (max-width: 1119px) {
  .loginFormOpen {
    display: block;
    width: 90%;
    background-color: #83CA39;
    border: 2px solid #83CA39;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    border-radius: 30px;
    padding: 13px;
    color: #ffffff;
    outline: none;
  }
}

.d-hide {
  display: none !important;
}

.d-inblock {
  display: inline-block;
}

.registerMain {
  width: 100vw;
  max-width: 100%;
}
@media screen and (max-width: 1119px) {
  .registerMain .registerMain {
    margin-right: 0;
  }
}
@media screen and (max-width: 1119px) {
  .registerMain .registerMain > .col-lg-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.row.fullWidth {
  width: 100%;
}

@supports (-webkit-appearance: none) {
  .os-android .page-header {
    min-height: calc(100vh - 56px);
  }
}

@media (max-width: 575px) {
  .box-left-padding {
    padding-bottom: 25px;
  }
  .form-add {
    width: 100% !important;
  }
}
.wt-scroll {
  overflow-y: auto;
}
.wt-scroll::-webkit-scrollbar {
  width: 8px;
}
.wt-scroll::-webkit-scrollbar-thumb {
  background-color: #646d7b;
  outline: 1px solid #2c3036;
  border-radius: 5px;
}
.wt-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #2c3036;
  box-shadow: inset 0 0 6px #2c3036;
  border-radius: 5px;
}

.wt-scroll--account-statement {
  max-height: 80vh;
  position: relative;
}
@media screen and (min-width: 1120px) {
  .wt-scroll--account-statement {
    max-height: calc(100vh - 284px);
  }
}

.wt-x-scroll {
  overflow-x: auto;
}
.wt-x-scroll::-webkit-scrollbar {
  width: 8px;
}
.wt-x-scroll::-webkit-scrollbar-thumb {
  background-color: #646d7b;
  outline: 1px solid #2c3036;
  border-radius: 5px;
}
.wt-x-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #2c3036;
  box-shadow: inset 0 0 6px #2c3036;
  border-radius: 5px;
}

@media screen and (max-width: 1119px) {
  .scroll {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -moz-flex-wrap: nowrap;
    -ms-flex-wrap: none;
    flex-wrap: nowrap;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}

@media screen and (max-width: 1119px) {
  .logo-brand {
    height: 39px !important;
    padding: 5px;
    width: auto;
  }
}

@media screen and (max-width: 1119px) {
  .vjs-big-play-button {
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

/* ==========================================================================
     LAYOUT HELPER CLASSES
     ========================================================================== */
.flex-1 {
  flex: 1 !important;
}

iframe#webWidget {
  padding-top: env(safe-area-inset-top);
}

/********************* 1120px ***************************/
@media screen and (max-width: 1119px) {
  /*General*/
  .m-xy-0 {
    margin: 0;
  }
  .m-flex-33 {
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .m-show {
    display: block !important;
  }
  .m-flex {
    display: flex !important;
  }
  .m-inblock {
    display: inline-block !important;
  }
  .block-1 {
    max-width: 100%;
  }
  .block-2 {
    max-width: 305px;
  }
  button:focus {
    outline: none !important;
  }
  .register-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -moz-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }
  .m-content-center {
    height: 100%;
    position: absolute;
    z-index: 100;
    width: 100%;
    background: #18191d;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    -webkit-align-content: center;
    -moz-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .m-hide {
    display: none !important;
  }
  .m-p-0 {
    padding: 0;
  }
  /*footer*/
  .pl-mob {
    padding-left: 0;
  }
  .pr-mob {
    padding-right: 0;
  }
  .rotate-90 {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .m-width {
    padding: 0;
  }
  .m-flex-50 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .m-pos-1 {
    order: 1;
    height: 124px !important;
    padding-top: 0 !important;
    min-height: 124px;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 2;
  }
  /************Settings******************/
}
@media screen and (max-width: 1119px) and (min-width: 600px) {
  .m-pos-1 {
    height: 147px !important;
  }
}
@media screen and (max-width: 1119px) and (max-height: 640px) {
  .m-pos-1 {
    height: 115px !important;
    min-height: 115px !important;
  }
}
@media screen and (max-width: 1119px) {
  .m-flex-3 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    flex: 0 0 25% !important;
    max-width: 25% !important;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .mobile-margin {
    padding: 0 !important;
  }
  .mobile-100 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-left: 0 !important;
  }
}
@media screen and (max-width: 570px) {
  .m-pos-2 {
    min-height: 280px;
  }
}
.markets-box {
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media screen and (max-width: 1119px) {
  .markets-box {
    padding-bottom: 0;
    height: 100%;
    background: linear-gradient(134.22deg, #565a5c -63.52%, #2a2e31 74.66%), #2a2e31;
    background: -o-linear-gradient(134.22deg, #565a5c -63.52%, #2a2e31 74.66%), #2a2e31;
    background: -ms-linear-gradient(134.22deg, #565a5c -63.52%, #2a2e31 74.66%), #2a2e31;
    background: -moz-linear-gradient(134.22deg, #565a5c -63.52%, #2a2e31 74.66%), #2a2e31;
    background: -webkit-linear-gradient(134.22deg, #565a5c -63.52%, #2a2e31 74.66%), #2a2e31;
  }
}
.markets-box__category {
  list-style-type: none;
  margin-bottom: 0;
}
.markets-box__category li {
  display: inline-block;
  margin-right: 5px;
}
.markets-box__category li a img {
  opacity: 0.5;
}
.markets-box__category li.active a img {
  opacity: 1;
}
.markets-box__category li a:hover img {
  opacity: 1;
}
.markets-box__category li:hover {
  cursor: pointer;
}
.markets-box__list {
  list-style-type: none;
  height: 100%;
}
.markets-box__list li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  padding: 10px 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.markets-box__list li:hover {
  background-color: #42434c;
  cursor: pointer;
}
.markets-box__list li:first-child {
  border-top: 1px solid #2a2e31;
}
@media screen and (max-width: 1119px) {
  .markets-box__list li:hover {
    background-color: rgba(24, 25, 29, 0.5);
  }
}
@media screen and (max-width: 1120px) {
  .markets-box__list--desktop {
    display: none;
  }
}
@media screen and (min-width: 1120px) {
  .markets-box__list--mobile {
    display: none;
  }
}
@media screen and (max-width: 1119px) {
  .markets-box__list .instruments-section-heading {
    font-size: 1rem;
    margin: 20px 20px 10px 20px;
  }
}
.markets-box__list .instrument-icon-wrapper {
  margin-right: 1rem;
  width: 40px;
}
.markets-box__list .instrument,
.markets-box__list .charticon {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.markets-box__list .charticon {
  text-align: right;
}
.markets-box__list .name {
  display: inline-block;
  padding-right: 5px;
  font-size: 0.875rem;
  font-weight: 700;
  color: #ffffff;
}
.markets-box__list .value {
  display: block;
  font-size: 0.875rem;
}

.account-info {
  text-align: right;
  min-width: 160px;
  height: 60px;
  padding: 9px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid #2a2e31;
  border-radius: 6px;
  background: #1d2024;
  margin-right: 10px;
  position: relative;
  /* Animation Stuff */
}
.account-info__avatar {
  margin-right: 12px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 30px;
  -moz-box-flex: 0;
  -moz-flex: 0 0 30px;
  -ms-flex: 0 0 30px;
  flex: 0 0 30px;
}
.account-info__data {
  white-space: nowrap;
}
.account-info__wallet {
  font-size: 0.6875rem;
  color: #A6A6A8;
  margin: 0;
  padding: 0;
  text-align: left;
}
.account-info__wallet--accent {
  color: #ffb200;
}
.account-info__username {
  font-size: 1rem;
  font-weight: 700;
  color: #fff;
  margin: 0;
  padding: 0;
}
.account-info .dropdown__wrapper {
  position: absolute;
  top: 100%;
  right: -1.5px;
  z-index: 1000;
  padding-top: 5px;
  min-width: 262px;
  max-height: 0;
  overflow: hidden;
}
.account-info .dropdown__container {
  border: 1.5px solid #2a2e31;
  border-radius: 5px;
  overflow: hidden;
}
.account-info .dropdown__item {
  background: linear-gradient(134.22deg, #565A5C -63.52%, #2a2e31 74.66%);
  text-align: start;
  padding: 7px 10px;
  font-size: 0.875rem;
  cursor: pointer;
}
.account-info .dropdown__item:hover {
  background: #2a2e31;
}
.account-info .dropdown__item--link {
  display: block;
  color: white;
}
.account-info .dropdown__item--link:hover {
  color: white;
}
.account-info .dropdown__item--button {
  width: 100%;
  color: white;
  display: flex;
  justify-content: space-between;
}
.account-info .current-wallet__label {
  font-size: 0.6875rem;
  color: #A6A6A8;
}
.account-info .current-wallet__value_wrapper {
  display: flex;
  justify-content: space-between;
}
.account-info .current-wallet__value {
  color: #ffb200;
}
.account-info .dropdown__container {
  transition: height 0.45s ease-in-out;
}
.account-info .dropdown__page {
  background: #1d2024;
}
.account-info .dropdown__page--wallets {
  max-height: 200px;
  overflow: auto;
}

.account-dropdown-enter {
  max-height: 0 !important;
  will-change: max-height;
  overflow: hidden;
}

.account-dropdown-enter-active, .account-dropdown-enter-done {
  max-height: 205px !important;
  transition: max-height 350ms ease;
}

.account-dropdown-exit {
  max-height: 205px !important;
  will-change: max-height;
}

.account-dropdown-exit-active, .account-dropdown-exit-done {
  max-height: 0 !important;
  overflow: hidden;
  transition: max-height 350ms ease;
}

.account-dropdown-exit-active {
  pointer-events: none;
}

.basic-info {
  display: flex;
  align-items: center;
  gap: 4px;
}

.sidemenu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 90px;
  background-color: #2a2b31;
  overflow: auto;
}
.sidemenu .logo h1 {
  height: 110px;
  margin: 0;
  text-align: center;
  text-indent: -9999px;
  white-space: nowrap;
  background-size: 70% auto;
  background-position: center 25px;
  background-repeat: no-repeat;
}
@media screen and (max-width: 1120px) {
  .sidemenu .logo h1 {
    display: none;
  }
}
.sidemenu ul {
  list-style: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 3;
  -webkit-flex: 3;
  -moz-box-flex: 3;
  -moz-flex: 3;
  -ms-flex: 3;
  flex: 3;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.sidemenu ul li {
  margin: 10px auto;
}
.sidemenu ul li svg {
  fill: white;
}
.sidemenu ul li .messages-icon svg {
  fill: none;
}
@media screen and (max-height: 795px) {
  .sidemenu ul li {
    margin: 5px auto;
  }
}
.sidemenu ul li > a, .sidemenu ul li > button, .sidemenu ul li button.refer {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  -webkit-transition: 0.35s ease-in-out;
  -moz-transition: 0.35s ease-in-out;
  -o-transition: 0.35s ease-in-out;
  transition: 0.35s ease-in-out;
  width: 60px;
  height: 60px;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidemenu ul li > a:hover, .sidemenu ul li > button:hover, .sidemenu ul li button.refer:hover {
  border: 1px solid rgba(255, 255, 255, 0.4);
}
.sidemenu ul li > a:not(.logo):hover img, .sidemenu ul li > a:not(.logo):hover i, .sidemenu ul li > button:not(.logo):hover img, .sidemenu ul li > button:not(.logo):hover i, .sidemenu ul li button.refer:not(.logo):hover img, .sidemenu ul li button.refer:not(.logo):hover i {
  opacity: 1;
}
.sidemenu ul li > a:not(.logo).active, .sidemenu ul li > button:not(.logo).active, .sidemenu ul li button.refer:not(.logo).active {
  background: linear-gradient(134.22deg, #2a2e31 -63.52%, #565a5c 74.66%);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.sidemenu ul li > a:not(.logo).active img, .sidemenu ul li > a:not(.logo).active i, .sidemenu ul li > a:not(.logo).active svg, .sidemenu ul li > button:not(.logo).active img, .sidemenu ul li > button:not(.logo).active i, .sidemenu ul li > button:not(.logo).active svg, .sidemenu ul li button.refer:not(.logo).active img, .sidemenu ul li button.refer:not(.logo).active i, .sidemenu ul li button.refer:not(.logo).active svg {
  opacity: 1;
}
.sidemenu li:first-child {
  height: 110px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidemenu li:nth-child(2) {
  margin-top: 0;
}
.sidemenu li.extra {
  align-self: flex-end;
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
}
.sidemenu li.logout button img {
  opacity: 1;
}
.sidemenu .powered-by__label {
  display: block;
  font-size: 0.6875rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}
.sidemenu .powered-by__link {
  display: block;
  text-align: center;
  font-size: 0.875rem;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.5);
  line-height: 1;
}
.sidemenu .powered-by__link:hover {
  text-decoration: none;
  color: #12aaeb;
}

.trading-bar__col {
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  width: 100%;
}
@media screen and (max-width: 1119px) {
  .trading-bar {
    width: 100%;
    max-width: 100%;
    margin: auto;
    padding: 0;
    height: auto;
    margin-bottom: constant(safe-area-inset-bottom);
    margin-bottom: env(safe-area-inset-bottom);
  }
}
@media screen and (min-width: 1120px) {
  .trading-bar {
    margin: 25px 0;
  }
}
.trading-bar__holder {
  padding: 0;
  background-color: transparent;
  height: 100%;
  width: 100%;
}
@media screen and (min-width: 1120px) {
  .trading-bar__holder {
    padding: 30px;
    background-color: #1d2024;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;
    border: 1.5px solid #2A2E31;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
  }
}
.trading-bar__upper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}
@media screen and (min-width: 1120px) {
  .trading-bar__upper {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start;
  }
}
@media screen and (max-width: 1119px) {
  .trading-bar__upper {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
}
@media screen and (max-width: 1119px) {
  .trading-bar__upper .popup-menu {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 48%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 48%;
    -ms-flex: 0 0 48%;
    flex: 0 0 48%;
  }
}
@media screen and (min-width: 1120px) {
  .trading-bar__upper .popup-menu:nth-child(1) {
    margin-right: 20px;
  }
}
@media screen and (min-width: 1120px) {
  .trading-bar__upper .popup-menu a ul {
    max-width: 140px;
  }
}
.trading-bar__right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
@media screen and (max-width: 1120px) {
  .trading-bar__right {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    padding: 0;
    position: absolute;
    right: 15px;
    padding-left: 30px;
  }
}
@media screen and (max-width: 1120px) {
  .trading-bar__right .profit-invest {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 48%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 48%;
    -ms-flex: 0 0 48%;
    flex: 0 0 48%;
  }
}
@media screen and (min-width: 1400px) {
  .trading-bar__right .profit-invest {
    padding-right: 1.25rem;
  }
}
@media screen and (min-width: 767px) {
  .trading-bar__right .profit-invest .popup-menu a ul {
    max-width: 140px;
  }
}
.trading-bar__settings {
  text-align: right;
}
@media screen and (max-width: 1399px) {
  .trading-bar__settings {
    display: none;
  }
}
.trading-bar__settings ul.trading-menu {
  height: 100%;
  list-style: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  text-align: right;
  margin: 0;
}
@media screen and (max-width: 1399px) {
  .trading-bar__settings ul.trading-menu {
    float: right;
  }
}
.trading-bar__settings ul.trading-menu li:nth-child(1) {
  margin-right: 10px;
}
.trading-bar__settings ul.trading-menu li.active a {
  background: linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
}
.trading-bar__settings ul.trading-menu li.active a img {
  opacity: 1;
}
.trading-bar__settings ul.trading-menu li a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  box-sizing: border-box;
  border-radius: 5px;
}
.trading-bar__settings ul.trading-menu li a img {
  opacity: 0.4;
  -webkit-transition: opacity 0.1s ease-in;
  -moz-transition: opacity 0.1s ease-in;
  -ms-transition: opacity 0.1s ease-in;
  -o-transition: opacity 0.1s ease-in;
  transition: opacity 0.1s ease-in;
}
.trading-bar__settings ul.trading-menu li a img:first-of-type {
  min-width: 22px;
}
.trading-bar__settings ul.trading-menu li a:hover {
  border: 1px solid rgba(255, 255, 255, 0.4);
}
.trading-bar__settings ul.trading-menu li a:hover img {
  opacity: 1;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

@media screen and (max-width: 1119px) {
  .trading-buttons {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -moz-order: 3;
    -ms-flex-order: 3;
    order: 3;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 10px 0 0 0;
  }
}
@media screen and (min-width: 1120px) {
  .trading-buttons {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
.trading-buttons__holder {
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
@media screen and (max-width: 1120px) {
  .trading-buttons__holder {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -moz-order: 3;
    -ms-flex-order: 3;
    order: 3;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
}
@media screen and (min-width: 1120px) {
  .trading-buttons__holder {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    min-width: 375px;
    height: 50px;
    position: relative;
  }
}
@media screen and (min-width: 1400px) {
  .trading-buttons__holder {
    min-width: 400px;
  }
}
.trading-buttons__holder .button {
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 100%;
  -moz-box-flex: 0;
  -moz-flex: 0 1 100%;
  -ms-flex: 0 1 100%;
  flex: 0 1 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}
@media screen and (min-width: 1120px) {
  .trading-buttons__holder .button {
    max-width: 134px;
    padding: 22.5px 10px;
    height: 50px;
  }
}
.trading-buttons__holder .button:nth-child(2) {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 76px;
  -moz-box-flex: 0;
  -moz-flex: 0 0 76px;
  -ms-flex: 0 0 76px;
  flex: 0 0 76px;
  margin: 0 15px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}
@media screen and (min-width: 1120px) {
  .trading-buttons__holder .button:nth-child(2) {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}
@media screen and (max-width: 480px) {
  .trading-buttons__holder .button {
    padding: 14px 10px;
  }
}
@media screen and (max-height: 640px) {
  .trading-buttons__holder .button {
    padding: 10px;
  }
}
.trading-buttons__holder .button span {
  margin: 0;
  padding: 0;
}
.trading-buttons__holder .button .title {
  font-size: 1.125rem;
  font-weight: 700;
  color: #ffffff;
  text-decoration: none;
  margin-right: 20px;
}
.trading-buttons__holder .button.disabled {
  opacity: 0.3;
  pointer-events: none;
}
.trading-buttons__holder .button .icons-trade i {
  font-size: 1.8125rem;
}
@media screen and (min-width: 600px) and (max-width: 1120px) {
  .trading-buttons__holder .button .icons-trade i {
    font-size: 2.1875rem;
  }
}
.trading-buttons__holder .button .icons-trade img {
  height: 10px;
  width: auto;
}

.language_switch.desktop {
  position: absolute;
  top: 55px;
  right: 40px;
}
@media screen and (max-width: 990px) {
  .language_switch.desktop {
    display: none;
  }
}
@media screen and (max-height: 530px) {
  .language_switch .dropup button.dropdown-toggle {
    background-color: transparent;
    width: 100%;
    padding: 0;
    height: 20px;
  }
}
@media screen and (max-height: 530px) {
  .language_switch .dropup button.dropdown-toggle span.lang {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .language_switch .dropup button.dropdown-toggle span.lang {
    display: none;
  }
}
.language_switch .dropup .dropupContainer {
  text-align: right;
}
.language_switch .dropup .dropupContainer.show button.dropdown-toggle {
  width: 158px;
  opacity: 0;
}
.language_switch .dropup .dropupContainer button.dropdown-toggle {
  width: 158px;
  height: 48px;
  line-height: 48px;
  text-align: left;
  font-size: 1rem;
  padding: 0 10px;
  background: linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
@media screen and (max-width: 990px) {
  .language_switch .dropup .dropupContainer button.dropdown-toggle {
    width: 80px;
  }
}
.language_switch .dropup .dropupContainer button.dropdown-toggle span {
  font-size: 1rem;
  margin: 0 0 0 10px;
}
.language_switch .dropup .dropupContainer button.dropdown-toggle span.arrow-down {
  margin-top: -4px;
}
.language_switch .dropup .dropupContainer button.dropdown-toggle span.lang {
  font-size: 0.875rem;
  font-weight: 700;
  color: #ffffff;
}
.language_switch .dropup .dropupContainer button.dropdown-toggle i {
  right: 5%;
  left: auto;
  top: 38%;
}
.language_switch .dropup .dropupContainer .dropdown-menu {
  height: calc(100vh - 10px);
  overflow-y: auto;
  overflow-x: hidden;
  background-color: transparent;
  border: none;
  width: 170px;
  min-width: 60px;
  margin-bottom: 0px;
}
.language_switch .dropup .dropupContainer .dropdown-menu a {
  color: #FFF;
  margin-top: 5px;
  padding: 0 0 0 8px;
  width: 158px;
  height: 48px;
  line-height: 48px;
  text-align: left;
  font-size: 1rem;
  padding: 0 0 0 10px;
  background: linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
}
.language_switch .dropup .dropupContainer .dropdown-menu a span {
  font-size: 1rem;
  margin: 0 0 0 10px;
  color: #ffffff;
}

.dropdown-item {
  color: #FFF !important;
  padding: 15px 0 15px 10px !important;
  font-size: 0.875rem;
  background: #2a2e31;
  transition: background-color 0.25s ease-in-out;
  text-decoration: none;
  display: block;
  white-space: nowrap;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  cursor: pointer;
}
.dropdown-item > span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.dropdown-item:after {
  position: absolute;
  right: 20px;
}
.dropdown-item:hover, .dropdown-item:focus {
  background-color: #1d2024 !important;
  text-decoration: none;
}

.registerButton {
  position: relative;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.25);
  border-color: rgba(255, 255, 255, 0.6);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}
@media screen and (max-width: 1119px) {
  .registerButton {
    background-color: #00A1DF;
    border-radius: 30px;
    border: none;
  }
}
.registerButton:before {
  content: "";
  display: block;
  padding-top: 100%;
}
@media screen and (max-width: 1119px) {
  .registerButton:before {
    padding-top: 50px;
  }
}
.registerButton__holder {
  padding: 15px;
  width: 100%;
  max-width: 100%;
  margin: auto;
}
@media screen and (min-width: 1120px) {
  .registerButton__holder {
    width: 200px;
  }
}
.registerButton .btnContent {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60% auto;
}
@media screen and (max-width: 1119px) {
  .registerButton .btnContent {
    background-image: none !important;
  }
}
.registerButton .btnDesc {
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
  left: 0;
  color: #ffffff;
  font-weight: 700;
}
@media screen and (max-width: 1119px) {
  .registerButton .btnDesc {
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 52px;
    bottom: 0;
  }
}
@media screen and (max-width: 600px) {
  .registerButton .btnDesc, .registerButton .btnDescLogin {
    font-size: 1rem;
  }
}
@media screen and (max-width: 540px) {
  .registerButton .btnDesc, .registerButton .btnDescLogin {
    font-size: 0.75rem;
  }
}
@media screen and (max-width: 1120px) {
  .registerButton .btnDescLogin {
    font-size: 1.125rem;
  }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 19px;
  /* Hide default HTML checkbox */
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.account-statement {
  height: 100%;
}
@media screen and (max-width: 1119px) {
  .account-statement {
    padding: 0;
  }
}
.account-statement .container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
}
.account-statement .content-wrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-height: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
}
.account-statement .table-wrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
@media screen and (max-width: 1119px) {
  .account-statement .table-wrapper {
    padding: 0 10px 0 0;
    margin-top: 5px;
  }
}
@media screen and (max-width: 1119px) {
  .account-statement .date-range-header {
    margin-bottom: 0;
    font-size: 1.125rem;
  }
}
@media screen and (max-width: 600px) {
  .account-statement .date-range-header {
    font-size: 0.75rem;
  }
}

.account-statement-wrapper {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 1em;
  margin-top: 10px;
}
.account-statement-wrapper th > span {
  display: inline-block;
  padding: 0;
  font-weight: 200;
  letter-spacing: 0.07em;
}
@media screen and (max-width: 1119px) {
  .account-statement-wrapper tbody {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.account-statement-wrapper tbody:nth-child(odd) {
  background: rgba(42, 46, 49, 0.5);
}
.account-statement-wrapper tbody:nth-child(even) {
  background: rgba(42, 46, 49, 0.75);
}
@media screen and (max-width: 1119px) {
  .account-statement-wrapper tbody tr {
    border-bottom: 5px solid #18191d;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    width: 100%;
    font-size: 0.875rem;
    background: #2a2b31;
    -webkit-border-radius: 5px 5px 8px 5px;
    -moz-border-radius: 5px 5px 8px 5px;
    -ms-border-radius: 5px 5px 8px 5px;
    border-radius: 5px 5px 8px 5px;
    align-items: center;
    padding-top: 10px;
  }
}
.account-statement-wrapper td, .account-statement-wrapper th {
  text-align: center;
}
@media screen and (max-width: 1119px) {
  .account-statement-wrapper thead {
    display: none;
  }
}
.account-statement-wrapper thead tr th span {
  color: #A3A3A5;
  font-size: 0.75rem;
}
.account-statement-wrapper td {
  padding: 15px 0;
}
@media screen and (max-width: 1119px) {
  .account-statement-wrapper td {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 33%;
    -ms-flex: 0 0 33%;
    flex: 0 0 33%;
    max-width: 33%;
    padding: 0;
    margin-bottom: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    -webkit-border-radius: 5px 5px 8px 5px;
    -moz-border-radius: 5px 5px 8px 5px;
    -ms-border-radius: 5px 5px 8px 5px;
    border-radius: 5px 5px 8px 5px;
  }
}
.account-statement-wrapper td.description .trade-instrument-name {
  font-size: 1rem;
  font-weight: 700;
}
@media screen and (min-width: 1120px) {
  .account-statement-wrapper td.description .trade-instrument-name {
    min-width: 60px;
  }
}
.account-statement-wrapper td.trade-time {
  font-weight: 400;
  color: #A3A3A5;
}
.account-statement-wrapper td.trade-time strong {
  display: inline-block;
  padding-right: 5px;
  font-weight: 400;
  color: #ffffff;
}
@media screen and (min-width: 1120px) {
  .account-statement-wrapper td.trade-time strong {
    color: #ffffff;
    font-weight: 700;
  }
}
.account-statement-wrapper td.side .icon {
  margin-left: 0.5rem;
  width: 20px;
}
.account-statement-wrapper td.side .icon--trending-up {
  stroke: #55D636;
}
.account-statement-wrapper td.side .icon--trending-down {
  stroke: #CD0000;
}
.account-statement-wrapper td.stop-reason .holder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}
.account-statement-wrapper td.stop-reason .holder img {
  margin-right: 10px;
}
.account-statement-wrapper td.trade-outcome {
  font-size: 1rem;
  font-weight: 700;
}
@media screen and (max-width: 1119px) {
  .account-statement-wrapper td.trade-outcome {
    font-size: 0.75rem;
  }
}
.account-statement-wrapper td.trade-outcome.win {
  color: #72C259;
}
@media screen and (max-width: 1119px) {
  .account-statement-wrapper td.trade-outcome.win span {
    background-color: #98B946;
  }
}
.account-statement-wrapper td.trade-outcome.loss {
  color: #f60057;
}
@media screen and (max-width: 1119px) {
  .account-statement-wrapper td.trade-outcome.loss span {
    background-color: #f5004d;
  }
}
@media screen and (max-width: 1119px) {
  .account-statement-wrapper td:nth-child(1) {
    text-align: right;
    position: relative;
  }
}
@media screen and (max-width: 1119px) {
  .account-statement-wrapper td:nth-child(2) > span {
    float: left;
    position: relative;
    left: 35%;
    clear: both;
  }
}
@media screen and (max-width: 1119px) {
  .account-statement-wrapper td:nth-child(2) .trade-buy:before {
    background: #4BB84E;
    width: 9px;
    height: 9px;
    content: "";
    display: inline-block;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    float: left;
    position: relative;
    left: 0;
    top: 6px;
  }
}
@media screen and (max-width: 1119px) {
  .account-statement-wrapper td:nth-child(2) .trade-buy span {
    padding-left: 2px;
  }
}
@media screen and (max-width: 1119px) {
  .account-statement-wrapper td:nth-child(2) .trade-sell:before {
    background-color: #f5004d;
    width: 9px;
    height: 9px;
    content: "";
    display: inline-block;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    float: left;
    position: relative;
    left: 0;
    top: 6px;
  }
}
@media screen and (max-width: 1119px) {
  .account-statement-wrapper td:nth-child(2) .trade-sell span {
    padding-left: 2px;
  }
}
@media screen and (max-width: 1119px) {
  .account-statement-wrapper td:nth-child(3) {
    text-align: right;
  }
}
@media screen and (max-width: 1119px) {
  .account-statement-wrapper td:nth-child(4) {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 100%;
    text-align: left;
    font-size: 0.6875rem;
    padding-left: 5px;
  }
}
@media screen and (max-width: 1119px) {
  .account-statement-wrapper td:nth-child(5) {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    text-align: right;
  }
}
@media screen and (max-width: 1119px) {
  .account-statement-wrapper .trade-instrument-quote.sell {
    background-position-x: 3px;
    background-image: none;
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 15px solid #f5004d;
  }
}
@media screen and (max-width: 1119px) {
  .account-statement-wrapper .trade-instrument-quote.buy {
    background-position-x: 3px;
    background-image: none;
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 15px solid #4BB84E;
  }
}

.trades_list {
  list-style-type: none;
  height: 100%;
}
@media screen and (max-width: 1119px) {
  .trades_list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    margin-bottom: 0;
    min-height: 110px;
  }
}
@media screen and (max-width: 600px) {
  .trades_list {
    min-height: 114px;
  }
}
.trades_list li.item {
  cursor: pointer;
  margin: 0 5px 0 0;
}
@media screen and (min-width: 1120px) {
  .trades_list li.item {
    margin: 0 20px 20px 20px;
  }
}
.trades_list li.item:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 1119px) {
  .trades_list li.item {
    padding: 0;
  }
}
.trades_list li.item.active .item__holder {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: linear-gradient(134.22deg, #565a5c -63.52%, #2a2e31 74.66%);
}
@media screen and (max-width: 1119px) {
  .trades_list li.item.active .trade-instrument-name {
    font-weight: 600 !important;
    color: #ffffff;
  }
}
.trades_list li.item.closing .item__holder {
  opacity: 0.8;
}
.trades_list li.item.mt4 .item__holder {
  background: linear-gradient(108.6deg, #3BA4E8 10%, #0A579E 62.59%), #2A2E31;
}
.trades_list li.item .item__holder {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background: #2a2b31;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid transparent;
  overflow: hidden;
}
.trades_list li.item .item__main {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 5px 5px 0 5px;
}
@media screen and (min-width: 1120px) {
  .trades_list li.item .item__main {
    padding: 10px;
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}
@media screen and (min-width: 1920px) {
  .trades_list li.item .item__main {
    padding: 20px 10px;
  }
}
.trades_list li.item .item__left {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 20px;
  -moz-box-flex: 0;
  -moz-flex: 0 0 20px;
  -ms-flex: 0 0 20px;
  flex: 0 0 20px;
}
@media screen and (min-width: 1120px) {
  .trades_list li.item .item__left {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 49%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 49%;
    -ms-flex: 0 0 49%;
    flex: 0 0 49%;
  }
}
.trades_list li.item .item__left .info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
@media screen and (max-width: 1119px) {
  .trades_list li.item .item__left .info {
    border-bottom: 1.5px solid #4c5358;
    margin: 0 -5px;
  }
}
.trades_list li.item .item__left .info .info__icon {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 70px;
  -moz-box-flex: 0;
  -moz-flex: 0 0 70px;
  -ms-flex: 0 0 70px;
  flex: 0 0 70px;
  display: none;
}
@media screen and (min-width: 1120px) {
  .trades_list li.item .item__left .info .info__icon {
    display: block;
  }
}
@media screen and (min-width: 1500px) and (max-width: 1920px) {
  .trades_list li.item .item__left .info .info__icon {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50px;
    -moz-box-flex: 0;
    -moz-flex: 0 0 50px;
    -ms-flex: 0 0 50px;
    flex: 0 0 50px;
  }
}
.trades_list li.item .item__left .info .info__icon img {
  height: 50px;
  width: auto;
}
@media screen and (min-width: 1500px) and (max-width: 1920px) {
  .trades_list li.item .item__left .info .info__icon img {
    height: 40px;
  }
}
@media screen and (max-width: 1119px) {
  .trades_list li.item .item__left .info .info__desc {
    text-align: center;
    margin: 0 auto;
  }
}
@media screen and (min-width: 1120px) {
  .trades_list li.item .item__left .info .info__desc {
    padding-top: 10px;
  }
}
.trades_list li.item .item__left .info .info__desc .name {
  margin: 0 0 5px 0;
  font-weight: 700;
  font-size: 0.75rem;
}
.trades_list li.item .item__left .info .info__desc .value {
  font-size: 0.6875rem;
  margin: 0;
  margin-top: -5px;
  background-position: center left;
  background-repeat: no-repeat;
  padding-left: 10px;
}
@media screen and (max-width: 1119px) {
  .trades_list li.item .item__left .info .info__desc .value {
    display: none;
  }
}
.trades_list li.item .item__left .info .info__desc .value.sell {
  background-image: url(~/src/icons/down.svg);
  color: #f60057;
}
.trades_list li.item .item__left .info .info__desc .value.buy {
  background-image: url(~/src/icons/up.svg);
  color: #70c157;
}
.trades_list li.item .item__right {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 49%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 49%;
  -ms-flex: 0 0 49%;
  flex: 0 0 49%;
  padding: 2px 0;
}
@media screen and (min-width: 1120px) {
  .trades_list li.item .item__right {
    padding: 0 0 0 20px;
  }
}
.trades_list li.item .item__right .details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.trades_list li.item .item__right .details .details__row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
@media screen and (min-width: 1120px) {
  .trades_list li.item .item__right .details .details__row {
    margin: 0 0 10px 0;
  }
}
.trades_list li.item .item__right .details .details__row img {
  margin-right: 10px;
  width: 10px;
  height: 10px;
}
@media screen and (max-width: 1120px) {
  .trades_list li.item .item__right .details .details__row img {
    display: none;
  }
}
.trades_list li.item .item__right .details .details__row span {
  font-size: 0.6875rem;
}
@media screen and (min-width: 1120px) {
  .trades_list li.item .item__right .details .details__row span {
    color: #ffffff;
  }
}
@media screen and (max-width: 1119px) {
  .trades_list li.item .item__right .details--desktop {
    display: none;
  }
}
@media screen and (min-width: 1120px) {
  .trades_list li.item .item__right .details--desktop {
    padding-top: 10px;
  }
}
@media screen and (min-width: 1120px) {
  .trades_list li.item .item__right .details--mobile {
    display: none;
  }
}
.trades_list li.item .item__bottom {
  width: 100%;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  height: 20px;
}
@media screen and (min-width: 1120px) {
  .trades_list li.item .item__bottom {
    height: 8px;
  }
}
.trades_list li.item .item__bottom.buy {
  background: linear-gradient(103.09deg, #70C157 21.7%, #3E8C3B 59.43%), #3E8C3B;
}
.trades_list li.item .item__bottom.sell {
  background: linear-gradient(103.09deg, #F60057 21.7%, #BB173E 59.43%), #3E8C3B;
}
.trades_list li.item .item__bottom span {
  font-size: 0.75rem;
  padding: 4px 0;
  font-weight: 700;
  margin: 0 auto;
}
@media screen and (min-width: 1120px) {
  .trades_list li.item .item__bottom span {
    display: none;
  }
}
.trades_list li.item .item__timer {
  text-align: center;
  width: 100%;
  background: #18191d;
  height: 20px;
  margin-top: 3px;
  border-radius: 5px;
}
@media screen and (max-width: 1119px) {
  .trades_list li.item .item__timer {
    font-size: 0.6875rem;
    color: #b6c2ca;
  }
}
.trades_list li.item .mt4-placeholder {
  background: linear-gradient(108.6deg, #3BA4E8 0%, #0A579E 62.59%);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  margin-top: 3px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.trades_list li.item .mt4-placeholder .value {
  font-size: 0.75rem;
  color: white;
  font-weight: bold;
}
@media screen and (max-width: 1119px) {
  .trades_list li.item .mt4-placeholder .value {
    font-size: 1rem;
  }
}
@media screen and (max-width: 600px) {
  .trades_list li.item .mt4-placeholder .value {
    font-size: 0.75rem;
  }
}
.trades_list li.item .instruments-position-container .trade-instrument-info {
  display: inline-block;
  width: 50%;
}
@media screen and (max-width: 1900px) {
  .trades_list li.item .instruments-position-container .trade-instrument-info {
    width: 100%;
    padding-top: 10px;
  }
}
@media screen and (max-width: 1500px) {
  .trades_list li.item .instruments-position-container .trade-instrument-info {
    width: 55%;
  }
}
@media screen and (max-width: 1119px) {
  .trades_list li.item .instruments-position-container .trade-instrument-info {
    width: 100%;
    text-align: center;
    padding-top: 0;
    padding-left: 5px;
    padding-right: 5px;
  }
}
.trades_list li.item .instruments-position-container .trade-instrument-info .trade-instrument-icon {
  height: 30px;
  margin-right: 5px;
  margin-top: -20px;
}
@media screen and (max-width: 1119px) {
  .trades_list li.item .instruments-position-container .trade-instrument-info .trade-instrument-icon {
    display: none;
    margin-top: 0 !important;
  }
}
.trades_list li.item .instruments-position-container .trade-instrument-info .trade-instrument-name {
  font-size: 0.875rem;
}
@media screen and (max-width: 1119px) {
  .trades_list li.item .instruments-position-container .trade-instrument-info .trade-instrument-name {
    font-weight: 500;
    font-size: 1rem;
    color: whitesmoke;
  }
}
@media screen and (max-width: 600px) {
  .trades_list li.item .instruments-position-container .trade-instrument-info .trade-instrument-name {
    font-size: 0.875rem;
  }
}
.trades_list li.item .instruments-position-container .trade-instrument-info .trade-instrument-quote {
  display: block;
  margin-left: 30px;
  margin-top: -5px;
  background-position: center left;
  background-repeat: no-repeat;
  padding-left: 10px;
}
@media screen and (max-width: 1900px) {
  .trades_list li.item .instruments-position-container .trade-instrument-info .trade-instrument-quote {
    display: inline;
  }
}
@media screen and (max-width: 1710px) {
  .trades_list li.item .instruments-position-container .trade-instrument-info .trade-instrument-quote {
    display: block;
  }
}
.trades_list li.item .instruments-position-container .trade-instrument-info .trade-instrument-quote.sell {
  background-image: url(~/src/icons/down.svg);
  color: #f5004d;
}
.trades_list li.item .instruments-position-container .trade-instrument-info .trade-instrument-quote.buy {
  background-image: url(~/src/icons/up.svg);
  color: #4bb84e;
}
.trades_list li.item .instruments-position-container #position-open-price span {
  font-size: 0.75rem;
}
.trades_list li.item .instruments-position-container .trade-settings-info {
  display: inline-block;
  width: 45%;
}
@media screen and (max-width: 1900px) {
  .trades_list li.item .instruments-position-container .trade-settings-info {
    display: inline-block;
    width: 100%;
    padding: 10px 0 0 7px;
  }
}
@media screen and (max-width: 1500px) {
  .trades_list li.item .instruments-position-container .trade-settings-info {
    width: 45%;
  }
}
@media screen and (max-width: 1119px) {
  .trades_list li.item .instruments-position-container .trade-settings-info {
    padding: 0 5px 0 5px;
    font-size: 0.875rem;
    color: #ffffff;
    text-align: center;
  }
}
.trades_list li.item .instruments-position-container .trade-settings-info .trade-settings-time, .trades_list li.item .instruments-position-container .trade-settings-info .trade-settings-pl {
  padding-left: 25px;
}
.trades_list li.item .instruments-position-container .trade-settings-info .trade-settings-time strong, .trades_list li.item .instruments-position-container .trade-settings-info .trade-settings-pl strong {
  color: #ffffff;
}
@media screen and (min-width: 1119px) {
  .trades_list li.item .instruments-position-container .trade-settings-info .trade-settings-time strong, .trades_list li.item .instruments-position-container .trade-settings-info .trade-settings-pl strong {
    color: #ffb200;
  }
}
@media screen and (max-width: 1119px) {
  .trades_list li.item .instruments-position-container .trade-settings-info .trade-settings-time, .trades_list li.item .instruments-position-container .trade-settings-info .trade-settings-pl {
    padding-left: 0;
  }
}
.trades_list li.item .instruments-position-container .trade-settings-info .trade-settings-time {
  display: block;
  background: url(~/src/icons/clock.svg) center left no-repeat;
}
@media screen and (max-width: 1119px) {
  .trades_list li.item .instruments-position-container .trade-settings-info .trade-settings-time {
    background: none;
    display: none;
  }
}
.trades_list li.item .instruments-position-container .trade-close {
  position: absolute;
  top: -5px;
  right: -5px;
  height: 20px;
  width: 20px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background-color: #f5004d;
}
.trades_list li.item .instruments-position-container .trade-close:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "×"; /* use the hex value here... */
  font-size: 22px;
  color: #FFF;
  line-height: 20px;
  text-align: center;
}
.trades_list li.item.new {
  opacity: 0.3;
}
.trades_list li.item .trade-timer {
  text-align: center;
  width: 100%;
  margin-top: 20px;
}
@media screen and (max-width: 1119px) {
  .trades_list li.item .trade-timer {
    margin-top: 0;
    font-size: 0.6875rem;
    color: #ffffff;
  }
}
.trades_list li.item .trade-timer > span {
  background: url(~/src/icons/clock.svg) no-repeat;
  background-position: 10px;
  display: inline-block;
  padding: 5px 15px 5px 35px;
  border-radius: 15px;
  background-color: #18191d;
}
@media screen and (max-width: 1119px) {
  .trades_list li.item .trade-timer > span {
    background: none;
    padding: 0;
    background-color: transparent;
    border-radius: 0;
  }
}
.trades_list li.item .trade-timer strong {
  color: #ffb200;
}
@media screen and (max-width: 1119px) {
  .trades_list li.item .trade-timer strong {
    color: #ffffff;
  }
}
@media screen and (max-width: 1119px) {
  .trades_list--running .open-positions-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -moz-flex-wrap: nowrap;
    -ms-flex-wrap: none;
    flex-wrap: nowrap;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}
@media screen and (max-width: 1119px) {
  .trades_list--running .open-positions-container::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
  }
}
@media screen and (max-width: 1119px) {
  .trades_list--running li {
    width: 90px;
    height: auto;
    margin: 3px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: hidden;
  }
}
@media screen and (max-width: 1119px) {
  .trades_list--running li:first-of-type {
    margin-left: 0;
  }
}
@media screen and (max-width: 1119px) {
  .trades_list--running li.active {
    opacity: 1;
  }
}
@media screen and (max-width: 1119px) {
  .trades_list--running li.active.closing {
    opacity: 0.8;
  }
}
@media screen and (max-width: 1119px) {
  .trades_list--running .instruments-position-container {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}
@media screen and (max-width: 1119px) {
  .trades_list--running .instruments-position-container .instrument-quote-indicator {
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    -webkit-justify-content: space-evenly;
    -moz-justify-content: space-evenly;
    justify-content: space-evenly;
  }
}
@media screen and (max-width: 1119px) {
  .trades_list--running .instruments-position-container .instrument-quote-indicator svg {
    margin-right: -5px !important;
  }
}
@media screen and (max-width: 1119px) {
  .trades_list--running .profit-loss-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    padding: 2px;
    width: 100%;
    height: 19px;
  }
}
@media screen and (max-width: 600px) {
  .trades_list--running .profit-loss-container {
    height: 18px;
  }
}
@media screen and (max-width: 1119px) {
  .trades_list--running .profit-loss-container .progress-bar__bar {
    background-image: linear-gradient(to right, #12AAEB, #12bfea, #12cdea);
  }
}
@media screen and (max-width: 1119px) {
  .trades_list--running .profit-loss-container span {
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 600px) {
  .trades_list--running .profit-loss-container span {
    font-size: 0.6875rem;
  }
}
.trades_list--closed {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow-x: hidden;
}

.vertical-bar-blue {
  background-color: #1496cd;
  margin-top: 0;
  height: 100% !important;
  position: absolute !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  overflow: visible !important;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  height: 0;
}
@media screen and (min-width: 1120px) {
  .vertical-bar-blue {
    display: none;
  }
}
.vertical-bar-blue svg {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  fill: #ffffff;
  background-color: #1496cd;
  left: -8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  font-size: 0.8125rem;
  padding: 5px;
}
.vertical-bar-blue span {
  padding: 0 20px;
  overflow: hidden;
  word-break: break-word;
  width: 100%;
  font-weight: 500;
  font-size: 1rem;
  height: 25px;
}
@media screen and (max-width: 599px) {
  .vertical-bar-blue span {
    font-size: 0.75rem;
    height: 19px;
  }
}
@media screen and (max-width: 1119px) {
  .vertical-bar-blue__overlay {
    position: absolute !important;
  }
}

@media screen and (max-width: 1119px) {
  .footer {
    text-align: center;
  }
}
@media screen and (min-width: 1120px) {
  .footer {
    display: none;
  }
}
@media screen and (max-width: 1119px) {
  .footer__lost {
    font-size: 0.75rem;
    top: 4px;
    position: relative;
    opacity: 0.5;
    max-width: 247px;
    margin: 0 auto 20px auto;
  }
}
@media screen and (max-width: 340px) {
  .footer__lost {
    font-size: 0.6875rem;
    top: 0;
    position: static;
  }
}
@media screen and (max-width: 1119px) {
  .footer__links a {
    text-decoration: none;
    font-size: 0.75rem;
    padding: 0 15px;
    color: #ffffff;
  }
}
@media screen and (max-width: 1119px) {
  .footer__links a:nth-child(2) {
    text-transform: uppercase;
  }
}
@media screen and (max-width: 1119px) {
  .footer__links span {
    opacity: 0.15;
  }
}
.footer .powered-by {
  margin-top: 1rem;
  text-align: center;
}
.footer .powered-by__label {
  font-size: 0.6875rem;
  color: rgba(255, 255, 255, 0.5);
}
.footer .powered-by__link {
  font-size: 0.875rem;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.5);
  line-height: 1;
}
.footer .powered-by__link:hover {
  text-decoration: none;
  color: #12aaeb;
}

.back-button {
  cursor: pointer;
  height: 42px;
  min-height: 42px;
  width: 42px;
  min-width: 42px;
  background: linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.back-button__icon {
  width: 16px;
}

.header {
  height: 110px;
  min-height: 110px;
  z-index: 1060;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.header a:hover {
  text-decoration: none;
}
.header .profile-leaderboard-holder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-left: 10px;
}
.header .profile-leaderboard-holder .rank-stat {
  width: 100px;
  min-height: 60px;
}

.open-position-big {
  font-size: 0.75rem;
}
@media screen and (max-width: 1119px) {
  .open-position-big {
    padding-bottom: 10px;
    font-size: 1rem;
  }
}
@media screen and (max-width: 600px) {
  .open-position-big {
    padding-bottom: 0;
    font-size: 0.75rem;
  }
}
@media screen and (max-height: 639px) {
  .open-position-big {
    display: none;
  }
}
.open-position-big .position-info-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
@media screen and (max-width: 340px) {
  .open-position-big .position-info-row {
    padding: 2px 0 0 0;
  }
}
.open-position-big .position-info-row .buy-sell-trend-wrapper {
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 24%;
  -moz-box-flex: 0;
  -moz-flex: 0 1 24%;
  -ms-flex: 0 1 24%;
  flex: 0 1 24%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  align-items: stretch;
  background: rgba(42, 46, 49, 0.5);
  border: 1.5px solid #2a2e31;
  box-sizing: border-box;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  min-height: 70px;
}
.open-position-big .position-info-row .buy-sell-trend-wrapper .buy-sell-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.open-position-big .position-info-row .icon-wrapper {
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 24%;
  -moz-box-flex: 0;
  -moz-flex: 0 1 24%;
  -ms-flex: 0 1 24%;
  flex: 0 1 24%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  background: rgba(42, 46, 49, 0.5);
  border: 1.5px solid #2a2e31;
  box-sizing: border-box;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  min-height: 70px;
}
@media screen and (min-width: 600px) {
  .open-position-big .position-info-row .icon-wrapper {
    min-height: 70px;
  }
}
.open-position-big .position-info-row .icon-wrapper__holder {
  position: relative;
}
.open-position-big .position-info-row .icon-wrapper__holder .relative-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.open-position-big .position-info-row .icon-wrapper__holder:before {
  content: url(~/src/icons/main/arrow-right.svg);
  width: 42px;
  height: 42px;
  position: relative;
  top: -10px;
  right: -33px;
  display: block;
}
.open-position-big .position-info-row .icon-wrapper__holder:after {
  content: url(~/src/icons/main/arrow-left.svg);
  width: 42px;
  height: 42px;
  position: absolute;
  bottom: -25px;
  left: -5px;
  display: block;
}
.open-position-big .position-info-row .instrument-info {
  background: linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 49%;
  -moz-box-flex: 0;
  -moz-flex: 0 1 49%;
  -ms-flex: 0 1 49%;
  flex: 0 1 49%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 70px;
  padding: 5px 0;
}
@media screen and (min-width: 600px) {
  .open-position-big .position-info-row .instrument-info {
    min-height: 70px;
  }
}
.open-position-big .position-info-row .instrument-info__general {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  width: 100%;
}
.open-position-big .position-info-row .instrument-info__general .currency, .open-position-big .position-info-row .instrument-info__general .rise-fall {
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 50%;
  -moz-box-flex: 0;
  -moz-flex: 0 1 50%;
  -ms-flex: 0 1 50%;
  flex: 0 1 50%;
  padding: 0 10px;
  max-width: 50%;
  margin: 0;
}
@media screen and (max-width: 480px) {
  .open-position-big .position-info-row .instrument-info__general .currency, .open-position-big .position-info-row .instrument-info__general .rise-fall {
    padding: 0 5px;
  }
}
.open-position-big .position-info-row .instrument-info__general .currency span {
  font-size: 0.75rem;
  font-weight: 700;
}
.open-position-big .position-info-row .instrument-info__general .rise-fall .instrument-quote-indicator {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
}
@media screen and (max-width: 766px) {
  .open-position-big .position-info-row .instrument-info__general .rise-fall .instrument-quote-indicator img {
    width: 0.8em;
  }
}
.open-position-big .position-info-row .instrument-info__general .rise-fall .instrument-quote-indicator img.up + span {
  color: #4bb84e;
}
.open-position-big .position-info-row .instrument-info__general .rise-fall .instrument-quote-indicator img.down + span {
  color: #f60057;
}
.open-position-big .position-info-row .instrument-info__general .rise-fall .instrument-quote-indicator span {
  font-size: 0.75rem;
  font-weight: 700;
  margin-left: 7px;
}
@media screen and (max-width: 480px) {
  .open-position-big .position-info-row .instrument-info__general .rise-fall .instrument-quote-indicator span {
    margin-left: 3px;
  }
}
.open-position-big .progress-bar-row {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin: 10px 0;
  background: rgba(42, 46, 49, 0.5);
  box-sizing: border-box;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
}
@media screen and (max-height: 640px) {
  .open-position-big .progress-bar-row {
    margin: 5px 0;
  }
}
@media screen and (min-width: 600px) {
  .open-position-big .progress-bar-row {
    margin: 10px 0 0 0;
  }
}
.open-position-big .progress-bar-row .progress-bar-wrapper {
  width: 75%;
  height: 20px;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
}
@media screen and (min-width: 600px) {
  .open-position-big .progress-bar-row .progress-bar-wrapper {
    height: 30px;
  }
}
@media screen and (min-width: 600px) and (max-width: 1120px) {
  .open-position-big .progress-bar-row .progress-bar-wrapper .progress-bar__value {
    font-size: 0.875rem;
  }
}
.open-position-big .progress-bar-row .profit-loss-wrapper {
  width: 25%;
  height: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end;
}
@media screen and (min-width: 600px) {
  .open-position-big .progress-bar-row .profit-loss-wrapper {
    height: 30px;
  }
}
.open-position-big .progress-bar-row .profit-loss-wrapper span {
  font-size: 0.75rem;
  font-weight: 700;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 600px) {
  .open-position-big .progress-bar-row .profit-loss-wrapper span {
    height: 30px !important;
    padding: 4px 0;
    font-size: 0.875rem;
  }
}

.page-title__title {
  margin: 0 0 1.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

.date-range-header {
  min-height: 42px;
  margin-bottom: 15px;
  padding: 10px 20px;
  background: linear-gradient(134.22deg, #565a5c -63.52%, #2a2e31 74.66%);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  position: relative;
  font-size: 0.875rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}

.trade-result {
  text-align: center;
  width: 100%;
  margin-top: 20px;
}
@media screen and (max-width: 1119px) {
  .trade-result {
    font-size: 0.875rem;
    text-align: center;
    width: 100%;
    margin-top: 0;
  }
}
.trade-result > span {
  display: inline-block;
  padding: 5px 10px 5px 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  background-color: #18191d;
}
@media screen and (max-width: 1119px) {
  .trade-result > span {
    display: inline-block;
    padding: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
  }
}
.trade-result.win > span {
  background-color: #4bb84e;
  width: 90%;
  margin-bottom: 5px;
}
@media screen and (max-width: 1500px) {
  .trade-result.win > span {
    width: auto;
    min-width: 50%;
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 1119px) {
  .trade-result.win > span {
    width: 100%;
    min-width: 100%;
    margin-bottom: 0;
    font-weight: 400;
    background-color: #98B946;
  }
}
.trade-result.loss > span {
  background-color: #f5004d;
  width: 90%;
  margin-bottom: 5px;
}
@media screen and (max-width: 1500px) {
  .trade-result.loss > span {
    width: auto;
    min-width: 50%;
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 1119px) {
  .trade-result.loss > span {
    width: 100%;
    min-width: 100%;
    margin-bottom: 0;
    font-weight: 400;
  }
}

.main_content {
  z-index: 1030;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #18191d;
  overflow: visible;
  margin-top: 110px;
}
@media screen and (max-width: 1119px) {
  .main_content {
    margin-top: 0;
  }
}

.positions-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
}
@media screen and (max-width: 1119px) {
  .positions-list {
    -webkit-flex-wrap: nowrap;
    -moz-flex-wrap: nowrap;
    -ms-flex-wrap: none;
    flex-wrap: nowrap;
    overflow: auto;
    overflow-scrolling: touch;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    width: 100vw;
    max-width: 100%;
    margin-bottom: 10px;
    height: auto;
    background-color: transparent;
  }
}
@media screen and (max-height: 640px) {
  .positions-list {
    margin-bottom: 2.5px;
  }
}
@media screen and (max-width: 1119px) {
  .positions-list::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
  }
}
.positions-list .counter-bubble {
  background-color: #f5004d;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 12px;
  display: block;
  margin-left: 1rem;
}

.trend-indicator-tooltip {
  background: rgba(0, 0, 0, 0.9);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.35);
  border-radius: 5px;
  color: white;
  max-width: 600px !important;
}
.trend-indicator-tooltip-arrow {
  border-color: rgba(0, 0, 0, 0.9);
}
.trend-indicator-tooltip .content-wrapper {
  font-size: 0.875rem;
  max-width: 400px;
}
.trend-indicator-tooltip .content-wrapper .indicator-description {
  margin-left: 14px;
  margin-bottom: 0;
}
.trend-indicator-tooltip .content-wrapper .indicator-description:before {
  width: 9px;
  height: 9px;
  position: static;
  top: 0px;
  margin-right: 5px;
  margin-left: -14px;
  display: inline-block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  content: "";
}
.trend-indicator-tooltip .content-wrapper .indicator-description--sell:before {
  background: #CD0000;
}
.trend-indicator-tooltip .content-wrapper .indicator-description--buy:before {
  background: #55D636;
}

.refer-tooltip {
  box-shadow: 5px 8px 14px 0 black;
  background: #24262b !important;
  border: 1px solid #2a2e31 !important;
  border-radius: 9px !important;
  color: white;
  margin-top: 24.5px;
  z-index: 1070 !important;
  max-width: 323px !important;
  padding: 20px !important;
}
.refer-tooltip .copied-label {
  margin: 0 0 17px 0;
  font-size: 1rem;
}
.refer-tooltip #tooltip_arrow {
  fill: #24262b;
}
.refer-tooltip .referral-link-input {
  border: 1px solid #2a2e31;
  color: #7e868f;
  padding: 17px 15px;
  background: #18191d;
  width: 280px;
  height: 48px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  font-size: 0.875rem;
  cursor: pointer;
}

.react-orientation {
  background-color: #18191d;
  display: flex;
  flex-direction: row;
  color: #ffffff;
  font-weight: 200;
  height: 100%;
}

.device-orientation-wrapper {
  height: 100%;
}

.total-footer {
  min-height: 40px;
  margin: 10px 0 0 0;
  background-color: transparent;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  overflow: hidden;
}
@media screen and (min-width: 1120px) {
  .total-footer {
    margin: 20px 0 0 0;
    padding: 0 30px;
  }
}
.total-footer__amount {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  padding: 10px 20px;
}
@media screen and (max-width: 1120px) {
  .total-footer__amount {
    padding: 6px 20px;
  }
}
.total-footer__amount.positive {
  background: linear-gradient(103.09deg, #70c157 21.7%, #3e8c3b 59.43%);
}
.total-footer__amount.negative {
  background: linear-gradient(103.09deg, #f60057 21.7%, #bb173e 59.43%);
}
.total-footer__amount span {
  font-weight: 700;
  font-size: 1rem;
}
.total-footer__label {
  font-size: 1rem;
  font-weight: 700;
}
@media screen and (max-width: 1119px) {
  .total-footer__label:after {
    content: ":";
  }
}

.rank-stat {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.06);
  border: 1.5px solid rgba(255, 255, 255, 0.5);
  color: white;
  cursor: pointer;
}
.rank-stat:hover {
  text-decoration: none;
  color: white;
}
@media screen and (max-width: 1119px) {
  .rank-stat {
    flex: 1;
    margin-right: 0;
  }
}
.rank-stat.up {
  background: rgba(85, 214, 54, 0.06);
  border: 1.5px solid rgba(85, 214, 54, 0.5);
}
.rank-stat.down {
  background: rgba(205, 0, 0, 0.06);
  border: 1.5px solid rgba(205, 0, 0, 0.5);
}
.rank-stat .nickname-wrapper {
  flex: 1;
}
.rank-stat .stat-wrapper {
  display: flex;
}
.rank-stat .stat-wrapper .arrow {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}
.rank-stat .stat-wrapper .rank {
  text-align: center;
  min-width: 2rem;
  font-size: 1rem;
  font-weight: 700;
}
.rank-stat .stat-wrapper .rank > span {
  font-size: 0.875rem;
  font-weight: normal;
}

.competition-countdown {
  display: flex;
  flex-direction: column;
  margin: auto 20px 30px;
  flex: 0 1 105px;
}
.competition-countdown--inline {
  flex: 1;
}
@media screen and (max-width: 1119px) {
  .competition-countdown {
    margin: 0;
  }
}
.competition-countdown .label {
  margin: 0.5rem;
  opacity: 0.6;
  font-size: 0.875rem;
  text-align: center;
}
@media screen and (min-width: 1120px) {
  .competition-countdown .label {
    margin: 1rem 0;
  }
}
.competition-countdown .countdown {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  background: linear-gradient(134.22deg, #565A5C -63.52%, #2a2e31 74.66%), #2a2e31;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}
.competition-countdown .countdown span {
  text-align: center;
  font-size: 1.5rem;
  display: block;
  padding: 5px 0;
}
@media screen and (min-width: 1120px) {
  .competition-countdown .countdown span {
    padding: 10px 0;
  }
}
.competition-countdown .countdown span:not(:last-child) {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}
.competition-countdown .countdown span.days {
  font-weight: 700;
}
.competition-countdown .countdown span.hours {
  font-weight: 500;
}
.competition-countdown .countdown span.minutes {
  font-weight: 400;
}
.competition-countdown .countdown span.seconds {
  font-weight: 300;
}
.competition-countdown .countdown-labels {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: 0.3rem;
  margin-bottom: 0.6rem;
}
.competition-countdown .countdown-labels span {
  text-align: center;
  opacity: 0.5;
  font-size: 0.6875rem;
}

.mobile-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  align-items: stretch;
  margin: 0 0 5px 0;
  text-align: center;
  position: relative;
  padding: 0 15px;
}
@media screen and (min-width: 1120px) {
  .mobile-header {
    display: none;
  }
}
@media screen and (max-height: 640px) {
  .mobile-header {
    margin: 0 0 2.5px 0;
  }
}
.mobile-header .logo-container {
  text-align: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.mobile-header .buttons-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow-x: auto;
}
.mobile-header .buttons-container::-webkit-scrollbar {
  display: none !important;
  width: 0 !important;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}
@media screen and (min-width: 1120px) {
  .mobile-header a img.logo-brand {
    display: none;
  }
}
.mobile-header .button {
  cursor: pointer;
  height: 42px;
  width: 42px;
  background: linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.mobile-header .button i {
  padding: 7.5px;
}
.mobile-header .button i.logout {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.mobile-header .button.market {
  margin-right: 5px;
}
.mobile-header .button.leaderboard {
  background: linear-gradient(43.68deg, #EDA800 24.42%, #FFDC52 75.58%), #FCC200;
}
.mobile-header .button.leaderboard img {
  background: none;
}
.mobile-header .button:not(:first-child) {
  margin-left: 5px;
}
.mobile-header .button a {
  color: white;
  display: block;
}
.mobile-header .row {
  margin: 5px 0;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: none;
  flex-wrap: nowrap;
}
.mobile-header .row:first-child {
  margin-top: 0;
}
@media screen and (max-height: 640px) {
  .mobile-header .row {
    margin: 2.5px 0;
  }
}

.mobile-page-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  align-items: stretch;
  margin: 10px 0;
  position: relative;
  min-height: 42px;
}
@media screen and (min-width: 1120px) {
  .mobile-page-header {
    display: none;
  }
}
.mobile-page-header__logo {
  text-align: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.mobile-page-header .back-button {
  position: absolute;
  top: 0;
  left: 10px;
}

.messages-icon {
  position: relative;
}
.messages-icon__unread-badge {
  position: absolute;
  background-color: #70c157;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  top: 3px;
  right: -2px;
}

.message-count {
  position: absolute;
  background-color: #70c157;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  overflow: visible;
  align-items: center;
  justify-content: center;
  top: -25px;
  right: -25px;
}
.message-count > span {
  font-size: 0.6875rem;
  text-decoration: none;
  color: white;
}

.message-item {
  border: 1px solid #2a2e31;
  border-radius: 5px;
  padding: 16px 24px;
  display: flex;
  margin: 0.5rem 0;
  color: #FFF;
  flex-direction: column;
}
.message-item:hover {
  text-decoration: none;
  color: #FFF;
}
@media screen and (max-width: 1119px) {
  .message-item {
    flex-direction: column-reverse;
    padding: 15px 20px;
  }
}
.message-item:first-child {
  margin-top: 0;
}
.message-item__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.message-item__title {
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 0;
  flex: 1;
  min-width: 0;
  text-overflow: ellipsis;
  max-lines: 2;
}
.message-item__content p {
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: regular;
  color: #A6A6A8;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.message-item__end {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1119px) {
  .message-item__end {
    margin: 0 0 0.5rem;
    justify-content: space-between;
  }
}
.message-item__end .message-item__date {
  font-size: 0.75rem;
  color: #70c157;
}
.message-item__end .message-item__unread-badge {
  width: 8px;
  height: 8px;
  background-color: #2a2e31;
  border-radius: 100%;
  margin-left: 1rem;
}
.message-item--unread {
  background-color: #2a2e31;
}
.message-item--unread .message-item__unread-badge {
  background-color: #70c157;
}

.maintenance-screen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  position: absolute;
}
.maintenance-screen__logo {
  max-width: 190px;
  position: absolute;
  top: 3rem;
}
.maintenance-screen__icon {
  margin-top: -150px;
}
.maintenance-screen__title {
  margin-top: -150px;
  color: white;
}
.maintenance-screen__description {
  margin: 1rem 1rem;
  text-align: center;
  max-width: 450px;
  opacity: 0.6;
}
.maintenance-screen__button {
  margin-top: 1rem;
}

.cashier-iframe-wrapper {
  flex: 1;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
.cashier-iframe-wrapper__iframe {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.main-card-header {
  margin: 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 33px;
}
.main-card-header__title-wrapper {
  display: flex;
}
.main-card-header__title {
  font-size: 1.125rem;
  font-weight: 700;
  margin: 0;
}
@media screen and (max-width: 1120px) {
  .main-card-header {
    display: none;
  }
}

.insufficient-balance-amounts {
  display: flex;
  flex-direction: column;
  color: #18191d;
  width: 100%;
}
.insufficient-balance-amounts__item {
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
}
.insufficient-balance-amounts__item__icon {
  margin-right: 0.5rem;
}
.insufficient-balance-amounts__item__label {
  flex: 1;
  font-weight: 700;
  font-size: 1rem;
}
.insufficient-balance-amounts__item__value {
  font-size: 1rem;
}
.insufficient-balance-amounts__item__value--accent {
  color: #CD0000;
  font-weight: 700;
}
.insufficient-balance-amounts__divider {
  width: 100%;
}

.modal-close-button {
  font-size: 2.625rem;
  line-height: 1;
  font-weight: 100;
  color: #ffffff;
  -webkit-text-shadow: 0 1px 0 #ffffff;
  -moz-text-shadow: 0 1px 0 #ffffff;
  text-shadow: 0 1px 0 #ffffff;
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 2;
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  -ms-transition: opacity 0.25s ease;
  -o-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}
.modal-close-button:hover {
  opacity: 0.75;
}
.modal-close-button--dark {
  color: #18191d;
}
.modal-close-button--dark:hover {
  opacity: unset;
  font-weight: 200;
}

.modal-footer-buttons {
  width: 100%;
  margin-top: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}
@media screen and (min-width: 340px) {
  .modal-footer-buttons {
    margin-top: 30px;
  }
}

.desktop-instrument-trend-indicator {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  height: 30px;
}
.desktop-instrument-trend-indicator__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.desktop-instrument-trend-indicator .label {
  margin: 0 0.5rem;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.7;
}
.desktop-instrument-trend-indicator .label-sell {
  color: #CD0000;
}
.desktop-instrument-trend-indicator .label-buy {
  color: #55D636;
}
.desktop-instrument-trend-indicator .bar-wrapper {
  flex: 1;
  border-radius: 5px;
  height: 4px;
  width: 100%;
  background: green;
  display: flex;
}
.desktop-instrument-trend-indicator .bar-wrapper .sell-bar {
  transition: width 0.2s ease-in-out;
  background: linear-gradient(103.09deg, #f60057 21.7%, #bb173e 59.43%);
}
.desktop-instrument-trend-indicator .bar-wrapper .buy-bar {
  transition: width 0.2s ease-in-out;
  background: linear-gradient(103.09deg, #70c157 21.7%, #3e8c3b 59.43%);
}

.mobile-instrument-trend-indicator {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.mobile-instrument-trend-indicator .item {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobile-instrument-trend-indicator .item:first-child {
  border-bottom: 1px solid #2a2e31;
}
.mobile-instrument-trend-indicator .item__label {
  flex: 1;
  font-size: 0.75rem;
  font-weight: 700;
}
.mobile-instrument-trend-indicator .item__icon {
  flex: 1;
  margin-right: 0.5rem;
  text-align: end;
}

.ReactModal__Content .instrument-trend-explainer {
  text-align: center;
  margin: 0 1rem;
}
.ReactModal__Content .instrument-trend-explainer .title {
  margin-bottom: 1rem;
}
.ReactModal__Content .instrument-trend-explainer h5 {
  margin: 0.5rem 0;
}
.ReactModal__Content .instrument-trend-explainer .icon {
  width: 64px;
  height: 64px;
}

.insufficient-funds .insufficient-balance-amounts {
  color: #fff;
}
.insufficient-funds .insufficient-balance-amounts__divider {
  border-top-color: #fff;
}
.insufficient-funds .insufficient-balance-amounts__item__label {
  text-align: left;
  margin-left: 30px;
}

.no-messages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.no-messages__icon {
  margin-top: 5rem;
  margin-bottom: 4rem;
}

li.tab {
  border: 1px solid #2a2e31;
  border-radius: 5px 5px 0 0;
  background: #2a2b31;
  padding: 0.5rem 1rem;
  margin: 0 0.1rem;
  cursor: pointer;
}
li.tab > * {
  opacity: 0.35;
}
li.tab--active {
  background: linear-gradient(134.22deg, #2a2e31 -63.52%, #565a5c 74.66%);
}
li.tab--active > * {
  opacity: 1;
}

.tabs-container ul.tabs {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  margin: 0;
}
.tabs-container .content {
  background: #1d2024;
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.trading-strategy-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #2a2b31;
  border: 1px solid #383d44;
  border-radius: 5px;
  padding: 0.4rem 1rem;
  position: relative;
  cursor: pointer;
}
.trading-strategy-row:hover {
  background: linear-gradient(134.22deg, #565a5c -63.52%, #2a2e31 74.66%);
}
.trading-strategy-row__link {
  color: unset;
  margin: 0.5rem 0;
  display: block;
}
.trading-strategy-row__link:hover {
  color: unset;
  text-decoration: none;
}
@media screen and (max-width: 766px) {
  .trading-strategy-row {
    flex-direction: column;
    align-items: stretch;
  }
}
.trading-strategy-row::after {
  content: "";
  position: absolute;
  left: 0;
  top: 20%;
  bottom: 20%;
  width: 5px;
  background-color: #70c157;
  border-radius: 0 5px 5px 0;
}
@media screen and (max-width: 766px) {
  .trading-strategy-row::after {
    height: 45px;
    top: 0.5rem;
    bottom: unset;
  }
}
.trading-strategy-row .basic-info {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  padding: 0.5rem 0;
  min-width: 190px;
  width: 190px;
}
@media screen and (max-width: 766px) {
  .trading-strategy-row .basic-info {
    flex-direction: column;
    align-self: flex-start;
    align-items: flex-start;
  }
}
.trading-strategy-row .basic-info .name {
  display: block;
  margin: 0;
}
.trading-strategy-row .basic-info .copiers {
  display: block;
  font-size: 0.75rem;
  margin-left: 1rem;
  white-space: nowrap;
}
@media screen and (max-width: 766px) {
  .trading-strategy-row .basic-info .copiers {
    margin-left: 0;
  }
}
.trading-strategy-row .stats-wrapper {
  flex: 1;
}
@media screen and (max-width: 766px) {
  .trading-strategy-row .stats-wrapper {
    margin: 0.5rem 0;
  }
}
.trading-strategy-row .stats-wrapper .stats {
  min-width: 210px;
  max-width: 250px;
  margin: 0 auto;
  background: #2f3235;
  border: 1px solid #383d44;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  font-size: 0.75rem;
  line-height: 2.16;
}
@media screen and (max-width: 766px) {
  .trading-strategy-row .stats-wrapper .stats {
    max-width: unset;
  }
}
.trading-strategy-row .stats-wrapper .stats__item {
  display: flex;
  justify-content: space-between;
}
.trading-strategy-row .stats-wrapper .value {
  font-weight: bold;
  margin-left: 0.5rem;
}
.trading-strategy-row .breakdown-chart {
  margin: 0 1rem;
  height: 80px;
  width: 280px;
}
@media screen and (max-width: 766px) {
  .trading-strategy-row .breakdown-chart {
    margin: 0.5rem auto;
  }
}
.trading-strategy-row .performance-chart {
  margin: 0 1rem;
  flex: 1;
  height: 80px;
}
@media screen and (max-width: 990px) {
  .trading-strategy-row .performance-chart {
    display: none;
  }
}
@media screen and (max-width: 766px) {
  .trading-strategy-row .performance-chart {
    display: block;
    margin: 0.5rem 0;
    width: 100%;
    flex: unset;
  }
}
.trading-strategy-row .icon {
  margin-left: 0.5rem;
}
@media screen and (max-width: 990px) {
  .trading-strategy-row .icon {
    display: none;
  }
}

.strategy-details {
  background: #1d2024;
  border: 1px solid #2a2e31;
  border-radius: 5px;
  padding: 20px;
  overflow: auto;
}
@media screen and (min-width: 767px) {
  .strategy-details {
    padding: 30px;
  }
}
.strategy-details__wrap {
  display: flex;
  gap: 20px;
  flex-direction: column;
}
@media screen and (min-width: 767px) {
  .strategy-details__wrap {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.strategy-details__wrap:not(:last-child) {
  margin-bottom: 20px;
}
.strategy-details__wrap .stat-box {
  flex: 1;
}
@media screen and (min-width: 767px) {
  .strategy-details__wrap.second-row .stat-box:first-child {
    flex: 0 1 100%;
  }
}
@media screen and (min-width: 1120px) {
  .strategy-details__wrap.second-row .stat-box:first-child {
    flex: 3;
  }
}
@media screen and (min-width: 1120px) {
  .strategy-details__wrap.second-row .stat-box:nth-child(2) {
    flex: 1;
  }
}
@media screen and (min-width: 1120px) {
  .strategy-details__wrap.second-row .stat-box:nth-child(3) {
    flex: 2;
  }
}

.copy-trading-strategy {
  max-width: 870px;
  width: 100%;
  margin: 0 auto;
  background: #1d2024;
  border: 1px solid #2a2e31;
  border-radius: 5px;
  padding: 20px;
  overflow: auto;
  position: relative;
}
@media screen and (min-width: 767px) {
  .copy-trading-strategy {
    padding: 30px;
  }
}
.copy-trading-strategy .stat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  margin: 0.5rem 0;
}
.copy-trading-strategy .stat__value {
  font-weight: 700;
}
.copy-trading-strategy .disclaimer {
  font-size: 0.75rem;
  font-weight: 300;
  color: #A7A8A8;
  margin: 1rem 0;
  display: block;
}
.copy-trading-strategy .button {
  margin: 0 auto;
}

.create-trading-strategy {
  max-width: 870px;
  width: 100%;
  margin: 0 auto;
  background: #1d2024;
  border: 1px solid #2a2e31;
  border-radius: 5px;
  padding: 20px;
  overflow: auto;
  position: relative;
}
@media screen and (min-width: 767px) {
  .create-trading-strategy {
    padding: 30px;
  }
}
.create-trading-strategy .button {
  margin: 0 auto;
}

.custom-chart-tooltip {
  padding: 5px 20px;
  border: 1px solid #383d44;
  border-radius: 4px;
  background: linear-gradient(134.22deg, #565a5c -63.52%, #2a2e31 74.66%);
}

.stat-row {
  display: flex;
  justify-content: space-between;
}
.stat-row .label, .stat-row .value {
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 500;
}
.stat-row .label {
  margin-right: 0.5rem;
}
.stat-row .value--red {
  color: #CD0000;
}
.stat-row .value--green {
  color: #55D636;
}

.notice {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 2rem 0;
}
.notice__icon {
  margin-bottom: 2.5rem;
}
.notice__subtitle {
  font-size: 1.125rem;
  color: #A7A8A8;
}
.notice__button {
  margin-top: 2rem !important;
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.react-tabs__tab {
  display: inline-block;
  bottom: -1px;
  position: relative;
  list-style: none;
  cursor: pointer;
  background: linear-gradient(134.22deg, #565a5c -63.52%, #2a2e31 74.66%);
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: #2a2e31;
  border-radius: 10px 10px 0 0;
  color: #FFF;
  margin-right: 0.5rem;
  width: 120px;
  text-align: center;
  padding: 0.5rem 0.2rem;
  font-size: 1rem;
}

.react-tabs__tab--selected {
  background: #1d2024;
  border-color: #2a2e31;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  outline: none;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
  background: #1d2024;
  border: 1px solid #2a2e31;
  border-radius: 5px;
  overflow: auto;
  max-height: 100%;
  padding: 2rem;
}
@media screen and (max-width: 1119px) {
  .react-tabs__tab-panel--selected {
    padding: 2rem 1rem;
  }
}

.amount-label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  overflow: hidden;
}
@media screen and (min-width: 1120px) {
  .amount-label:nth-child(1) {
    margin: 0 0 10px 0;
  }
}
.amount-label__title {
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 50%;
  -moz-box-flex: 0;
  -moz-flex: 0 1 50%;
  -ms-flex: 0 1 50%;
  flex: 0 1 50%;
  padding: 10px 20px 10px 20px;
  background: #2a2e31;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  font-size: 0.75rem;
  color: #fff;
}
.amount-label__price {
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 50%;
  -moz-box-flex: 0;
  -moz-flex: 0 1 50%;
  -ms-flex: 0 1 50%;
  flex: 0 1 50%;
  padding: 10px 20px 10px 12px;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
}
.amount-label__price--green {
  background: linear-gradient(103.09deg, #70C157 21.7%, #3E8C3B 59.43%), #3E8C3B;
}
.amount-label__price--red {
  background: linear-gradient(103.09deg, #F60057 21.7%, #BB173E 59.43%), #3E8C3B;
}

.page-subtitle {
  text-align: center;
  margin-bottom: 1.2rem;
}
.page-subtitle__subtitle {
  font-size: 0.875rem;
  opacity: 0.6;
}

.logout_btn {
  color: #FFF;
  display: block;
  text-align: center;
  padding-top: 5px;
}
.logout_btn i {
  font-size: 2.625rem;
}

.segmented-controls {
  position: relative;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
@media screen and (min-width: 1120px) {
  .segmented-controls {
    flex-direction: column;
  }
}
.segmented-controls__active {
  background: linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  position: relative;
  height: 42px;
  width: 42px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  z-index: 3;
}
.segmented-controls__active span {
  font-size: 0.75rem;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.segmented-controls__active:hover {
  background: #212327;
  cursor: pointer;
}
.segmented-controls__active.disabled {
  background: linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
  cursor: initial;
}
.segmented-controls__choices {
  margin: 0 0 0 5px;
  padding: 0;
  background: #212327;
  border: 1.5px solid #2a2e31;
  list-style-type: none;
  overflow: hidden;
  height: 42px;
  border-radius: 5px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  display: none;
}
@media screen and (min-width: 1120px) {
  .segmented-controls__choices {
    flex-direction: column;
    position: absolute;
    top: 48px;
    margin: 0;
    width: 42px;
    height: max-content;
  }
}
@media screen and (max-width: 1119px) {
  .segmented-controls__choices {
    transform: translateX(-15%);
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
  }
}
@media screen and (min-width: 1120px) {
  .segmented-controls__choices {
    transform: translateY(-15%);
    animation: slide-down 0.5s forwards;
    -webkit-animation: slide-down 0.5s forwards;
  }
}
.segmented-controls__choices.expanded {
  display: flex;
}
.segmented-controls__choices .choice {
  margin: 0;
  position: relative;
  width: 42px;
  height: 42px;
  text-decoration: none;
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-transition: background 0.25s ease;
  -moz-transition: background 0.25s ease;
  -ms-transition: background 0.25s ease;
  -o-transition: background 0.25s ease;
  transition: background 0.25s ease;
}
@media screen and (min-width: 1120px) {
  .segmented-controls__choices .choice {
    padding: 10px 0;
  }
}
@media screen and (max-width: 1119px) {
  .segmented-controls__choices .choice {
    min-height: 42px;
    padding: 0;
  }
}
.segmented-controls__choices .choice span {
  font-size: 0.75rem;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}
.segmented-controls__choices .choice:hover {
  background: linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
  cursor: pointer;
}

@keyframes slide-in {
  100% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -o-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@-webkit-keyframes slide-in {
  100% {
    -webkit-transform: translateX(0%);
  }
}
@keyframes slide-down {
  100% {
    -webkit-transform: translateY(0%);
    -moz-transform: translateY(0%);
    -o-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
  }
}
@-webkit-keyframes slide-down {
  100% {
    -webkit-transform: translateY(0%);
  }
}
.position-item-desktop {
  cursor: pointer;
  margin: 0 20px 10px 20px;
}
.position-item-desktop.active .position-item-desktop__holder {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: linear-gradient(134.22deg, #565a5c -63.52%, #2a2e31 74.66%);
}
.position-item-desktop.closing .item__holder {
  opacity: 0.8;
}
.position-item-desktop.mt4 .item__holder {
  background: linear-gradient(108.6deg, #3ba4e8 10%, #0a579e 62.59%), #2a2e31;
}
.position-item-desktop__holder {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background: #2a2b31;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid transparent;
  overflow: hidden;
}
.position-item-desktop__main {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 10px;
}
@media screen and (min-width: 1920px) {
  .position-item-desktop__main {
    padding: 20px 10px;
  }
}
.position-item-desktop__general .info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.position-item-desktop__general .info .info__left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 50%;
  -moz-box-flex: 0;
  -moz-flex: 0 1 50%;
  -ms-flex: 0 1 50%;
  flex: 0 1 50%;
  padding: 0 10px;
  max-width: 50%;
}
.position-item-desktop__general .info .info__left .info__icon {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50px;
  -moz-box-flex: 0;
  -moz-flex: 0 0 50px;
  -ms-flex: 0 0 50px;
  flex: 0 0 50px;
}
.position-item-desktop__general .info .info__left .info__desc .name {
  font-weight: 700;
  font-size: 0.875rem;
  margin: 0;
}
.position-item-desktop__general .info .info__right {
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 50%;
  -moz-box-flex: 0;
  -moz-flex: 0 1 50%;
  -ms-flex: 0 1 50%;
  flex: 0 1 50%;
  padding: 0 10px;
  max-width: 50%;
}
.position-item-desktop__general .info .info__right .value {
  font-size: 0.875rem;
  margin: 0;
  background-position: center left;
  background-repeat: no-repeat;
  background-size: 10px;
  padding-left: 20px;
  font-weight: 700;
  line-height: 16px;
}
.position-item-desktop__general .info .info__right .value.sell {
  background-image: url(~/src/icons/down-arrow.svg);
  color: #f60057;
}
.position-item-desktop__general .info .info__right .value.buy {
  background-image: url(~/src/icons/up-arrow.svg);
  color: #70c157;
}
.position-item-desktop__details .details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  padding: 5px 0 0 0;
}
.position-item-desktop__details .details .details__item {
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 50%;
  -moz-box-flex: 0;
  -moz-flex: 0 1 50%;
  -ms-flex: 0 1 50%;
  flex: 0 1 50%;
  padding: 0 10px;
  max-width: 50%;
  margin: 0;
}
.position-item-desktop__details .details .details__item img {
  margin-right: 10px;
  width: 12px;
  height: 12px;
}
.position-item-desktop__details .details .details__item span {
  font-size: 0.75rem;
  color: #ffffff;
}
.position-item-desktop__bottom {
  width: 100%;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  height: 8px;
}
.position-item-desktop__bottom.buy {
  background: linear-gradient(103.09deg, #70c157 21.7%, #3e8c3b 59.43%), #3e8c3b;
}
.position-item-desktop__bottom.sell {
  background: linear-gradient(103.09deg, #f60057 21.7%, #bb173e 59.43%), #3e8c3b;
}
.position-item-desktop__bottom span {
  display: none;
}
.position-item-desktop__timer {
  text-align: center;
  width: 100%;
  background: #18191d;
}
.position-item-desktop__timer .progress-bar-row {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin: 10px 0;
  background: rgba(42, 46, 49, 0.5);
  box-sizing: border-box;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
}
@media screen and (max-height: 640px) {
  .position-item-desktop__timer .progress-bar-row {
    margin: 5px 0;
  }
}
@media screen and (min-width: 600px) {
  .position-item-desktop__timer .progress-bar-row {
    margin: 10px 0 0 0;
  }
}
.position-item-desktop__timer .progress-bar-row .progress-bar-wrapper {
  width: 75%;
  height: 20px;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
}
@media screen and (min-width: 600px) {
  .position-item-desktop__timer .progress-bar-row .progress-bar-wrapper {
    height: 30px;
  }
}
@media screen and (min-width: 600px) and (max-width: 1120px) {
  .position-item-desktop__timer .progress-bar-row .progress-bar-wrapper .progress-bar__value {
    font-size: 0.875rem;
  }
}
.position-item-desktop__timer .progress-bar-row .profit-loss-wrapper {
  width: 25%;
  height: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end;
}
@media screen and (min-width: 600px) {
  .position-item-desktop__timer .progress-bar-row .profit-loss-wrapper {
    height: 30px;
  }
}
.position-item-desktop__timer .progress-bar-row .profit-loss-wrapper span {
  font-size: 0.75rem;
  font-weight: 700;
  height: 20px;
}
@media screen and (min-width: 600px) {
  .position-item-desktop__timer .progress-bar-row .profit-loss-wrapper span {
    height: 30px !important;
    padding: 4px 0;
    font-size: 0.875rem;
  }
}
.position-item-desktop .mt4-placeholder {
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 50%;
  -moz-box-flex: 0;
  -moz-flex: 0 1 50%;
  -ms-flex: 0 1 50%;
  flex: 0 1 50%;
  padding: 0 10px;
  max-width: 50%;
  background: linear-gradient(108.6deg, #3ba4e8 0%, #0a579e 62.59%);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  margin-top: 3px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.position-item-desktop .mt4-placeholder .value {
  font-size: 0.75rem;
  color: white;
  font-weight: 700;
}

.position-item-mobile {
  cursor: pointer;
  margin: 0 5px 0 0;
  padding: 0;
}
.position-item-mobile:last-child {
  margin-bottom: 0;
}
.position-item-mobile.active .position-item-mobile__holder {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: linear-gradient(134.22deg, #565a5c -63.52%, #2a2e31 74.66%);
}
.position-item-mobile.active .trade-instrument-name {
  font-weight: 600 !important;
  color: #ffffff;
}
.position-item-mobile.closing .position-item-mobile__holder {
  opacity: 0.8;
}
.position-item-mobile.mt4 .position-item-mobile__holder {
  background: linear-gradient(108.6deg, #3BA4E8 10%, #0A579E 62.59%), #2A2E31;
}
.position-item-mobile.new {
  opacity: 0.3;
}
.position-item-mobile__holder {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background: #2a2b31;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid transparent;
  overflow: hidden;
}
.position-item-mobile__main {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 5px 5px 0 5px;
}
.position-item-mobile__left {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 20px;
  -moz-box-flex: 0;
  -moz-flex: 0 0 20px;
  -ms-flex: 0 0 20px;
  flex: 0 0 20px;
}
.position-item-mobile__left .info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  border-bottom: 1.5px solid #4c5358;
  margin: 0;
  max-width: 100%;
}
.position-item-mobile__left .info .info__icon {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 70px;
  -moz-box-flex: 0;
  -moz-flex: 0 0 70px;
  -ms-flex: 0 0 70px;
  flex: 0 0 70px;
  display: none;
}
.position-item-mobile__left .info .info__desc {
  max-width: 100%;
  text-align: center;
  margin: 0 auto;
}
.position-item-mobile__left .info .info__desc .name {
  margin: 0 0 5px 0;
  font-weight: 700;
  font-size: 0.75rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.position-item-mobile__left .info .info__desc .value {
  font-size: 0.6875rem;
  margin: 0;
  margin-top: -5px;
  background-position: center left;
  background-repeat: no-repeat;
  padding-left: 10px;
  display: none;
}
.position-item-mobile__left .info .info__desc .value.sell {
  background-image: url(~/src/icons/down.svg);
  color: #f60057;
}
.position-item-mobile__left .info .info__desc .value.buy {
  background-image: url(~/src/icons/up.svg);
  color: #70c157;
}
.position-item-mobile__right {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 49%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 49%;
  -ms-flex: 0 0 49%;
  flex: 0 0 49%;
  padding: 2px 0;
}
.position-item-mobile__right .details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.position-item-mobile__right .details .details__row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.position-item-mobile__right .details .details__row img {
  margin-right: 10px;
  width: 10px;
  height: 10px;
  display: none;
}
.position-item-mobile__right .details .details__row span {
  font-size: 0.6875rem;
}
.position-item-mobile__bottom {
  width: 100%;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  height: 20px;
}
.position-item-mobile__bottom.buy {
  background: linear-gradient(103.09deg, #70C157 21.7%, #3E8C3B 59.43%), #3E8C3B;
}
.position-item-mobile__bottom.sell {
  background: linear-gradient(103.09deg, #F60057 21.7%, #BB173E 59.43%), #3E8C3B;
}
.position-item-mobile__bottom span {
  font-size: 0.75rem;
  padding: 4px 0;
  font-weight: 700;
  margin: 0 auto;
}
.position-item-mobile__timer {
  text-align: center;
  width: 100%;
  background: #18191d;
  height: 20px;
  margin-top: 3px;
  border-radius: 5px;
  font-size: 0.6875rem;
  color: #b6c2ca;
}
.position-item-mobile .mt4-placeholder {
  background: linear-gradient(108.6deg, #3BA4E8 0%, #0A579E 62.59%);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  margin-top: 3px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.position-item-mobile .mt4-placeholder .value {
  font-size: 0.75rem;
  color: white;
  font-weight: 700;
  font-size: 1rem;
}
@media screen and (max-width: 599px) {
  .position-item-mobile .mt4-placeholder .value {
    font-size: 0.75rem;
  }
}

.position-progress-bar-desktop {
  overflow: hidden;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  background: rgba(42, 46, 49, 0.5);
  box-sizing: border-box;
}
.position-progress-bar-desktop__wrapper {
  width: 75%;
  height: 20px;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
}
@media screen and (min-width: 600px) and (max-width: 1120px) {
  .position-progress-bar-desktop__wrapper .progress-bar__value {
    font-size: 0.875rem;
  }
}
.position-progress-bar-desktop__profit-loss-wrapper {
  width: 25%;
  height: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end;
}
.position-progress-bar-desktop__profit-loss-wrapper span {
  font-size: 0.75rem;
  font-weight: 700;
  height: 20px;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stat-box {
  background: #2a2b31;
  border: 1px solid #383d44;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.stat-box__title-holder .title {
  color: #FFF;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 20px;
}
.stat-box__value-holder .value {
  color: #FFF;
  font-size: 1.25rem;
  font-weight: 700;
  margin: 0;
  line-height: 1;
}
.stat-box--provider .stat-box__value-holder {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  align-items: center;
}
.stat-box--provider .stat-box__value-holder .value {
  color: #FFF;
  font-size: 0.875rem;
  font-weight: 500;
}
.stat-box--provider .stat-box__value-holder .avatar {
  max-width: 30px;
  height: auto;
  border-radius: 50%;
}
.stat-box--green .stat-box__value-holder .value {
  color: #70c157;
}
.stat-box--green .stat-box__value-holder .value {
  color: #70c157;
}
.stat-box--large_title .stat-box__title-holder .title {
  color: #FFF;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
}
.stat-box--large_title .stat-box__value-holder .value {
  font-size: 0.875rem;
  line-height: 1.5;
  font-weight: 300;
}
@media screen and (min-width: 767px) {
  .stat-box--half {
    flex: 2;
  }
}

.table__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  color: #FFF;
}
.table__row .label {
  font-size: 0.875rem;
  font-weight: 300;
  margin: 0;
}
.table__row .value-unit {
  font-size: 0.875rem;
  font-weight: 700;
  margin: 0;
}
.table--alternative .table__row .label {
  font-size: 0.875rem;
  font-weight: 700;
}
.table--alternative .table__row .value-unit {
  font-size: 0.875rem;
  font-weight: 300;
}

[type=range] {
  -webkit-appearance: none;
  background: transparent;
  margin: 12px 0;
  width: 100%;
}
[type=range]::-moz-focus-outer {
  border: 0;
}
[type=range]:focus {
  outline: 0;
}
[type=range]:focus::-webkit-slider-runnable-track {
  background: #fbfbfc;
}
[type=range]:focus::-ms-fill-lower {
  background: #eceff1;
}
[type=range]:focus::-ms-fill-upper {
  background: #fbfbfc;
}
[type=range]::-webkit-slider-runnable-track {
  cursor: default;
  height: 8px;
  transition: all 0.2s ease;
  width: 100%;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2), 0 0 1px rgba(13, 13, 13, 0.2);
  background: #eceff1;
  border: 2px solid #cfd8dc;
  border-radius: 5px;
}
[type=range]::-webkit-slider-thumb {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2), 0 0 4px rgba(13, 13, 13, 0.2);
  background: #607d8b;
  border: 2px solid #eceff1;
  border-radius: 12px;
  box-sizing: border-box;
  cursor: default;
  height: 24px;
  width: 24px;
  -webkit-appearance: none;
  margin-top: -10px;
}
[type=range]::-moz-range-track {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2), 0 0 1px rgba(13, 13, 13, 0.2);
  cursor: default;
  height: 8px;
  transition: all 0.2s ease;
  width: 100%;
  background: #eceff1;
  border: 2px solid #cfd8dc;
  border-radius: 5px;
  height: 4px;
}
[type=range]::-moz-range-thumb {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2), 0 0 4px rgba(13, 13, 13, 0.2);
  background: #607d8b;
  border: 2px solid #eceff1;
  border-radius: 12px;
  box-sizing: border-box;
  cursor: default;
  height: 24px;
  width: 24px;
}
[type=range]::-ms-track {
  cursor: default;
  height: 8px;
  transition: all 0.2s ease;
  width: 100%;
  background: transparent;
  border-color: transparent;
  border-width: 12px 0;
  color: transparent;
}
[type=range]::-ms-fill-lower {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2), 0 0 1px rgba(13, 13, 13, 0.2);
  background: #dde3e6;
  border: 2px solid #cfd8dc;
  border-radius: 10px;
}
[type=range]::-ms-fill-upper {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2), 0 0 1px rgba(13, 13, 13, 0.2);
  background: #eceff1;
  border: 2px solid #cfd8dc;
  border-radius: 10px;
}
[type=range]::-ms-thumb {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2), 0 0 4px rgba(13, 13, 13, 0.2);
  background: #607d8b;
  border: 2px solid #eceff1;
  border-radius: 12px;
  box-sizing: border-box;
  cursor: default;
  height: 24px;
  width: 24px;
  margin-top: 2px;
}
[type=range]:disabled::-webkit-slider-thumb, [type=range]:disabled::-moz-range-thumb, [type=range]:disabled::-ms-thumb, [type=range]:disabled::-webkit-slider-runnable-track, [type=range]:disabled::-ms-fill-lower, [type=range]:disabled::-ms-fill-upper {
  cursor: not-allowed;
}