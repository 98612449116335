html,
body {
  font-size: 16px;
}

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
/**************************************************
    GRID & LAYOUT SETTINGS
    **********************************************/
/**************************************************
    COLORS
    **********************************************/
/**************************************************
    BORDER RADIUS DEFINITIONS
    **********************************************/
.container {
  width: 100%;
}

.label-clear-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.label {
  font-size: 0.75rem;
  font-weight: 300;
}

.clear-button {
  font-size: 0.75rem;
}

.inputs-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dash {
  margin: 0 0.5rem;
}