.iconButton {
  display: inline-flex;
  height: 56px;
  width: 56px;
  flex-shrink: 0;
  border-radius: 100%;
  overflow: hidden;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  outline: none;
  transition: background-color 0.3s ease-out;
}
.iconButton:hover, .iconButton:active, .iconButton-active {
  background: #2b2e38;
}
.iconButton:active .iconWrapper, .iconButton-active .iconWrapper {
  color: #12aaeb;
}
.iconButton--small {
  height: 40px;
  width: 40px;
}

.iconWrapper {
  height: 24px;
  width: 24px;
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}
.iconWrapper--flexible {
  height: unset;
  width: unset;
}