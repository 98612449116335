html,
body {
  font-size: 16px;
}

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
/**************************************************
    GRID & LAYOUT SETTINGS
    **********************************************/
/**************************************************
    COLORS
    **********************************************/
/**************************************************
    BORDER RADIUS DEFINITIONS
    **********************************************/
.container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
@media screen and (max-width: 1119px) {
  .container {
    flex-direction: column;
    align-items: stretch;
    gap: 1rem;
  }
}

.search-filter-container {
  display: flex;
  align-items: center;
}

.sort-container {
  margin-left: auto;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1119px) {
  .sort-container {
    margin-left: 0;
  }
}

.sort-label {
  margin-right: 0.5rem;
}
@media screen and (max-width: 1119px) {
  .sort-label {
    display: none;
  }
}

.range-filters-container {
  position: relative;
  display: flex;
}

.filter-icon {
  margin-right: 0.5rem;
}

@media screen and (min-width: 1120px) {
  .sort-icon {
    display: none;
  }
}

@media screen and (max-width: 1119px) {
  .sort-button-label {
    display: none;
  }
}

.dropdown-container {
  position: relative;
}
.dropdown-container :global(.button) {
  min-width: 120px;
}
@media screen and (max-width: 1119px) {
  .dropdown-container {
    flex: 1;
  }
  .dropdown-container :global(.button) {
    min-width: unset;
  }
}
.dropdown-item {
  color: #FFF !important;
  padding: 15px 0 15px 10px !important;
  font-size: 0.875rem;
  background: #2a2e31;
  transition: background-color 0.25s ease-in-out;
  text-decoration: none;
  display: block;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;
}
.dropdown-item:after {
  position: absolute;
  right: 20px;
}
.dropdown-item:hover, .dropdown-item:focus {
  background-color: #1d2024 !important;
  text-decoration: none;
}