.spacer {
  --spacer-size: .5rem;
  flex-grow: 0;
  flex-shrink: 0;
}
.spacer--large {
  --spacer-size: 1rem;
}
.spacer--x {
  width: var(--spacer-size);
}
.spacer--y {
  height: var(--spacer-size);
}