html,
body {
  font-size: 16px;
}

/**************************************************
    GRID & LAYOUT SETTINGS
    **********************************************/
/**************************************************
    COLORS
    **********************************************/
/**************************************************
    BORDER RADIUS DEFINITIONS
    **********************************************/
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background: #000;
  opacity: 0.5;
}

.body {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: #21232b;
  overflow: hidden;
  outline: none;
  max-width: 600px;
  border-radius: 8px;
  color: #FFF;
  z-index: 1050;
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
}

.closeButtonWrapper {
  display: flex;
  justify-content: flex-end;
  margin: -8px;
}

.title {
  margin-bottom: 8px;
}

.description {
  font-size: 12px;
  line-height: 16px;
  color: #84899c;
  margin-bottom: 32px;
}

.hidden {
  visibility: hidden;
}