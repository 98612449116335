/**************************************************
    GRID & LAYOUT SETTINGS
    **********************************************/
/**************************************************
    COLORS
    **********************************************/
/**************************************************
    BORDER RADIUS DEFINITIONS
    **********************************************/
html,
body {
  font-size: 16px;
}

.balance-pill {
  padding: 3px 6px;
  background: #272A2C;
  font-size: 0.75rem;
  font-weight: 500;
  border: 1px solid #404251;
  border-radius: 5px;
}