html,
body {
  font-size: 16px;
}

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
/**************************************************
    GRID & LAYOUT SETTINGS
    **********************************************/
/**************************************************
    COLORS
    **********************************************/
/**************************************************
    BORDER RADIUS DEFINITIONS
    **********************************************/
.link {
  color: unset;
  text-decoration: none;
}
.link:hover {
  color: unset;
  text-decoration: none;
}
@media screen and (max-width: 1119px) {
  .link {
    flex: 1;
  }
}

.container {
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0.7rem;
  border: 1.5px solid transparent;
  border-radius: 5px;
  height: 60px;
}
@media screen and (max-width: 1119px) {
  .container {
    width: 100%;
    height: 47.5px;
    padding: 0.2rem 0.7rem;
  }
}
.container--bronze {
  background-color: rgba(207, 92, 37, 0.08);
  border-color: #E36629;
}
.container--bronze .progress-bar {
  background: #E36629;
}
.container--silver {
  background-color: rgba(188, 184, 183, 0.08);
  border-color: #BCB8B7;
}
.container--silver .progress-bar {
  background: #BCB8B7;
}
.container--gold {
  background-color: rgba(248, 163, 0, 0.08);
  border-color: #F8A300;
}
.container--gold .progress-bar {
  background: #F8A300;
}
.container--platinum {
  background-color: rgba(238, 238, 238, 0.08);
  border-color: #EEEEEE;
}
.container--platinum .progress-bar {
  background: #EEEEEE;
}
.container--diamond {
  background-color: rgba(163, 244, 255, 0.08);
  border-color: #A3F4FF;
}
.container--diamond .progress-bar {
  background: #A3F4FF;
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
}

.labels-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  margin-right: 0.5rem;
}

.title {
  font-size: 1rem;
  font-weight: 700;
  text-align: start;
}
@media screen and (max-width: 1119px) {
  .title {
    font-size: 0.875rem;
  }
}

.subtitle {
  font-size: 0.6875rem;
  color: rgba(255, 255, 255, 0.5);
  white-space: nowrap;
  text-align: start;
}

.progress-label-wrapper {
  display: flex;
  margin-left: 0.5rem;
}

.progress-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 140px;
  max-width: 170px;
  justify-content: space-around;
}

.stars-and-progress-label-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.progress-label {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.5);
}
.progress-label--accent {
  font-weight: 700;
  color: #FFF;
}

.progress-bar-track {
  height: 6px;
  width: 100%;
  background-color: #18191d;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.progress-bar {
  height: 6px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: width 0.6s ease;
  background-color: #CD0000;
}

.star--active {
  color: pink;
}