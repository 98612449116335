@charset "UTF-8";
html,
body {
  font-size: 16px;
}

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
/**************************************************
    GRID & LAYOUT SETTINGS
    **********************************************/
/**************************************************
    COLORS
    **********************************************/
/**************************************************
    BORDER RADIUS DEFINITIONS
    **********************************************/
.container {
  padding: 0.05rem 0.5rem;
  border-radius: 6px;
  font-size: 0.6875rem;
  font-weight: bold;
  color: white;
  text-align: center;
  white-space: nowrap;
  display: inline-block;
  line-height: 1.3;
}
@media screen and (max-width: 1119px) {
  .container {
    font-weight: 500;
  }
}
.container:empty:before {
  content: "​";
}
.container--win {
  background-color: #4bb84e;
}
.container--loss {
  background-color: #f5004d;
}