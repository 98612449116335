html,
body {
  font-size: 16px;
}

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
/**************************************************
    GRID & LAYOUT SETTINGS
    **********************************************/
/**************************************************
    COLORS
    **********************************************/
/**************************************************
    BORDER RADIUS DEFINITIONS
    **********************************************/
.container {
  margin-top: 1rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.star--bronze {
  color: #E36629;
}
.star--silver {
  color: #BCB8B7;
}
.star--gold {
  color: #F8A300;
}
.star--platinum {
  color: #EEEEEE;
}
.star--diamond {
  color: #A3F4FF;
}

.progress-bar-track {
  width: 40px;
  height: 3px;
  border-radius: 12px;
  background: #2a2e31;
  position: relative;
  overflow: hidden;
}

.progress-bar {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
}
.progress-bar--silver {
  background: #BCB8B7;
}
.progress-bar--gold {
  background: #F8A300;
}
.progress-bar--platinum {
  background: #EEEEEE;
}
.progress-bar--diamond {
  background: #A3F4FF;
}