/**************************************************
    GRID & LAYOUT SETTINGS
    **********************************************/
/**************************************************
    COLORS
    **********************************************/
/**************************************************
    BORDER RADIUS DEFINITIONS
    **********************************************/
html,
body {
  font-size: 16px;
}

.container {
  padding: 1px 4px;
  border-radius: 3px;
  margin: 0px;
  font-size: 0.6875rem;
  background: transparent;
  border: 1px solid #404251;
}