/**************************************************
    GRID & LAYOUT SETTINGS
    **********************************************/
/**************************************************
    COLORS
    **********************************************/
/**************************************************
    BORDER RADIUS DEFINITIONS
    **********************************************/
/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
.container {
  text-align: center;
}

.insufficientFunds {
  text-align: center;
  padding: 0 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .insufficientFunds {
    padding: 0 1.5rem;
  }
}
@media screen and (max-width: 540px) {
  .insufficientFunds {
    padding: 0;
  }
}

.balanceAmountsWrapper {
  width: 100%;
  padding: 0 4rem;
}
@media screen and (max-width: 767px) {
  .balanceAmountsWrapper {
    padding: 0 2rem;
  }
}
@media screen and (max-width: 540px) {
  .balanceAmountsWrapper {
    padding: 0;
  }
}

.subtitle {
  margin: 0.5rem 0;
}