html,
body {
  font-size: 16px;
}

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
/**************************************************
    GRID & LAYOUT SETTINGS
    **********************************************/
/**************************************************
    COLORS
    **********************************************/
/**************************************************
    BORDER RADIUS DEFINITIONS
    **********************************************/
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header, .wallet {
  background: linear-gradient(134.22deg, #565A5C -63.52%, #2a2e31 74.66%);
  text-align: start;
  font-size: 0.875rem;
}
@media screen and (max-width: 1119px) {
  .header, .wallet {
    background: none;
  }
}

.header {
  display: flex;
  align-items: center;
  padding: 7px 0;
}
@media screen and (max-width: 1119px) {
  .header {
    padding: 1.2rem 0.5rem 1.2rem 2rem;
    position: relative;
  }
}

.title {
  font-size: 0.875rem;
  font-weight: normal;
  margin: 0;
}
@media screen and (max-width: 1119px) {
  .title {
    font-size: 1.5rem;
    font-weight: bold;
  }
}

.back-button {
  padding: 0 10px;
}
@media screen and (max-width: 1119px) {
  .back-button {
    width: 30px;
    margin-right: 0.5rem;
  }
  .back-button img {
    width: 15px;
  }
}

.wallet {
  padding: 7px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #FFF;
}
.wallet:not(:disabled):hover {
  background: #2a2e31;
}
@media screen and (max-width: 1119px) {
  .wallet {
    padding: 1.2rem 2rem;
    font-size: 1rem;
  }
  .wallet:not(:disabled):hover {
    background: rgba(24, 25, 29, 0.5);
  }
}

.wallet-label--active {
  color: #ffb200;
  font-weight: bold;
}

.wallet-list {
  overflow: auto;
  flex: 1;
}

.loader-wrapper {
  background: linear-gradient(134.22deg, #565A5C -63.52%, #2a2e31 74.66%);
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1119px) {
  .loader-wrapper {
    background: none;
  }
}

.wallet-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}