/**************************************************
    GRID & LAYOUT SETTINGS
    **********************************************/
/**************************************************
    COLORS
    **********************************************/
/**************************************************
    BORDER RADIUS DEFINITIONS
    **********************************************/
/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
.container {
  padding: 20px;
  text-align: center;
}
.container h4 {
  margin-top: 10px;
}

.holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button {
  min-width: 120px;
}

.actions {
  margin-top: 30px;
}
.actions .holder {
  margin: 0 auto;
  max-width: 306px;
  flex-direction: row;
  gap: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around;
}
@media screen and (min-width: 1120px) {
  .actions .holder {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
  }
}
.actions .holder .button {
  min-width: 120px;
}
@media screen and (min-width: 767px) {
  .actions .holder .button {
    min-width: 143px;
  }
}