html,
body {
  font-size: 16px;
}

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
/**************************************************
    GRID & LAYOUT SETTINGS
    **********************************************/
/**************************************************
    COLORS
    **********************************************/
/**************************************************
    BORDER RADIUS DEFINITIONS
    **********************************************/
.container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 10px;
}

.track-container {
  height: 36px;
  display: flex;
  width: 100%;
}

.track {
  height: 5px;
  width: 100%;
  border-radius: 4px;
  align-self: center;
}

.thumb {
  height: 20px;
  width: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background-color: #4bb84e;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #4bb84e;
}
.thumb-dragged {
  border: 1px solid #ffffff;
}