html,
body {
  font-size: 16px;
}

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
/**************************************************
    GRID & LAYOUT SETTINGS
    **********************************************/
/**************************************************
    COLORS
    **********************************************/
/**************************************************
    BORDER RADIUS DEFINITIONS
    **********************************************/
.avatarRoot {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  overflow: hidden;
  user-select: none;
  width: 40px;
  height: 40px;
  max-width: 100%;
  max-height: 100%;
  background-color: transparent;
}

.avatarImage {
  width: 36px;
  height: 36px;
  object-fit: contain;
  border-radius: inherit;
}

.avatarFallback {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #646d7b;
  line-height: 24px;
  font-size: 14px;
  border-radius: 100%;
  font-weight: 500;
  color: #FFF;
}