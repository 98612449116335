/**************************************************
    GRID & LAYOUT SETTINGS
    **********************************************/
/**************************************************
    COLORS
    **********************************************/
/**************************************************
    BORDER RADIUS DEFINITIONS
    **********************************************/
/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
html,
body {
  font-size: 16px;
}

.container {
  width: 400px;
  background-color: #e3a300;
  padding: 8px 16px 8px 16px;
  border-radius: 8px;
  position: absolute;
  top: 7%;
  left: 5%;
  z-index: 1000;
}
@media screen and (max-width: 1119px) {
  .container {
    top: 20%;
  }
}
@media screen and (max-width: 539px) {
  .container {
    width: 300px;
    left: 0px;
  }
}

.inner {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 539px) {
  .inner {
    flex-direction: column;
  }
}

.info {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}
@media screen and (max-width: 539px) {
  .info {
    align-items: center;
    margin-bottom: 8px;
  }
}

.header {
  color: #1a1b1f;
  font-size: 1rem;
  font-weight: 600;
  height: 1rem;
}

.label {
  color: #2b2e38;
  font-size: 0.75rem;
}

.buttonText {
  font-size: 1rem;
  font-weight: 500;
  height: 40px;
}

.buttonText:hover {
  background: linear-gradient(134.22deg, #585b5e -63.52%, #1d1f21 74.66%), #1d1f21;
}